import React from 'react';
import { Router } from '@reach/router';

import NotFoundPage from '../NotFoundPage';
import ListadoRecepciones from './ListadoRecepciones';
import IngresarRecepcion from './IngresarRecepcion';
import EditarRecepcion from 'pages/recepcion/EditarRecepcion';

export default function() {
  return (
    <Router>
      <ListadoRecepciones path="/" />
      <EditarRecepcion path="/:id/editar" />
      <IngresarRecepcion path="/nuevo" />
      <NotFoundPage default />
    </Router>
  )
}