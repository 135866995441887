import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Save as SaveIcon } from '@material-ui/icons';
import { Formik, Form, Fab, ApiErrorMessage } from 'components/ui';
import { validateRut, validateLength, validateEmail } from 'helpers';

function ProveedorForm ({ proveedor, error, onSubmit }) {
  
  const initialValues = {
    rut: proveedor ? proveedor.rut : '',
    razonSocial: proveedor ? proveedor.razonSocial : '',
    direccion: proveedor ? proveedor.direccion : '',
    telefono1: proveedor ? proveedor.telefono1 : '',
    telefono2: proveedor ? proveedor.telefono2 : '',
    personaContacto: proveedor ? proveedor.personaContacto : '',
    email: proveedor ? proveedor.email : '',
  };

  const handleSubmit = (input) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    onSubmit(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      {({ dirty, handleSubmit }) => (
        <Form alignItems="center">

          <Form.Field xs={12} md={3}>
            <Form.TextField required name="rut" label="RUT" fluid />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name="razonSocial" label="Razón Social" fluid validate={validateLength(0, 200)} />
          </Form.Field>
          <Form.Field xs={12} md={6}>
            <Form.TextField required name="direccion" label="Dirección" fluid validate={validateLength(0, 300)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name="telefono1" label="Teléfono 1" fluid validate={validateLength(0, 20)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField name="telefono2" label="Teléfono 2" fluid validate={validateLength(0, 20)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField name="personaContacto" label="Persona Contacto" fluid validate={validateLength(0, 200)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField name="email" label="Email" fluid validate={validateEmail} />
          </Form.Field>

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.rut) {
    errors.rut = 'El RUT es obligatorio'
  } else if(!validateRut(values.rut)) {
    errors.rut = 'El RUT ingresado no es correcto';
  }
  if (!values.razonSocial) {
    errors.razonSocial = 'La razón social es obligatoria';
  }
  if (!values.direccion) {
    errors.direccion = 'La dirección es obligatoria';
  }
  if (!values.telefono1) {
    errors.telefono1 = 'El teléfono es obligatorio';
  }
  
  return errors;
}

ProveedorForm.propTypes = {
  proveedor: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default ProveedorForm;