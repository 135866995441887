import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import createActivityDetector from 'activity-detector';

const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
  expiresAt: null,
};

export const AuthContext = React.createContext(initialState);

function authenticate (token) {
  try {
    const { exp, iat, ...user } = jwtDecode(token);
    const expiresAt = new Date(exp * 1000);
    if (expiresAt < new Date()) {
      return initialState;
    }
    return { user, token, expiresAt, isAuthenticated: true};
  } catch(err) {
    console.error(err);
    return initialState;
  }
}

export const AuthProvider = ({ children }) => {
  // Startup authentication
  const token = localStorage.getItem('access-token');
  let startupState = initialState;
  if (token) {
    startupState = authenticate(token);
  }

  if (!startupState.isAuthenticated) {
    localStorage.removeItem('access-token');
  }

  const [state, setState] = React.useState(startupState);
  
  const login = (token) => {
    const authState = authenticate(token);
    setState(authState);
    if(authState.isAuthenticated){
      localStorage.setItem('access-token', authState.token);
    }
  }

  const logout = () => {
    localStorage.removeItem('access-token');
    setState(initialState);
  }

  useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: 20 * 60 * 1000, // 20 minutos
      inactivityEvents: [],
    });
    
    activityDetector.on('idle', () => logout());
    
    return () => activityDetector.stop();
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}
