import { Formik } from 'formik';
import { Form } from './Form';
import { TextField, PasswordField } from './TextField';
import { Select } from './Select';
import { Checkbox, Switch } from './Checkbox';
import { Datepicker } from './DatePicker';
import { Autocomplete } from './Autocomplete';
import { TextArea } from './TextArea';
import { Lookup } from './Lookup';
import { ErrorMessage } from './ErrorMessage'

Form.TextField = TextField;
Form.PasswordField = PasswordField;
Form.Select = Select;
Form.Checkbox = Checkbox;
Form.Switch = Switch;
Form.Datepicker = Datepicker;
Form.Autocomplete = Autocomplete;
Form.TextArea = TextArea;
Form.Lookup = Lookup;
Form.ErrorMessage = ErrorMessage;

export { Formik, Form };
