import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { FieldArray } from 'formik';
import { Typography, IconButton, Button, MenuItem, InputAdornment } from '@material-ui/core';
import { Save as SaveIcon, Delete, Add } from '@material-ui/icons';
import { useLazyQuery, useQuery } from 'api';
import { Formik, Form, Fab, ApiErrorMessage, TextField } from 'components/ui';
import { validateLength, formatRut, formatPrecio, formatProducto, roundDecimals } from 'helpers';

function RecepcionForm ({ recepcion, error, onSubmit }) {
  const [buscarProveedores] = useLazyQuery('/proveedores');
  const [buscarProductos] = useLazyQuery('/productos');
  const { data: tiposDocumento } = useQuery('/tipos-documento');
  const { data: origenes } = useQuery('/origenes');

  const initialValues = {
    proveedor: recepcion ? recepcion.proveedor : null,
    idTipoDocumento: recepcion ? recepcion.idTipoDocumento.toString() : '1',
    documento: recepcion ? recepcion.documento : '',
    idOrigen: recepcion ? recepcion.idOrigen.toString() : '',
    fechaRecepcion: recepcion ? recepcion.fechaRecepcion : '',
    observaciones: recepcion ? recepcion.observaciones : '',
    productosDetalle: recepcion ? recepcion.productosDetalle : [],
    subProceso: recepcion ? !!recepcion.idProcesamiento : false,
    idProcesamiento: recepcion ? recepcion.idProcesamiento : '',
  };

  const handleFormSubmit = (input) => {
    const values = _.chain(input)
      .omit(['proveedor', 'productosDetalle'])
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    values.fechaRecepcion = moment.utc().toISOString();
    values.idProveedor = input.proveedor.id;
    values.productosDetalle = input.productosDetalle.map(pd => ({
      idProducto: pd.producto.id,
      cantidad: pd.cantidad,
      precio: pd.precio,
    }));

    onSubmit(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm}
    >
      {({ values, setFieldValue, setFieldTouched, errors, dirty, handleSubmit }) => (
        <Form alignItems="center">
          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Datos Generales</Typography>
          </Form.Field>
          <Form.Field xs={12}>
            <Form.Lookup
              required
              name="proveedor"
              label="Proveedor"
              fluid
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option ? `${formatRut(option.rut)} - ${option.razonSocial}` : ''}
              fetcher={(busqueda) => buscarProveedores({ query: { busqueda } }).then(resp => resp.data.nodes)}
            />
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.Select required name="idTipoDocumento" label="Tipo Documento" fluid align="left">
              {(tiposDocumento || []).map(td => <MenuItem key={td.id} value={td.id}>{td.nombre}</MenuItem>)}
            </Form.Select>
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.TextField required name="documento" label="Documento" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.Select required name="idOrigen" label="Origen" fluid align="left">
              {(origenes || []).map(origen => <MenuItem key={origen.id} value={origen.id}>{origen.nombre}</MenuItem>)}
            </Form.Select>
          </Form.Field>
          <Form.Field xs={values.subProceso ? 3 : 12} align="left">
            <Form.Checkbox name="subProceso" label="Sub Proceso" labelPlacement="end" />
          </Form.Field>
          {values.subProceso && (
            <Form.Field xs={9} style={{display:'flex', justifyContent:'flex-start'}}>
              <Form.TextField required name="idProcesamiento" label="Nº Procesamiento" />
            </Form.Field>
          )}

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Productos</Typography>
          </Form.Field>
          <FieldArray name="productosDetalle" render={arrayHelpers => (
            <>
              {values.productosDetalle.map((pd, index) => {
                const esMonto = !!pd.producto && pd.producto.tipo.esMonto;

                return (
                  <React.Fragment key={index}>
                    <Form.Field xs={12} md={5}>
                      <Form.Lookup
                        required
                        name={`productosDetalle.${index}.producto`}
                        label="Producto"
                        fluid
                        onChange={(e, option) => {
                          if (option && option.tipo.esMonto) {
                            setFieldValue(`productosDetalle.${index}.precio`, '1');
                            setFieldTouched(`productosDetalle.${index}.precio`);
                          }
                        }}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option ? formatProducto(option) : ''}
                        fetcher={(busqueda) => buscarProductos({ query: { busqueda }}).then(resp => resp.data.nodes)}
                      />
                    </Form.Field>
                    <Form.Field xs={12} md={esMonto ? 3 : 1}>
                      <Form.TextField
                        required
                        name={`productosDetalle.${index}.cantidad`}
                        label={esMonto ? 'Monto' : 'Cantidad'}
                        type="number"
                        InputProps={{
                          startAdornment: esMonto ? <InputAdornment position="start">$</InputAdornment> : null,
                        }}
                        fluid
                      />
                    </Form.Field>
                    {!esMonto && (
                      <Form.Field xs={12} md={2}>
                        <Form.TextField
                          required
                          name={`productosDetalle.${index}.precio`}
                          label="Precio Unitario"
                          type="number"
                          lang="es"
                          fluid
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                        />
                      </Form.Field>
                    )}
                    <Form.Field xs={12} md={2}>
                      <TextField
                        label="Subtotal"
                        value={formatPrecio(roundDecimals(pd.cantidad * pd.precio, 3))}
                        fluid
                        disabled
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </Form.Field>
                    <IconButton aria-label="delete" onClick={() => arrayHelpers.remove(index)}>
                      <Delete />
                    </IconButton>
                  </React.Fragment>
                );
              })}
              <Form.Field xs={6} align="left">
                <Form.ErrorMessage name="productosDetalle" />
                <Button variant="contained" size="small" startIcon={<Add/>} onClick={() => arrayHelpers.push({ producto: null, cantidad: '1', precio: '0' })}>
                  Agregar Producto
                </Button>
              </Form.Field>
              <Form.Field xs={6} align="right">
                <Typography variant="subtitle2">Total:</Typography>
                <Typography variant="body1">$ {formatPrecio(Math.round(_.sum(values.productosDetalle.map(pd => pd.cantidad * pd.precio))))}</Typography>
              </Form.Field>
            </>
          )} />

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Observaciones</Typography>
          </Form.Field>
          <Form.Field xs={12}>
            <Form.TextArea name="observaciones" placeholder="Ingrese un texto..." fluid />
          </Form.Field>

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.proveedor) {
    errors.proveedor = 'El proveedor es obligatorio'
  }
  if (!values.idTipoDocumento) {
    errors.documento = 'El tipo de documento es obligatorio';
  }
  if (!values.documento) {
    errors.documento = 'El documento es obligatorio';
  }
  if (values.subProceso && !values.idProcesamiento) {
    errors.idProcesamiento = 'El Nº de procesamiento es obligatorio';
  }
  if (values.productosDetalle.length === 0) {
    errors.productosDetalle = 'Debe ingresar al menos un producto'
  } else {
    errors.productosDetalle = values.productosDetalle.map(pd => {
      const pdErrors = {};
      if (!pd.producto) {
        pdErrors.producto = 'El producto es obligatorio';
      }
      let cantidad = Number(pd.cantidad);
      if (_.isNaN(cantidad) || cantidad <= 0) {
        pdErrors.cantidad = 'La cantidad ingresada no es válida';
      }
      let precio = Number(pd.precio);
      if (_.isNaN(precio) || precio < 0) {
        pdErrors.precio = 'El precio ingresado no es válido';
      } else if (precio - roundDecimals(precio, 3) > 0) {
        pdErrors.precio = 'El precio no puede tener más de 3 dígitos decimales'
      }

      return pdErrors;
    });

    if (_.isEmpty(errors.productosDetalle.filter(x => !_.isEmpty(x)))) {
      delete errors.productosDetalle;

      const unicos = _.uniq(values.productosDetalle.map(pd => pd.producto.id));
      if (unicos.length !== values.productosDetalle.length) {
        errors.productosDetalle = 'Hay productos repetidos'
      }
    }

  }
  
  return errors;
}

RecepcionForm.propTypes = {
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default RecepcionForm;