import React, { useState } from 'react';
import { omitBy, isNil } from 'lodash';
import { hasPermission } from 'auth';
import { Grid } from '@material-ui/core';
import { DataTable, Loader, Breadcrumbs, Title, FilterGrid, Lookup } from 'components/ui';
import { useQuery, useApi, useLazyQuery } from 'api';
import { formatBeneficiarioDetallado } from 'helpers';
import { PERMISO } from '../../constants';
import { AyudaHeader, AyudaRow } from '../../components/ayuda/AyudaTable';

function ListadoTesoreria() {
  const [state, setState] = useState({ economicas: 1, page: 1, pageSize: 10 });
  
  const [buscarBeneficiarios] = useLazyQuery('/beneficiarios');
  const { data: ayudas, loading, refetch } = useQuery('/ayudas', { query: state });
  const callApi = useApi();

  const handleDesactivar = async (id) => {
    await callApi(`/ayudas/${id}`, { method: 'delete' });
    await refetch({ query: state });
  }

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: omitBy(newState, isNil) });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'listado', title: 'Tesorería', to: '/tesoreria', active: true },
      ]} />
      <Title>Ayudas Económicas</Title>
      <Loader loading={loading} />      

      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Lookup
            name="beneficiario"
            label="Usuario"
            size="small"
            fluid
            onChange={(e, val) => onChange({ idBeneficiario: val ? val.id : null, page: 1 })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? formatBeneficiarioDetallado(option) : ''}
            fetcher={(busqueda) => buscarBeneficiarios({ query: { busqueda } }).then(resp => resp.data.nodes)}
          />
        </Grid>
      </FilterGrid>

      <DataTable
        data={ayudas}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <AyudaHeader />}
        renderRow={(ayuda) => (
          <AyudaRow
            key={ayuda.id}
            ayuda={ayuda}
            onDesactivar={handleDesactivar}
          />
        )}
      />

    </div>
  );
}

export default hasPermission([PERMISO.AyudasAprobar])(ListadoTesoreria);
