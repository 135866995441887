import React, { useState } from 'react';
import _ from 'lodash';
import { TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
import { Add as AddIcon, PictureAsPdf as PdfIcon, Comment } from '@material-ui/icons';
import { pdf } from '@react-pdf/renderer';
import { hasPermission, useAuth } from 'auth';
import { Fab, DataTable, Loader, CompactTableCell, Breadcrumbs, Title } from 'components/ui';
import { useQuery } from 'api';
import { formatRut } from 'helpers/usuario';
import { formatDate, formatProductoItem } from 'helpers/format';
import { navigate } from '@reach/router';
import ComprobanteProcesamientoPdf from 'components/pdf/ComprobanteProcesamientoPdf';
import { PERMISO } from '../../constants';

function ListadoProcesamientos() {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10 });
  
  const canEdit = auth.hasPermission([PERMISO.ProcesamientosModificar]);

  const { data: procesamientos, loading, refetch } = useQuery('/procesamientos');
    
  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'listado', title: 'Procesamientos', to: '/procesamientos', active: true },
      ]} />
      <Title>Procesamiento de Productos</Title>
      <Loader loading={loading} />      

      <DataTable
        data={procesamientos}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <ProcesamientoHeader />}
        renderRow={(proc) => (
          <ProcesamientoRow
            key={proc.id}
            procesamiento={proc}
            canEdit={canEdit}
          />
        )}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate('/procesamientos/nuevo')}
        >
          <AddIcon />
        </Fab>
      )}

    </div>
  );
}

function ProcesamientoHeader() {
  return (
    <>
      <TableRow>
        <TableCell>Nº</TableCell>
        <TableCell>RUT</TableCell>
        <TableCell>Proveedor</TableCell>
        <TableCell>Fecha Ingreso</TableCell>
        <TableCell>Observaciones</TableCell>
        <TableCell>Productos</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </>
  )
}

function ProcesamientoRow({ procesamiento, canEdit }) {
  const prodsEntrada = procesamiento.productosDetalle.filter(pd => !pd.salida);
  const cantProds = Math.max(1, prodsEntrada.length); // Math.max(prodsEntrada.length, prodsSalida.length);
  const pe0 = prodsEntrada[0];
  
  const generatePdfDocument = React.useCallback(async () => {
    const blob = await pdf(<ComprobanteProcesamientoPdf procesamiento={procesamiento} />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
    // saveAs(blob, `procesamiento-${procesamiento.id}.pdf`);
  }, [procesamiento]);
  
  return (
    <>
      <TableRow>
        <CompactTableCell rowSpan={cantProds}>{procesamiento.id}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatRut(procesamiento.proveedor.rut)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{procesamiento.proveedor.razonSocial}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatDate(procesamiento.fechaIngreso)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{procesamiento.observaciones}</CompactTableCell>
        <CompactTableCell>{formatProductoItem(pe0)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>
          {procesamiento.observaciones && (
            <Tooltip title={procesamiento.observaciones}>
              <Comment color='disabled' />
            </Tooltip>
          )}
        </CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>
          <IconButton aria-label="descargar pdf" onClick={generatePdfDocument}>
            <PdfIcon style={{color: "firebrick"}} />
          </IconButton>
        </CompactTableCell>
      </TableRow>
      {_.range(1, cantProds).map(idx => (
        <TableRow key={idx}>
          <CompactTableCell>{prodsEntrada[idx] ? formatProductoItem(prodsEntrada[idx]) : null}</CompactTableCell>
        </TableRow>
      ))}
    </>
  )
}

export default hasPermission([PERMISO.ProcesamientosVer])(ListadoProcesamientos);
