import React, { useState } from 'react'
import { Loader, Snackbar, Breadcrumbs, Title } from 'components/ui';
import TipoProductoForm from 'components/producto/TipoProductoForm';
import { hasPermission } from 'auth';
import { useMutation, useQuery } from 'api';
import { PERMISO } from '../../constants';

function EditarTipoProducto({ id }) {
  const [snackOpen, setSnackOpen] = useState(false);
  const { data: tipoProducto, loading } = useQuery(`/tipos-productos/${id}`);
  const [editarTipoProducto, { loading: posting, error }] = useMutation(`/tipos-productos/${id}`, { method: 'patch' });

  const handleSubmit = async values => {
    const { error } = await editarTipoProducto({ body: values });

    if (!error) {
      setSnackOpen(true);
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'tipos-productos', title: 'Tipos de productos', to: '/admin/tipos-productos' },
        { key: 'actual', title: tipoProducto ? tipoProducto.nombre : id, to: `/admin/tipos-productos/${id}`, active: true },
      ]} />
      <Title>Editar Tipo de Producto</Title>
      <Loader loading={loading || posting} />

      {tipoProducto && 
        <TipoProductoForm
          tipoProducto={tipoProducto}
          error={error}
          onSubmit={handleSubmit}
        />
      }

      {snackOpen && <Snackbar
        message="Datos guardados"
        color="success"
        open={snackOpen}
        setOpen={setSnackOpen}
      />}
    </div>
  );
}

export default hasPermission([PERMISO.TiposProductosModificar])(EditarTipoProducto);
