import React from 'react';
import Admin from 'components/admin';
import OrigenForm from 'components/listados/OrigenForm';
import { PERMISO } from '../../constants';

export default function() {
  return (
    <Admin
      createTitle="Nuevo Origen"
      listTitle="Origenes"
      editTitle="Editar Origen"
      routePath="/admin/origenes"
      apiPath="/origenes"
      FormComponent={OrigenForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.OrigenesVer]}
      modifyPerms={[PERMISO.OrigenesModificar]}
      headerItems={['ID', 'Nombre']}
      rowItems={row => [row.id, row.nombre]}
    />
  )
}