import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs } from '@material-ui/core';
import { Link } from './Link';

export function Breadcrumbs({ items }) {
  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      {items.map(({ key, title, to, active }) => (
        <Link key={key} color={active ? 'primary' : 'inherit'} to={to}>{title}</Link>
      ))}
    </MuiBreadcrumbs>
  )
}