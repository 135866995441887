import React from 'react';
import { Router } from '@reach/router';

import NotFoundPage from '../NotFoundPage';
import ListadoAyudas from './ListadoAyudas';
import AprobarAyuda from './AprobarAyuda';

export default function() {
  return (
    <Router>
      <ListadoAyudas path="/" />
      <AprobarAyuda path=":idAyuda/aprobar" />
      <NotFoundPage default />
    </Router>
  )
}
