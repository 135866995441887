import React, { useState } from 'react'
import { Loader, Snackbar, Breadcrumbs, Title } from 'components/ui';
import ProveedorForm from 'components/proveedor/ProveedorForm';
import { hasPermission } from 'auth';
import { useMutation, useQuery } from 'api';
import { PERMISO } from '../../constants';

function EditarProveedor({ id }) {
  const [snackOpen, setSnackOpen] = useState(false);
  const { data: proveedor, loading } = useQuery(`/proveedores/${id}`);
  const [editarProveedor, { loading: posting, error }] = useMutation(`/proveedores/${id}`, { method: 'patch' });

  const handleSubmit = async values => {
    const { error } = await editarProveedor({ body: values });

    if (!error) {
      // navigate('/admin/proveedores');
      setSnackOpen(true);
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'proveedores', title: 'Proveedores', to: '/admin/proveedores' },
        { key: 'actual', title: proveedor ? proveedor.razonSocial : id, to: `/admin/proveedores/${id}`, active: true },
      ]} />
      <Title>Editar Proveedor</Title>
      <Loader loading={loading || posting} />

      {proveedor && 
        <ProveedorForm
          proveedor={proveedor}
          error={error}
          onSubmit={handleSubmit}
        />
      }

      {snackOpen && <Snackbar
        message="Datos guardados"
        color="success"
        open={snackOpen}
        setOpen={setSnackOpen}
      />}
    </div>
  );
}

export default hasPermission([PERMISO.ProveedoresModificar])(EditarProveedor);
