import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MenuItem, Typography, InputAdornment } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { Formik, Form, Fab, ApiErrorMessage } from 'components/ui';
import { validateRut, validateLength } from 'helpers';
import { useQuery } from '../../api';

function BeneficiarioForm ({ beneficiario, error, onSubmit }) {
  const { data: tiposDireccion } = useQuery('/tipos_direccion');

  const initialValues = {
    rut: beneficiario ? beneficiario.rut : '',
    primerNombre: beneficiario ? beneficiario.primerNombre : '',
    segundoNombre: beneficiario ? beneficiario.segundoNombre : '',
    primerApellido: beneficiario ? beneficiario.primerApellido : '',
    segundoApellido: beneficiario ? beneficiario.segundoApellido : '',
    fechaNacimiento: beneficiario ? beneficiario.fechaNacimiento : '',
    sexo: beneficiario ? beneficiario.sexo : '',
    direccion: {
      idTipo: beneficiario ? beneficiario.direccion.idTipo.toString() : '1',
      calle: beneficiario ? beneficiario.direccion.calle : '',
      numero: beneficiario ? beneficiario.direccion.numero : '',
      villa: beneficiario ? beneficiario.direccion.villa : '',
      sector: beneficiario ? beneficiario.direccion.sector : '',
      otro: beneficiario ? beneficiario.direccion.otro : '',
    },
    telefono1: beneficiario ? beneficiario.telefono1 : '',
    telefono2: beneficiario ? beneficiario.telefono2 : '',
    folioRSH: beneficiario ? beneficiario.folioRSH : '',  
    estado: beneficiario ? !beneficiario.estado : 1,
  };

  const handleFormSubmit = (input) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    const { direccion, ...beneficiarioValues } = values;
    beneficiarioValues.direccion = direccion;
    beneficiarioValues.estado = values.estado ? 0 : 1;

    onSubmit(beneficiarioValues);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm}
    >
      {({ values, errors, dirty, handleSubmit }) => (
        <Form alignItems="center">

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Datos Generales</Typography>
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField required name="rut" label="RUT" fluid />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField required name="primerNombre" label="Primer Nombre" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField name="segundoNombre" label="Segundo Nombre" fluid validate={validateLength(0, 50)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField required name="primerApellido" label="Primer Apellido" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField name="segundoApellido" label="Segundo Apellido" fluid validate={validateLength(0, 50)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.Select required name="sexo" label="Sexo" fluid align="left">
              <MenuItem value="M">Masculino</MenuItem>
              <MenuItem value="F">Femenino</MenuItem>
            </Form.Select>
          </Form.Field>

          <Form.Field xs={12} md={3}>
            <Form.Datepicker required name="fechaNacimiento" label="Fecha nacimiento" fluid InputLabelProps={{ shrink: true }} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name="telefono1" label="Teléfono 1" fluid validate={validateLength(0, 20)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField name="telefono2" label="Teléfono de Contacto" fluid validate={validateLength(0, 20)} />
          </Form.Field>
          <Form.Field xs={12} md={2}>
            <Form.TextField
              name="folioRSH"
              tooltip="Folio Registro Social de Hogares"
              label="Folio RSH"
              fluid
              validate={validateLength(0, 50)}
              InputProps={{
                startAdornment: <InputAdornment position="start">#</InputAdornment>,
              }}
            />
          </Form.Field>
          <Form.Field xs={12} md={1}>
            <Form.Checkbox name="estado" label="Activo" labelPlacement="top" />
          </Form.Field>

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Dirección</Typography>
          </Form.Field>
          <Form.Field xs={12} md={1}>
            <Form.Select required name="direccion.idTipo" label="Área" fluid align="left">
              {(tiposDireccion || []).map(td => <MenuItem key={td.id} value={td.id}>{td.nombre}</MenuItem>)}
            </Form.Select>
          </Form.Field>
          {values.direccion.idTipo.toString() === '1' ? (
            <>
              <Form.Field xs={12} md={3}>
                <Form.TextField required name="direccion.calle" label="Calle" fluid validate={validateLength(0, 100)} />
              </Form.Field>
              <Form.Field xs={12} md={2}>
                <Form.TextField required name="direccion.numero" label="Número" fluid validate={validateLength(0, 10)} />
              </Form.Field>
              <Form.Field xs={12} md={3}>
                <Form.TextField name="direccion.villa" label="Villa/Población" fluid validate={validateLength(0, 100)} />
              </Form.Field>
              <Form.Field xs={12} md={3}>
                <Form.TextField name="direccion.sector" label="Sector" fluid validate={validateLength(0, 100)} />
              </Form.Field>
            </>
          ) : (
            <>
              <Form.Field xs={12} md={3}>
                <Form.TextField required name="direccion.calle" label="Calle" fluid validate={validateLength(0, 100)} />
              </Form.Field>
              <Form.Field xs={12} md={3}>
                <Form.TextField required name="direccion.sector" label="Sector" fluid validate={validateLength(0, 100)} />
              </Form.Field>
              <Form.Field xs={12} md={3}>
                <Form.TextField name="direccion.villa" label="Comunidad" fluid validate={validateLength(0, 100)} />
              </Form.Field>
              <Form.Field xs={12} md={2}>
                <Form.TextField name="direccion.otro" label="Otro" fluid validate={validateLength(0, 100)} />
              </Form.Field>
            </>
          )}

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.rut) {
    errors.rut = 'El RUT es obligatorio'
  } else if(!validateRut(values.rut)) {
    errors.rut = 'El RUT ingresado no es correcto';
  }
  if (!values.primerNombre) {
    errors.primerNombre = 'El nombre es obligatorio';
  }
  if (!values.primerApellido) {
    errors.primerApellido = 'El apellido es obligatorio';
  }
  if (!values.fechaNacimiento) {
    errors.fechaNacimiento = 'La fecha de nacimiento es obligatoria';
  }
  if (!values.sexo) {
    errors.sexo = 'El sexo es obligatorio';
  }
  if (!values.telefono1) {
    errors.telefono1 = 'El teléfono es obligatorio';
  }
  if (!values.direccion.calle) {
    errors.direccion = { ...errors.direccion, calle: 'La calle es obligatoria' };
  }
  const idTipoDireccion = values.direccion.idTipo.toString();
  if (idTipoDireccion === '1' && !values.direccion.numero) {
    errors.direccion = { ...errors.direccion, numero: 'El número es obligatorio' };
  } else if (idTipoDireccion === '2' && !values.direccion.sector) {
    errors.direccion = { ...errors.direccion, sector: 'El sector es obligatorio' };
  }
  return errors;
}

BeneficiarioForm.propTypes = {
  beneficiario: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default BeneficiarioForm;