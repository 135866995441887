import React from 'react';
import { Router } from '@reach/router';

import NotFoundPage from '../NotFoundPage';
import ListadoProcesamiento from './ListadoProcesamientos';
import IngresarProcesamiento from './IngresarProcesamiento';

export default function() {
  return (
    <Router>
      <ListadoProcesamiento path="/" />
      <IngresarProcesamiento path="/nuevo" />
      <NotFoundPage default />
    </Router>
  )
}