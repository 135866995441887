import React from 'react'
import { IconButton, makeStyles, Paper } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { getFileIcon } from './AdjuntoDownloadButton';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function AdjuntoCard({ adjunto, onDelete }) {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root}>
      <a
        href={`${API_ENDPOINT}/adjuntos/${adjunto.id}/download`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <div className={classes.container}>
          {getFileIcon(adjunto.nombre)}
          <span>{adjunto.nombre}</span>
        </div>
      </a>
      <IconButton aria-label="delete" onClick={onDelete} size="small" className={classes.deleteButton}>
        <Close  />
      </IconButton>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    padding: 10
  },
  link: {
    color: theme.palette.text.primary
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  }
}));


export default AdjuntoCard;
