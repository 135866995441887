import React from 'react';
import moment from 'moment';
import { chain, sum } from 'lodash';
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import { formatRut, formatBeneficiario, formatDireccion, formatPrecio } from '../../helpers';
import { styles, DataRow } from './pdfComponents';

Font.register({
  family: 'Roboto-Bold',
  src: require('typeface-roboto/files/roboto-latin-500.woff'),
  fontStyle: 'normal',
  fontWeight: 'bold'
});

export default function ComprobanteProcesamientoPdf ({ procesamiento: proc }) {
  const productosEntrada = proc.productosDetalle.filter(pd => !pd.salida);

  return (
    <Document title={`Comprobante de procesamiento #${proc.id}`}>
      <Page size="A4">
        <View style={styles.page}>
          <View style={[styles.row, styles.divider]}>
            <View style={{width: '30%', flexGrow: 1}}>
              <Image src='/img/logo192.png' style={{width: 60, height: 60}} />
            </View>
            <View style={{width: '70%', flexGrow: 1}}>
              <Text style={styles.titleText}>Comprobante de Procesamiento</Text>
            </View>
          </View>
          <View style={[styles.row, styles.divider, { marginTop: 20, marginBottom: 20, paddingBottom: 20 }]}>
            <DataRow title="Procesamiento #" value={proc.id} />
            <DataRow title="Funcionario" value={`${proc.usuarioCreador.nombres} ${proc.usuarioCreador.apellidos}`} />
            <DataRow title="Fecha" value={moment(proc.fechaIngreso).format('DD/MM/YYYY HH:mm')} />
            <DataRow title="Proveedor" value={proc.proveedor.razonSocial} />
            <DataRow title="RUT" value={formatRut(proc.proveedor.rut)} />
          </View>
          <View style={[styles.row, { borderBottom: '1pt solid #888'}]}>
            <View style={{width: '30%'}}>
              <Text style={styles.bold}>Producto</Text>
            </View>
            <View style={{width: '30%'}}>
              <Text style={styles.bold}>Detalle</Text>
            </View>
            <View style={{width: '15%'}}>
              <Text style={styles.bold}>Cantidad</Text>
            </View>
          </View>
          {productosEntrada.map(pd => (
            <View style={[styles.row]} key={pd.idProducto}>
              <View style={{width: '30%'}}>
                <Text>{pd.producto.descripcion}</Text>
              </View>
              <View style={{width: '30%'}}>
                <Text>{pd.producto.detalle}</Text>
              </View>
              <View style={{width: '15%'}}>
                <Text>{pd.cantidad}</Text>
              </View>
            </View>
          ))}
          <View style={[styles.row, { flexGrow: 1 }]}></View>
          {/* <View style={styles.row}>
            <Text style={[styles.bold, { fontSize: 12 }]}>Recibe</Text>
          </View> */}
          <View style={styles.row}>
            <View style={{width: '70%'}}>
              {/* <Text>
                <Text style={styles.bold}>Nombre: </Text>
                {entrega.nombreReceptor || formatBeneficiario(entrega.beneficiario)}
              </Text>
              <Text>
                <Text style={styles.bold}>RUT: </Text>
                {formatRut(entrega.rutReceptor || entrega.beneficiario.rut)}
              </Text> */}
            </View>
            <View style={{width: '30%', borderTop: '1pt solid black', textAlign: 'center'}}>
              <Text>Firma</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
