import React, { useState } from 'react'
import { Snackbar } from '@material-ui/core';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import EntregaEditForm from 'components/entrega/EntregaEditForm';
import { hasPermission } from 'auth';
import { useMutation, useQuery } from 'api';
import { PERMISO } from '../../constants';

function EditarEntrega({ id }) {
  const [snackOpen, setSnackOpen] = useState(false);
  const { data: entrega, loading } = useQuery(`/entregas/${id}`);
  const [editarEntrega, { loading: posting, error }] = useMutation(`/entregas/${id}`, { method: 'patch' });

  const handleSubmit = async values => {
    const { error } = await editarEntrega({ body: values });

    if (!error) {
      setSnackOpen(true);
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'list', title: 'Entregas', to: '/entregas' },
        { key: 'actual', title: `Entrega #${id}`, to: `/entregas/${id}/editar`, active: true },
      ]} />
      <Title>Editar Entrega - Guía de Salida</Title>
      <Loader loading={loading || posting} />

      {entrega && (
        <EntregaEditForm
          entrega={entrega}
          error={error}
          onSubmit={handleSubmit}
        />
      )}

      {snackOpen && <Snackbar
        message="Datos guardados"
        color="success"
        open={snackOpen}
        setOpen={setSnackOpen}
      />}
    </div>
  );
}

export default hasPermission([PERMISO.EntregasModificar])(EditarEntrega);
