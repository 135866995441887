import React, { useState } from 'react'
import { Loader, Snackbar, Breadcrumbs, Title } from 'components/ui';
import BeneficiarioForm from 'components/beneficiario/BeneficiarioForm';
import { hasPermission } from 'auth';
import { useMutation, useQuery } from 'api';
import { formatBeneficiario } from '../../helpers';
import { PERMISO } from '../../constants';

function EditarBeneficiario({ id }) {
  const [snackOpen, setSnackOpen] = useState(false);
  const { data: beneficiario, loading } = useQuery(`/beneficiarios/${id}`);
  const [editarBeneficiario, { loading: posting, error }] = useMutation(`/beneficiarios/${id}`, { method: 'PATCH' });

  const handleSubmit = async values => {
    const { error } = await editarBeneficiario({ body: values });

    if (!error) {
      setSnackOpen(true);
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'beneficiarios', title: 'Usuarios', to: '/admin/usuarios' },
        { key: 'actual', title: beneficiario ? formatBeneficiario(beneficiario) : id, to: `/admin/usuarios/${id}`, active: true },
      ]} />
      <Title>Editar Usuario</Title>
      <Loader loading={loading || posting} />

      {beneficiario && 
        <BeneficiarioForm
          beneficiario={beneficiario}
          error={error}
          onSubmit={handleSubmit}
        />
      }

      {snackOpen && <Snackbar
        message="Datos guardados"
        color="success"
        open={snackOpen}
        setOpen={setSnackOpen}
      />}
    </div>
  );
}

export default hasPermission([PERMISO.BeneficiariosModificar])(EditarBeneficiario);
