import React, { useState } from 'react';
import { Grid, TableRow, TableCell, Button } from '@material-ui/core';
import { GetApp as DownloadIcon } from '@material-ui/icons';
import { omit } from 'lodash';
import moment from 'moment';
// import { pdf } from '@react-pdf/renderer';
import { hasPermission } from 'auth';
import { DataTable, Loader, CompactTableCell, TextField, Breadcrumbs, Title, FilterGrid, DownloadButton } from 'components/ui';
import { useQuery, useLazyQuery } from 'api';
import { formatRut } from 'helpers/usuario';
import { formatDate } from 'helpers/format';
import { PERMISO } from '../../constants';

function ReporteUsuarios() {
  const [state, setState] = useState({ page: 1, pageSize: 25 });
  
  const { data: items, loading, refetch } = useQuery('/reportes/usuarios', { query: state });
  const [descargarReporte, { loading: downloading }] = useLazyQuery('/reportes/usuarios');

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    handleFilter(newState);
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
    
  const getQuery = (state) => {
    const query = {};

    if (state.nombre) {
      query.nombre = state.nombre;
    }
    if (state.fechaDesde) {
      query.fechaDesde = moment.utc(state.fechaDesde).toISOString();
    }
    if (state.fechaHasta) {
      query.fechaHasta = moment.utc(state.fechaHasta).toISOString();
    }

    query.page = state.page;
    query.pageSize = state.pageSize;
    
    return query;
  }

  const handleFilter = (filterState) => {
    const query = getQuery(filterState);
    refetch({ query });
  }

  const handleDownloadExcel = async () => {
    const query = omit(getQuery(state), ['page', 'pageSize']);
    const content = await descargarReporte({ type: 'blob', query: { ...query, format: 'xls' } });
    const filename = `reporte-funcionarios-${moment().format('YYYY-MM-DD-HH-mm')}.xlsx`;
    return { filename, content };
  }

  const handleDownloadCSV = async () => {
    const query = omit(getQuery(state), ['page', 'pageSize']);
    const content = await descargarReporte({ type: 'blob', query: { ...query, format: 'csv' } });
    const filename = `reporte-funcionarios-${moment().format('YYYY-MM-DD-HH-mm')}.csv`;
    return { filename, content };
  }

  // const generatePdfDocument = React.useCallback(async () => {
  //   const blob = await pdf((
  //     <ReporteBeneficiarioDocument
  //       productos={items.nodes}
  //       proveedor={state.proveedor}
  //       origen={state.origen}
  //       producto={state.producto}
  //     />
  //   )).toBlob();
  //   saveAs(blob, `reporte-usuarios-${moment().format('YYYY-MM-DD-HH-mm')}.pdf`);
  // }, [items, state]);
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'actual', title: 'Reporte Funcionarios', to: '/reportes/funcionarios', active: true },
      ]} />
      <Title>Reporte Funcionarios</Title>
      <Loader loading={loading || downloading} />
      
      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={3}>
          <TextField
            label="Nombre"
            fluid
            onChange={(e) => setState({ ...state, nombre: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label="Registrado Desde"
            type="date"
            InputLabelProps={{shrink: true}}
            fluid
            onChange={(e) => setState({ ...state, fechaDesde: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label="Registrado Hasta"
            type="date"
            InputLabelProps={{shrink: true}}
            fluid
            onChange={(e) => setState({ ...state, fechaHasta: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button variant="contained" color="primary" size="small" onClick={() => handleFilter(state)}>Filtrar</Button>
        </Grid>
        <Grid item xs={12} align="right">
          <DownloadButton
            variant="contained"
            color="primary"
            size="small"
            type='text/csv;charset=utf-8'
            fetchFile={handleDownloadCSV}
            startIcon={<DownloadIcon />}
            style={{marginRight: 10}}
          >
            Descargar CSV
          </DownloadButton>
          <DownloadButton
            variant="contained"
            color="primary"
            size="small"
            type='vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
            fetchFile={handleDownloadExcel}
            startIcon={<DownloadIcon />}
            style={{marginRight: 10}}
          >
            Descargar Excel
          </DownloadButton>
          {/* <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<PictureAsPdf />}
            disabled={!items || items.count === 0}
            onClick={generatePdfDocument}
          >
            Generar PDF
          </Button> */}
        </Grid>
      </FilterGrid>

      <DataTable
        data={items}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <ReporteHeader />}
        renderRow={(item) => (
          <ReporteRow
            key={item.id}
            item={item}
          />
        )}
      />
    </div>
  );
}

function ReporteHeader() {
  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>RUT</TableCell>
      <TableCell>Nombre</TableCell>
      <TableCell>Apellidos</TableCell>
      <TableCell>Departamento</TableCell>
      <TableCell>Rol</TableCell>
      <TableCell>Activo</TableCell>
      <TableCell>Fecha Registro</TableCell>
    </TableRow>
  )
}

function ReporteRow({ item }) {

  return (
    <TableRow>
      <CompactTableCell>{item.id}</CompactTableCell>
      <CompactTableCell>{formatRut(item.rut)}</CompactTableCell>
      <CompactTableCell>{item.nombres}</CompactTableCell>
      <CompactTableCell>{item.apellidos}</CompactTableCell>
      <CompactTableCell>{item.departamento}</CompactTableCell>
      <CompactTableCell>{item.rol}</CompactTableCell>
      <CompactTableCell>{item.activo}</CompactTableCell>
      <CompactTableCell>{formatDate(item.fechaRegistro)}</CompactTableCell>
    </TableRow>
  )
}

export default hasPermission([PERMISO.ReportesUsuarios])(ReporteUsuarios);
