import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { TableRow, TableCell, IconButton, Tooltip, Grid, Button, Tabs, Tab } from '@material-ui/core';
import { Add as AddIcon, PictureAsPdf as PdfIcon, Delete, Comment } from '@material-ui/icons';
import { pdf } from '@react-pdf/renderer';
import { hasPermission, useAuth } from 'auth';
import { Fab, DataTable, Loader, CompactTableCell, Breadcrumbs, Title, ActionsButton, FilterGrid, Lookup, TextField, useTableSort, SortableTableCell } from 'components/ui';
import { useQuery, useApi, useLazyQuery } from 'api';
import { formatRut } from 'helpers/usuario';
import { formatDate, formatBeneficiario, formatDireccion, formatProductoItem, formatBeneficiarioDetallado } from 'helpers/format';
import { navigate } from '@reach/router';
import ComprobanteAyudaPdf from 'components/pdf/ComprobanteAyudaPdf';
import { PERMISO } from '../../constants';
import { getEstadoAyudaIcon } from '../../helpers';

function ListadoAyudas() {
  const auth = useAuth();
  const [tab, setTab] = useState(0);
  const [state, setState] = useState({ page: 1, pageSize: 10, entregadas: 1 });
  const [order, onOrderChange] = useTableSort();
  
  const canEdit = auth.hasPermission([PERMISO.AyudasModificar]);

  const [buscarBeneficiarios] = useLazyQuery('/beneficiarios');
  const { data: ayudas, loading, refetch } = useQuery('/ayudas', { query: state });
  const callApi = useApi();
    
  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: getQuery(newState) });
  }

  const getQuery = (state) => {
    const query = {};

    if (state.idBeneficiario) {
      query.idBeneficiario = state.idBeneficiario;
    }
    if (state.decreto) {
      query.decreto = state.decreto;
    }
    if (state.fechaDesde) {
      query.fechaDesde = moment.utc(state.fechaDesde).toISOString();
    }
    if (state.fechaHasta) {
      query.fechaHasta = moment.utc(state.fechaHasta).toISOString();
    }

    query.entregadas = state.entregadas;
    query.page = state.page;
    query.pageSize = state.pageSize;

    query.orderBy = order.field;
    query.orderCriteria = order.criteria;
    
    return query;
  }

  const handleFilter = (filterState) => {
    const query = getQuery(filterState);
    refetch({ query });
  }

  const handleDesactivar = async (id) => {
    await callApi(`/ayudas/${id}`, { method: 'delete' });
    await refetch({ query: state });
  }

  useEffect(() => {
    if (order.field) {
      handleFilter(state);
    }
  }, [order.field, order.criteria]);

  const handleTabChange = (newTab) => {
    setTab(newTab);
    onChange({ entregadas: (newTab === 0) ? 1 : 0 })
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'listado', title: 'Ayudas Sociales', to: '/ayudas', active: true },
      ]} />
      <Title>Ayudas Sociales</Title>
      <Loader loading={loading} />      

      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Lookup
            name="beneficiario"
            label="Usuario"
            size="small"
            fluid
            onChange={(e, val) => onChange({ idBeneficiario: val ? val.id : null, page: 1 })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? formatBeneficiarioDetallado(option) : ''}
            fetcher={(busqueda) => buscarBeneficiarios({ query: { busqueda } }).then(resp => resp.data.nodes)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            name="decreto"
            label="Decreto"
            fluid
            onChange={(e) => setState({ ...state, decreto: e.target.value, page: 1 })}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label="Creada Desde"
            type="date"
            InputLabelProps={{shrink: true}}
            fluid
            onChange={(e) => setState({ ...state, fechaDesde: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            label="Creada Hasta"
            type="date"
            InputLabelProps={{shrink: true}}
            fluid
            onChange={(e) => setState({ ...state, fechaHasta: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button variant="contained" color="primary" size="small" onClick={() => handleFilter(state)}>Filtrar</Button>
        </Grid>
      </FilterGrid>

      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, val) => handleTabChange(val)}
      >
        <Tab value={0} label="Ayudas Entregadas" />
        <Tab value={1} label="Ayudas Sin Entregar" />
      </Tabs>

      <DataTable
        data={ayudas}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <AyudaHeader order={order} onOrderChange={onOrderChange} />}
        renderRow={(ayuda) => (
          <AyudaRow
            key={ayuda.id}
            ayuda={ayuda}
            canEdit={canEdit}
            onDesactivar={handleDesactivar}
          />
        )}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate('/ayudas/nuevo')}
        >
          <AddIcon />
        </Fab>
      )}

    </div>
  );
}

function AyudaHeader(props) {
  return (
    <>
      <TableRow>
        <SortableTableCell {...props} id="id" label="ID" />
        <TableCell>RUT</TableCell>
        <TableCell>Usuario</TableCell>
        <TableCell>Dirección</TableCell>
        <TableCell>Decreto Nº</TableCell>
        <SortableTableCell {...props} id="fechaIngreso" label="Fecha Creación" />
        <TableCell>Meses Ayuda</TableCell>
        <TableCell>Habilitada</TableCell>
        <TableCell>Productos</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </>
  )
}

function AyudaRow({ ayuda, canEdit, onDesactivar }) {
  const cantProds = Math.max(1, ayuda.productosDetalle.length);
  const pd0 = ayuda.productosDetalle[0];
  
  const generatePdfDocument = React.useCallback(async () => {
    const blob = await pdf(<ComprobanteAyudaPdf ayuda={ayuda} />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
    // saveAs(blob, `ayuda-${ayuda.id}.pdf`);
  }, [ayuda]);

  return (
    <>
      <TableRow>
        <CompactTableCell rowSpan={cantProds}>{ayuda.id}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatRut(ayuda.beneficiario.rut)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatBeneficiario(ayuda.beneficiario)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatDireccion(ayuda.beneficiario.direccion)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{ayuda.decreto}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatDate(ayuda.fechaIngreso)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{ayuda.cantidadMeses}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>
          {getEstadoAyudaIcon(ayuda.estado)}
          {ayuda.observaciones && (
            <Tooltip title={ayuda.observaciones}>
              <Comment color='disabled' />
            </Tooltip>
          )}
        </CompactTableCell>
        <CompactTableCell>{formatProductoItem(pd0)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>
          {ayuda.estado === 1 && (
            <IconButton aria-label="descargar pdf" onClick={generatePdfDocument}>
              <PdfIcon style={{color: "firebrick"}} />
            </IconButton>
          )}
        </CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>
          {canEdit && ayuda.estado < 2 && (
            <ActionsButton
              items={[
                { key: 'desactivar', text: 'Desactivar', icon: <Delete/>, confirmText: 'Confirme si desea desactivar la ayuda', onClick: () => onDesactivar(ayuda.id) }
              ]}
            />
          )}
        </CompactTableCell>
      </TableRow>
      {ayuda.productosDetalle.slice(1).map(pd => (
        <TableRow key={pd.idProducto}>
          <CompactTableCell>{formatProductoItem(pd)}</CompactTableCell>
        </TableRow>
      ))}
    </>
  )
}

export default hasPermission([PERMISO.AyudasVer])(ListadoAyudas);
