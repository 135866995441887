import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

export default function RecepcionChart({ data }) {
  
  const [optionsGral] = useState({
    chart: {
      type: 'bar',
      height: 350,
      // toolbar: {
      //   show: false
      // }
    },
    title: {
      text: 'Productos ingresados por mes',
      align: 'center'
    },
    colors: ['#053c25'],
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    },
    yaxis: {
      title: {
        text: 'Cantidad Productos'
      },
      labels: {
        show: false,
      }
    },
  })

  const seriesGral = [{
    name: 'Recepciones',
    data: data.map(d => d.cantidad)
  }];

  return (
    <ReactApexChart
      type="bar"
      height={350}
      options={optionsGral}
      series={seriesGral}
    />
  )
}