import React, { useState } from 'react'
import { Loader, Breadcrumbs, Title } from 'components/ui';
import RecepcionForm from 'components/recepcion/RecepcionForm';
import { hasPermission } from 'auth';
import { useMutation, useQuery } from 'api';
import { PERMISO } from '../../constants';
import { Snackbar } from '@material-ui/core';

function EditarRecepcion({ id }) {
  const [snackOpen, setSnackOpen] = useState(false);
  const { data: recepcion, loading } = useQuery(`/recepciones/${id}`);
  const [editarRecepcion, { loading: posting, error }] = useMutation(`/recepciones/${id}`, { method: 'patch' });

  const handleSubmit = async values => {
    const { error } = await editarRecepcion({ body: values });

    if (!error) {
      setSnackOpen(true);
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'list', title: 'Recepciones', to: '/recepciones' },
        { key: 'actual', title: `Recepción #${id}`, to: `/recepciones/${id}/editar`, active: true },
      ]} />
      <Title>Editar Recepción - Guía de Entrada</Title>
      <Loader loading={loading || posting} />

      {recepcion && (
        <RecepcionForm
          recepcion={recepcion}
          error={error}
          onSubmit={handleSubmit}
        />
      )}

      {snackOpen && <Snackbar
        message="Datos guardados"
        color="success"
        open={snackOpen}
        setOpen={setSnackOpen}
      />}
    </div>
  );
}

export default hasPermission([PERMISO.RecepcionesModificar])(EditarRecepcion);
