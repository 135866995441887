import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { CloudUpload } from '@material-ui/icons';
import { useMutation } from 'api';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

export function UploadButton({ onUpload, accept }) {
  const classes = useStyles();
  const [subirArchivo, { loading }] = useMutation('/adjuntos', { method: 'post' });

  const handleFileUpload = async (file) => {
    try {
      const form = new FormData();
      form.append('file', file);

      const res = await subirArchivo({ body: form })
      onUpload(res.data);
    } catch(err) {
      console.error("FETCH ERROR", err);
    }
  }

  const handleFileSelect = (e) => {
    const { validity, files } = e.target;
    if (validity.valid) {
      for(const file of files) {
        handleFileUpload(file);
      }
    } else {
      alert("El archivo seleccionado no es una imagen válida");
    }
  }

  return (
    <div className={classes.root}>
      <input
        accept={accept}
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleFileSelect}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span" startIcon={<CloudUpload />} disabled={loading}>
          Subir Archivo
        </Button>
      </label>
    </div>
  );
}
