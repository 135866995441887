import React from 'react';
import moment from 'moment';
import { sum } from 'lodash';
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import { formatRut, formatPrecio } from '../../helpers';
import { styles, DataRow } from './pdfComponents';

Font.register({
  family: 'Roboto-Bold',
  src: require('typeface-roboto/files/roboto-latin-500.woff'),
  fontStyle: 'normal',
  fontWeight: 'bold'
});

export default function ComprobanteRecepcionPdf ({ recepcion }) {
  const valorTotal = Math.round(sum(recepcion.productosDetalle.map(pd => pd.precio * pd.cantidad)));

  return (
    <Document title={`Comprobante de recepción #${recepcion.id}`}>
      <Page size="A4">
        <View style={styles.page}>
          <View style={[styles.row, styles.divider]}>
            <View style={{width: '30%', flexGrow: 1}}>
              <Image src='/img/logo192.png' style={{width: 60, height: 60}} />
            </View>
            <View style={{width: '70%', flexGrow: 1}}>
              <Text style={styles.titleText}>Comprobante de Recepción</Text>
            </View>
          </View>
          <View style={[styles.row, styles.divider, { marginTop: 20, marginBottom: 20, paddingBottom: 20 }]}>
            <DataRow title="Entrega #" value={recepcion.id} />
            <DataRow title="Fecha" value={moment(recepcion.fechaRecepcion).format('DD/MM/YYYY HH:mm')} />
            <DataRow title="Tipo Documento" value={recepcion.tipoDocumento.nombre} />
            <DataRow title="Documento" value={recepcion.documento} />
            <DataRow title="Proveedor" value={recepcion.proveedor.razonSocial} />
            <DataRow title="RUT" value={formatRut(recepcion.proveedor.rut)} />
            <DataRow title="Origen" value={recepcion.origen && recepcion.origen.nombre} />
            <DataRow title="Procesamiento #" value={recepcion.idProcesamiento} />
          </View>
          <View style={[styles.row, { borderBottom: '1pt solid #888'}]}>
            <View style={{width: '30%'}}>
              <Text style={styles.bold}>Producto</Text>
            </View>
            <View style={{width: '30%'}}>
              <Text style={styles.bold}>Detalle</Text>
            </View>
            <View style={{width: '15%'}}>
              <Text style={styles.bold}>Cantidad</Text>
            </View>
            <View style={{width: '15%', textAlign: 'right'}}>
              <Text style={styles.bold}>Valor</Text>
            </View>
          </View>
          {recepcion.productosDetalle.map(pd => (
            <View style={[styles.row]} key={pd.id}>
              <View style={{width: '30%'}}>
                <Text>{pd.producto.descripcion}</Text>
              </View>
              <View style={{width: '30%'}}>
                <Text>{pd.producto.detalle}</Text>
              </View>
              <View style={{width: '15%'}}>
                <Text>{pd.producto.tipo.esMonto ? '' : pd.cantidad}</Text>
              </View>
              <View style={{width: '15%', textAlign: 'right'}}>
                <Text>${formatPrecio(pd.producto.tipo.esMonto ? pd.cantidad : pd.precio)}</Text>
              </View>
            </View>
          ))}
          <View style={[styles.row, { marginTop: 20 }]}>
            <View style={{width: '30%'}}></View>
            <View style={{width: '30%'}}></View>
            <View style={{width: '15%'}}>
              <Text style={styles.bold}>Valor Total:</Text>
            </View>
            <View style={{width: '15%', textAlign: 'right'}}>
              <Text style={styles.bold}>${formatPrecio(valorTotal)}</Text>
            </View>
          </View>
          <View style={[styles.row, { flexGrow: 1 }]}></View>
          <View style={styles.row}>
            <Text style={[styles.bold, { fontSize: 12 }]}>Recibe</Text>
          </View>
          <View style={styles.row}>
            <View style={{width: '70%'}}>
              <Text>
                <Text style={styles.bold}>Nombre: </Text>
                {`${recepcion.usuarioCreador.nombres} ${recepcion.usuarioCreador.apellidos}`}
              </Text>
            </View>
            <View style={{width: '30%', borderTop: '1pt solid black', textAlign: 'center'}}>
              <Text>Firma</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
