import React from 'react'
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import EntregaForm from 'components/entrega/EntregaForm';
import { hasPermission } from 'auth';
import { useMutation } from 'api';
import { PERMISO } from '../../constants';

function IngresarEntrega() {
  const [ingresarEntrega, { loading, error }] = useMutation('/entregas', { method: 'post' });

  const handleSubmit = async values => {
    const { error } = await ingresarEntrega({ body: values });

    if (!error) {
      navigate('/entregas');
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'list', title: 'Entregas', to: '/entregas' },
        { key: 'actual', title: 'Nueva Entrega', to: '/entregas/nuevo', active: true },
      ]} />
      <Title>Nueva Entrega - Guía de Salida</Title>
      <Loader loading={loading} />

      <EntregaForm
        error={error}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default hasPermission([PERMISO.EntregasIngresar])(IngresarEntrega);
