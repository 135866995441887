import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Typography, IconButton, Button, InputAdornment, TextField } from '@material-ui/core';
import { Save as SaveIcon, Delete, Add } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { Formik, Form, Fab, ApiErrorMessage } from 'components/ui';
import { useLazyQuery } from 'api';
import { FieldArray } from 'formik';
import { validateLength, formatBeneficiarioDetallado, formatProducto, formatPrecio, formatRecepcion } from 'helpers';

function AyudaForm ({ error, onSubmit }) {
  const [requiereObservacion, setRequiereObservacion] = useState(false);
  const [motivosObservacion, setMotivoObservacion] = useState([]);
  const [buscarBeneficiarios] = useLazyQuery('/beneficiarios');
  const [buscarProductos] = useLazyQuery('/productos', { query: { entregaDirecta: 1, stock: 1 } });
  const [contarAyudasBeneficiario] = useLazyQuery('/ayudas/conteo');

  const initialValues = {
    beneficiario: null,
    decreto: '',
    cantidadMeses: '1',
    observaciones: '',
    productosDetalle: [],
  };

  const handleBeneficiarioChanged = async (e, beneficiario) => {
    let requiereObs = false;
    const motivos = [];
    if (beneficiario) {
      const res = await contarAyudasBeneficiario({ query: { idBeneficiario: beneficiario.id } });
      
      if (res.data.rut > 0) {
        requiereObs = true;
        motivos.push('El RUT del usuario registra ayudas en el último mes');
      }
      if (res.data.direccion > 0) {
        requiereObs = true;
        motivos.push('La dirección del usuario registra ayudas en el último mes');
      }
      if (res.data.rsh > 0) {
        requiereObs = true;
        motivos.push('El Registro Social de Hogares del usuario registra ayudas en el último mes');
      }
    }
    setRequiereObservacion(requiereObs);
    setMotivoObservacion(motivos);
  }

  const handleFormSubmit = (input) => {
    const values = _.chain(input)
      .omit(['beneficiario', 'productosDetalle'])
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    values.idBeneficiario = input.beneficiario.id;
    if (values.observaciones === '') {
      delete values.observaciones;
    }
    values.productosDetalle = input.productosDetalle.map(pd => ({
      idProducto: pd.producto.id,
      cantidad: pd.cantidad,
      origen: pd.origen?.id,
    }));

    onSubmit(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm}
    >
      {({ values, errors, dirty, handleSubmit }) => (
        <Form alignItems="center">
          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Datos Generales</Typography>
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.Lookup
              required
              name="beneficiario"
              label="Usuario"
              onChange={handleBeneficiarioChanged}
              fluid
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option ? formatBeneficiarioDetallado(option) : ''}
              fetcher={(busqueda) => buscarBeneficiarios({ query: { busqueda }}).then(resp => resp.data.nodes)}
            />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name="decreto" label="Decreto Nº" fluid validate={validateLength(0, 50)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name='cantidadMeses' label="Cantidad de Meses" type="number" fluid />
          </Form.Field>

          {requiereObservacion ? motivosObservacion.map((motivo, idx) => (
            <Form.Field xs={12}>
              <Alert key={idx} variant="filled" severity="warning">
                {motivo}
              </Alert>
            </Form.Field>
          )) : null}

          {requiereObservacion && (
            <>
              <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
                <Typography variant="subtitle1">Observaciones</Typography>
              </Form.Field>
              <Form.Field xs={12}>
                <Form.TextArea
                  required
                  name="observaciones"
                  placeholder="Para poder registrar una nueva ayuda para este usuario debe ingresar la razón..."
                  fluid
                  style={{borderColor: '#ff9800'}}
                  validate={val => {
                    if (requiereObservacion && !val) {
                      return 'Debe ingresar una observación'
                    }
                  }}
                />
              </Form.Field>
            </>
          )}

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Productos</Typography>
          </Form.Field>
          <FieldArray name="productosDetalle" render={arrayHelpers => (
            <>
              {values.productosDetalle.map((pd, index) => {
                const esMonto = !!pd.producto && pd.producto.tipo.esMonto;
                const idProducto = pd.producto?.id;

                return (
                  <React.Fragment key={index}>
                    <Form.Field xs={12} md={4}>
                      <Form.Lookup
                        required
                        name={`productosDetalle.${index}.producto`}
                        label="Producto"
                        fluid
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option ? formatProducto(option) : ''}
                        fetcher={(busqueda) => buscarProductos({ query: { entregaDirecta: 1, stock: 1, busqueda }}).then(resp => resp.data.nodes)}
                      />
                    </Form.Field>
                    <Form.Field xs={12} md={2}>
                      <TextField
                        label="Stock Disponible"
                        disabled
                        value={pd.producto ? (pd.producto.tipo.esMonto ? `$${formatPrecio(pd.producto.stock)}` : pd.producto.stock): ' '}
                      />
                    </Form.Field>
                    <Form.Field xs={12} md={2}>
                      <Form.TextField
                        required
                        name={`productosDetalle.${index}.cantidad`}
                        label={esMonto ? 'Monto' : 'Cantidad'}
                        type="number"
                        InputProps={{
                          startAdornment: esMonto ? <InputAdornment position="start">$</InputAdornment> : null,
                        }}
                        fluid
                      />
                    </Form.Field>
                    <Form.Field xs={12} md={3}>
                      <RecepcionLookup
                        key={idProducto}
                        index={index}
                        idProducto={idProducto}
                      />
                    </Form.Field>
                    <IconButton aria-label="delete" onClick={() => arrayHelpers.remove(index)}>
                      <Delete />
                    </IconButton>
                  </React.Fragment>
                );
              })}
              <Form.Field xs={12} align="left">
                <Form.ErrorMessage name="productosDetalle" />
                <Button variant="contained" size="small" startIcon={<Add/>} onClick={() => arrayHelpers.push({ producto: null, cantidad: '1' })}>
                  Agregar Producto
                </Button>
              </Form.Field>
            </>
          )} />

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function RecepcionLookup({ idProducto, index }) {
  const [buscarEntradas] = useLazyQuery('/recepciones');

  const fetcher = (busqueda) => {
    if (!idProducto) return Promise.resolve([]);
    return buscarEntradas({ query: { idProducto, busqueda }}).then(resp => resp.data.nodes)
  };

  return (
    <Form.Lookup
      name={`productosDetalle.${index}.origen`}
      label="# Entrada"
      fluid
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option ? formatRecepcion(option) : ''}
      fetcher={fetcher}
    />
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.beneficiario) {
    errors.beneficiario = 'El usuario es obligatorio'
  }
  if (!values.decreto) {
    errors.decreto = 'El decreto es obligatorio'
  }
  if (!values.cantidadMeses) {
    errors.cantidadMeses = 'La cantidad de meses es obligatoria'
  }

  if (values.productosDetalle.length === 0) {
    errors.productosDetalle = 'Debe ingresar al menos un producto'
  } else {
    errors.productosDetalle = values.productosDetalle.map(pd => {
      const pdErrors = {};
      if (!pd.producto) {
        pdErrors.producto = 'El producto es obligatorio';
      }
      let cantidad = Number(pd.cantidad);
      let stock = pd.producto ? Number(pd.producto.stock) : 0;
      if (_.isNaN(cantidad) || cantidad <= 0) {
        pdErrors.cantidad = 'La cantidad ingresada no es válida';
      } else if (cantidad * values.cantidadMeses > stock) {
        pdErrors.cantidad = 'Excede el stock';
      }

      return pdErrors;
    });

    if (_.isEmpty(errors.productosDetalle.filter(x => !_.isEmpty(x)))) {
      delete errors.productosDetalle;

      const unicos = _.uniq(values.productosDetalle.map(pd => pd.producto.id));
      if (unicos.length !== values.productosDetalle.length) {
        errors.productosDetalle = 'Hay productos repetidos'
      }
    }

  }
  
  return errors;
}

AyudaForm.propTypes = {
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default AyudaForm;