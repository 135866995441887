import React from 'react';
import { Router } from '@reach/router';

import NotFoundPage from '../NotFoundPage';
import ListadoRoles from './ListadoRoles';
import CrearRol from './CrearRol';
import EditarRol from './EditarRol';

export default function() {
  return (
    <Router>
      <ListadoRoles path="/" />
      <CrearRol path="/nuevo" />
      <EditarRol path=":id" />
      
      <NotFoundPage default />
    </Router>
  )
}