import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Warning, Check, Clear } from '@material-ui/icons';

export function getEstadoAyudaIcon(estado) {
  const title = getEstadoAyuda(estado);
  let icon = null;
  if (estado === 0) {
    icon = <Warning color='secondary' />;
  } else if (estado === 1) {
    icon = <Check color='primary'/>;
  } else if (estado === 2) {
    icon = <Clear color='error' />;
  }

  return <Tooltip title={title}>{icon}</Tooltip>
}

export function getEstadoAyuda(estado) {
  if (estado === 0) {
    return "Pendiente de aprobación";
  } else if (estado === 1) {
    return "Habilitada";
  } else if (estado === 2) {
    return "Cancelada";
  }
}
