import React from 'react';
import { Router } from '@reach/router';

import NotFoundPage from '../NotFoundPage';
import ListadoUsuarios from './ListadoUsuarios';
import CrearUsuario from './CrearUsuario';
import EditarUsuario from './EditarUsuario';

export default function() {
  return (
    <Router>
      <ListadoUsuarios path="/" />
      <CrearUsuario path="/nuevo" />
      <EditarUsuario path=":id" />
      
      <NotFoundPage default />
    </Router>
  )
}