import React from 'react'
import { Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import ProveedorForm from 'components/proveedor/ProveedorForm';
import { hasPermission } from 'auth';
import { useMutation } from 'api';
import { PERMISO } from '../../constants';

function CrearProveedor() {
  const [crearProveedor, { loading, error }] = useMutation('/proveedores', { method: 'post' });

  const handleSubmit = async values => {
    const { error } = await crearProveedor({ body: values });

    if (!error) {
      navigate('/admin/proveedores');
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'proveedores', title: 'Proveedores', to: '/admin/proveedores' },
        { key: 'actual', title: 'Nuevo Proveedor', to: '/admin/proveedores/nuevo', active: true },
      ]} />
      <Title>Nuevo Proveedor</Title>
      <Loader loading={loading} />

      <ProveedorForm
        error={error}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default hasPermission([PERMISO.ProveedoresModificar])(CrearProveedor);
