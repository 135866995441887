import React from 'react';
import moment from 'moment';
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import { formatRut, formatPrecio, formatDate } from '../../helpers';
import { styles, DataRow } from './pdfComponents';

Font.register({
  family: 'Roboto-Bold',
  src: require('typeface-roboto/files/roboto-latin-500.woff'),
  fontStyle: 'normal',
  fontWeight: 'bold'
});

const ColHeader = ['Nº','Proveedor','RUT','Fecha','Funcionario','Producto','Cantidad'];
const ColWidth = [3, 15, 10, 10, 12, 15, 7];
const ColValue = item => [
  item.id, item.proveedor, formatRut(item.rut), formatDate(item.fecha), item.funcionario
];

export default function ReporteProcesamientoDocument ({ procesamientos, proveedor, productoEntrada, fechaDesde, fechaHasta }) {
  return (
    <Document title={`Reporte Procesamiento`}>
      <Page size="A4" orientation="landscape">
        <View style={styles.page}>
          <View style={[styles.row, styles.divider]}>
            <View style={{width: '30%', flexGrow: 1}}>
              <Image src='/img/logo192.png' style={{width: 60, height: 60}} />
            </View>
            <View style={{width: '70%', flexGrow: 1}}>
              <Text style={styles.titleText}>Reporte Procesamiento</Text>
              <Text>{moment().format('DD/MM/YYYY HH:mm')}</Text>
            </View>
          </View>
          <View style={[styles.row, styles.divider, { marginTop: 20, marginBottom: 20, paddingBottom: 20 }]}>
            <View style={{width: '50%'}}>
              <DataRow title="Proveedor:" value={proveedor ? proveedor.razonSocial : 'Todos'} />
              <DataRow title="Producto:" value={productoEntrada ? productoEntrada.descripcion : 'Todos'} />
            </View>
            <View style={{width: '50%'}}>
              <DataRow title="Desde:" value={fechaDesde ? moment(fechaDesde).format('DD/MM/YYYY') : ''} />
              <DataRow title="Hasta:" value={fechaHasta ? moment(fechaHasta).format('DD/MM/YYYY') : ''} />
            </View>
          </View>
          <View style={[styles.row, { borderBottom: '1pt solid #888'}]}>
            {ColHeader.map((header, idx) => (
              <View key={idx} style={[styles.col, {width: `${ColWidth[idx]}%`}]}>
                <Text style={styles.bold}>{header}</Text>
              </View>
            ))}
          </View>
          {procesamientos.map(item => (
            <View style={[styles.row]} key={item.id} wrap={false}>
              {ColValue(item).map((value, idx) => (
                <View key={idx} style={[styles.col, {width: `${ColWidth[idx]}%`}]}>
                  <Text>{value}</Text>
                </View>
              ))}
              <View style={[styles.col, {width: `${ColWidth[ColWidth.length-4]}%`}]}>
                {item.productosEntrada.map(pd => (
                  <View key={pd.idProducto} style={styles.row}>
                    <Text style={styles.col}>{pd.producto}</Text>
                  </View>
                ))}
              </View>
              <View style={[styles.col, {width: `${ColWidth[ColWidth.length-3]}%`, textAlign: 'right'}]}>
                {item.productosEntrada.map(pd => (
                  <View key={pd.idProducto} style={styles.row}>
                    <Text style={styles.col}>
                      {pd.esMonto ? `$${formatPrecio(pd.cantidad)}` : pd.cantidad}
                    </Text>
                  </View>
                ))}
              </View>
              {/* <View style={[styles.col, {width: `${ColWidth[ColWidth.length-2]}%`}]}>
                {item.productosSalida.map(pd => (
                  <View key={pd.idProducto} style={styles.row}>
                    <Text style={styles.col}>{pd.producto}</Text>
                  </View>
                ))}
              </View>
              <View style={[styles.col, {width: `${ColWidth[ColWidth.length-1]}%`, textAlign: 'right'}]}>
                {item.productosSalida.map(pd => (
                  <View key={pd.idProducto} style={styles.row}>
                    <Text style={styles.col}>
                      {pd.esMonto ? `$${formatPrecio(pd.cantidad)}` : pd.cantidad}
                    </Text>
                  </View>
                ))}
              </View> */}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}
