import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

export function DataRow({ title, value }) {
  return (
    <View style={styles.fixedRow}>
      <View style={{width: '40%'}}>
        <Text style={styles.bold}>{title}</Text>
      </View>
      <View style={{flexGrow: 1}}>
        <Text>{value}</Text>
      </View>
    </View>
  )
}

export const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
    paddingTop: 40,
    paddingRight: 20,
    paddingBottom: 40,
    paddingLeft: 20,
    height: '100%'
  },
  row: {
    width: '100%',
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  fixedRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 5,
  },
  col6: {
    width: '45%',
    // padding: 5,
  },
  titleText: {
    fontSize: 20,
  },
  text: {
    fontSize: 12,
  },
  bold: {
    fontFamily: 'Roboto-Bold'
  },
  divider: {
    borderBottom: '2pt solid black'
  }
});
