import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { TableRow, TableCell, IconButton, Tooltip, Grid, Button } from '@material-ui/core';
import { Add as AddIcon, PictureAsPdf as PdfIcon, Comment, Edit } from '@material-ui/icons';
import { pdf } from '@react-pdf/renderer';
import { hasPermission, useAuth } from 'auth';
import { Fab, DataTable, Loader, CompactTableCell, Breadcrumbs, Title, ActionsButton, FilterGrid, Lookup, TextField, useTableSort, SortableTableCell } from 'components/ui';
import { useLazyQuery, useQuery } from 'api';
import { formatRut } from 'helpers/usuario';
import { formatDate, formatProductoItem } from 'helpers/format';
import { navigate } from '@reach/router';
import ComprobanteRecepcionPdf from 'components/pdf/ComprobanteRecepcionPdf';
import { PERMISO } from '../../constants';

function ListadoRecepciones() {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10 });
  const [order, onOrderChange] = useTableSort();
  
  const canEdit = auth.hasPermission([PERMISO.RecepcionesModificar]);

  const { data: recepciones, loading, refetch } = useQuery('/recepciones');
  const [buscarProveedores] = useLazyQuery('/proveedores');
  const [buscarProductos] = useLazyQuery('/productos');
  const [buscarOrigenes] = useLazyQuery('/origenes');
    
  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: getQuery(newState) });
  }

  const getQuery = (state) => {
    const query = {}
    if (state.fechaDesde) {
      query.fechaDesde = moment.utc(state.fechaDesde).startOf('day').toISOString();
    }
    if (state.fechaHasta) {
      query.fechaHasta = moment.utc(state.fechaHasta).endOf('day').toISOString();
    }
    if (state.proveedor) {
      query.idProveedor = state.proveedor.id;
    }
    if (state.origen) {
      query.idOrigen = state.origen.id;
    }
    if (state.producto) {
      query.idProducto = state.producto.id;
    }

    query.page = state.page;
    query.pageSize = state.pageSize;

    query.orderBy = order.field;
    query.orderCriteria = order.criteria;
    
    return query;
  }

  const handleFilter = (filterState) => {
    const query = getQuery(filterState);
    refetch({ query });
  }

  useEffect(() => {
    if (order.field) {
      handleFilter(state);
    }
  }, [order.field, order.criteria]);

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'listado', title: 'Recepciones', to: '/recepciones', active: true },
      ]} />
      <Title>Recepciones - Guía de Entrada</Title>
      <Loader loading={loading} />      

      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Lookup
            name="proveedor"
            label="Proveedor"
            size="small"
            fluid
            onChange={(e, val) => onChange({ proveedor: val, page: 1 })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? `${formatRut(option.rut)} - ${option.razonSocial}` : ''}
            fetcher={(busqueda) => buscarProveedores({ query: { busqueda } }).then(resp => resp.data.nodes)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Lookup
            name="origen"
            label="Origen"
            size="small"
            fluid
            onChange={(e, val) => onChange({ origen: val, page: 1 })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? option.nombre : ''}
            fetcher={() => buscarOrigenes().then(resp => resp.data)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Lookup
            name="producto"
            label="Producto"
            size="small"
            fluid
            onChange={(e, val) => onChange({ producto: val, page: 1 })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? option.descripcion : ''}
            fetcher={(busqueda) => buscarProductos({ query: { busqueda } }).then(resp => resp.data.nodes)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Desde"
            type="date"
            InputLabelProps={{shrink: true}}
            fluid
            onChange={(e) => onChange({ fechaDesde: e.target.value, page: 1 })}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Hasta"
            type="date"
            InputLabelProps={{shrink: true}}
            fluid
            onChange={(e) => onChange({ fechaHasta: e.target.value, page: 1 })}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button variant="contained" color="primary" size="small" onClick={() => handleFilter(state)}>Filtrar</Button>
        </Grid>
      </FilterGrid>

      <DataTable
        data={recepciones}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <RecepcionHeader order={order} onOrderChange={onOrderChange} />}
        renderRow={(recepcion) => (
          <RecepcionRow
            key={recepcion.id}
            recepcion={recepcion}
            canEdit={canEdit}
          />
        )}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate('/recepciones/nuevo')}
        >
          <AddIcon />
        </Fab>
      )}

    </div>
  );
}

function RecepcionHeader(props) {
  return (
    <>
      <TableRow>
        <SortableTableCell {...props} id="id" label="ID" />
        <TableCell>RUT</TableCell>
        <TableCell>Razón Social</TableCell>
        <TableCell>Tipo Documento</TableCell>
        <TableCell>Documento</TableCell>
        <TableCell>Productos</TableCell>
        <SortableTableCell {...props} id="fechaRecepcion" label="Fecha Ingreso" />
        <TableCell>Origen</TableCell>
        <TableCell>Nº Procesamiento</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>      
    </>
  )
}

function RecepcionRow({ recepcion, canEdit }) {
  const cantProds = Math.max(1, recepcion.productosDetalle.length);
  const pd0 = recepcion.productosDetalle[0];

  const generatePdfDocument = React.useCallback(async () => {
    const blob = await pdf(<ComprobanteRecepcionPdf recepcion={recepcion} />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
    // saveAs(blob, `recepcion-${recepcion.id}.pdf`);
  }, [recepcion]);
  
  return (
    <>
      <TableRow>
        <CompactTableCell rowSpan={cantProds}>{recepcion.id}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatRut(recepcion.proveedor.rut)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{recepcion.proveedor.razonSocial}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{recepcion.tipoDocumento.nombre}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{recepcion.documento}</CompactTableCell>
        <CompactTableCell>{formatProductoItem(pd0)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatDate(recepcion.fechaRecepcion)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{recepcion.origen ? recepcion.origen.nombre : ''}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{recepcion.idProcesamiento}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>
          {recepcion.observaciones && (
            <Tooltip title={recepcion.observaciones}>
              <Comment color='disabled' />
            </Tooltip>
          )}
        </CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>
          <IconButton aria-label="descargar pdf" onClick={generatePdfDocument}>
            <PdfIcon style={{color: "firebrick"}} />
          </IconButton>
        </CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>
          {canEdit && (
            <ActionsButton
              items={[
                { key: 'editar', text: 'Editar', icon: <Edit/>, onClick: () => navigate(`/recepciones/${recepcion.id}/editar`) },
              ]}
            />
          )}
        </CompactTableCell>
      </TableRow>
      {recepcion.productosDetalle.slice(1).map(pd => (
        <TableRow key={pd.idProducto}>
          <CompactTableCell>{formatProductoItem(pd)}</CompactTableCell>
        </TableRow>
      ))}
    </>
  )
}

export default hasPermission([PERMISO.RecepcionesVer])(ListadoRecepciones);
