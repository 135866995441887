import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MenuItem } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { Formik, Form, Fab, ApiErrorMessage } from 'components/ui';
import { useQuery } from 'api';
import { validateLength, validateRut } from 'helpers';

function UsuarioForm ({ usuario, error, onSubmit }) {
  const { data: rolesData } = useQuery('/roles', { query: { vigente: 1 } });
  const { data: departamentosData } = useQuery('/departamentos');
  const roles = (rolesData && rolesData.nodes) || [];
  const departamentos = departamentosData || [];

  const initialValues = {
    rut: usuario ? usuario.rut : '',
    clave: '',
    nombres: usuario ? usuario.nombres : '',
    apellidos: usuario ? usuario.apellidos : '',
    activo: usuario ? !!usuario.activo : true,
    idRol: usuario ? usuario.idRol : '',
    idDepartamento: usuario ? usuario.idDepartamento : '',
  };

  const handleSubmit = (input) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    onSubmit(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      {({ dirty, handleSubmit }) => (
        <Form alignItems="center">
          <Form.Field xs={12} md={3}>
            <Form.TextField required name="rut" label="RUT" fluid />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.PasswordField name="clave" label="Clave" fluid validate={validateLength(0, 30)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name="nombres" label="Nombres" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name="apellidos" label="Apellidos" fluid validate={validateLength(0, 100)} />
          </Form.Field>

          <Form.Field xs={12} md={4}>
            <Form.Select required name="idDepartamento" label="Departamento" align="left" fluid>
              {departamentos.map(d => <MenuItem key={d.id} value={d.id}>{d.nombre}</MenuItem>)}
            </Form.Select>
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.Select required name="idRol" label="Rol" align="left" fluid>
              {roles.map(r => <MenuItem key={r.id} value={r.id}>{r.nombre}</MenuItem>)}
            </Form.Select>
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.Checkbox name="activo" label="Activo" labelPlacement="top" />
          </Form.Field>

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.rut) {
    errors.rut = 'El RUT es obligatorio'
  } else if(!validateRut(values.rut)) {
    errors.rut = 'El RUT ingresado no es correcto';
  }
  if (!values.nombres) {
    errors.nombres = 'El nombre es obligatorio';
  }
  if (!values.apellidos) {
    errors.apellidos = 'El apellido es obligatorio';
  }
  if (!values.idRol) {
    errors.idRol = 'El rol es obligatorio';
  }
  if (!values.idDepartamento) {
    errors.idDepartamento = 'El departamento es obligatorio';
  }
  
  return errors;
}

UsuarioForm.propTypes = {
  usuario: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default UsuarioForm;