import React from 'react'
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import AyudaForm from 'components/ayuda/AyudaForm';
import { hasPermission } from 'auth';
import { useMutation } from 'api';
import { PERMISO } from '../../constants';

function IngresarAyuda() {
  const [ingresarAyuda, { loading, error }] = useMutation('/ayudas', { method: 'post' });

  const handleSubmit = async values => {
    const { error } = await ingresarAyuda({ body: values });

    if (!error) {
      navigate('/ayudas');
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'list', title: 'Ayudas Sociales', to: '/ayudas' },
        { key: 'actual', title: 'Nueva Ayuda', to: '/ayudas/nuevo', active: true },
      ]} />
      <Title>Nueva Ayuda Social</Title>
      <Loader loading={loading} />

      <AyudaForm
        error={error}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default hasPermission([PERMISO.AyudasModificar])(IngresarAyuda);
