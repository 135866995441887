import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Typography, Table, TableHead, TableCell, TableRow, TableBody, MenuItem, Grid } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useLazyQuery, useApi } from 'api';
import { useAuth } from 'auth';
import { Formik, Form, Fab, ApiErrorMessage, Loader, UploadButton } from 'components/ui';
import { formatRut, formatProducto, formatPrecio, formatBeneficiarioDetallado, validateLength, formatBeneficiario } from 'helpers';
import { FieldArray } from 'formik';
import AdjuntoCard from 'components/adjunto/AdjuntoCard';

function EntregaForm ({ error, onSubmit }) {
  const callApi = useApi();
  const { auth } = useAuth();
  const [buscarBeneficiarios] = useLazyQuery('/beneficiarios');
  const [buscarUsuarios] = useLazyQuery('/usuarios');
  
  const [idBeneficiario, setIdBeneficiario] = useState(null);
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [loadingProductos, setLoadingProductos] = useState(false);
  
  const cantProductosDisponibles = productosDisponibles.filter(pd => pd.disponibles > 0).length;

  const initialValues = {
    beneficiario: null,
    repartidor: null,
    estado: '1',
    fechaEntrega: '',
    observaciones: '',
    rutReceptor: '',
    nombreReceptor: '',
    numOrdenCompra: '',
    productosDetalle: {},
    adjuntos: [],
  };

  const handleFormSubmit = (input) => {
    const values = _.chain(input)
      .omit(['beneficiario', 'repartidor', 'productosDetalle'])
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    values.idBeneficiario = input.beneficiario.id;
    if (input.repartidor) {
      values.idUsuarioRepartidor = input.repartidor.id;
    } else {
      values.idUsuarioRepartidor = auth.user.id;
    }
    values.productosDetalle = Object.keys(input.productosDetalle)
      .filter(idProducto => input.productosDetalle[idProducto].cantidad > 0)
      .map(idProducto => ({
        idProducto: Number(idProducto),
        cantidad: input.productosDetalle[idProducto].cantidad,
      }));
    
    values.fechaEntrega = moment.utc().toISOString();

    onSubmit(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm.bind(null, productosDisponibles)}
    >
      {({ values, errors, setFieldValue, setFieldTouched, dirty, handleSubmit }) => (
        <Form alignItems="center">
          <Loader loading={loadingProductos} />
          
          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Datos Generales</Typography>
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.Lookup
              required
              name="beneficiario"
              label="Usuario"
              fluid
              onChange={(e, beneficiario) => {
                setIdBeneficiario(beneficiario ? beneficiario.id : null);
                setFieldValue('rutReceptor', beneficiario ? formatRut(beneficiario.rut) : '');
                setFieldValue('nombreReceptor', beneficiario ? formatBeneficiario(beneficiario) : '');
                setFieldValue('productosDetalle', {});
                if (beneficiario) {
                  setLoadingProductos(true);
                  callApi(`/beneficiarios/${beneficiario.id}/productosDisponibles`)
                  .then(({ data }) => {
                    setProductosDisponibles(data.filter(pd => pd.ayudas.every(ayuda => ayuda.estado === 1)));
                    setLoadingProductos(false);
                  });
                } else {
                  setProductosDisponibles([]);
                }
              }}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option ? formatBeneficiarioDetallado(option) : ''}
              fetcher={(busqueda) => buscarBeneficiarios({ query: { busqueda } }).then(resp => resp.data.nodes)}
            />
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.Lookup
              name="repartidor"
              label="Funcionario"
              fluid
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option ? `${formatRut(option.rut)} - ${option.nombres} ${option.apellidos}` : ''}
              fetcher={(busqueda) => buscarUsuarios({ query: { busqueda } }).then(resp => resp.data.nodes)}
            />
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.Select required name="estado" label="Estado" fluid align="left">
              <MenuItem value="0">Pendiente de entrega</MenuItem>
              <MenuItem value="1">Entregado</MenuItem>
              <MenuItem value="2">No se pudo entregar</MenuItem>
            </Form.Select>
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name="rutReceptor" label="RUT Receptor" fluid />
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.TextField required name="nombreReceptor" label="Nombre Receptor" fluid validate={validateLength(0, 200)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField name="numOrdenCompra" label="# Orden Compra" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Productos</Typography>
          </Form.Field>

          {productosDisponibles.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Producto</TableCell>
                  <TableCell>Cant. Habilitada</TableCell>
                  {/* <TableCell>Stock Disponible</TableCell> */}
                  <TableCell>Cant. a Entregar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productosDisponibles.map((pd, index) => (
                  <TableRow key={`${idBeneficiario}-${pd.producto.id}`}>
                    <TableCell>{formatProducto(pd.producto)}</TableCell>
                    <TableCell>{pd.producto.tipo.esMonto ? `$${formatPrecio(pd.disponibles)}` : pd.disponibles}</TableCell>
                    {/* <TableCell>{pd.producto.tipo.esMonto ? `$${formatPrecio(pd.producto.stock)}` : pd.producto.stock}</TableCell> */}
                    <TableCell>
                      <Form.TextField
                        required
                        name={`productosDetalle.${pd.producto.id}.cantidad`}
                        label="Cantidad"
                        type="number"
                        disabled={pd.disponibles === 0}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {cantProductosDisponibles === 0 && !loadingProductos && (
            <Form.Field xs={12}>
              {!!idBeneficiario ? (
                <Alert variant="filled" severity="warning">
                  El usuario seleccionado no posee ayudas disponibles en este momento
                </Alert>
              ) : (
                <Typography variant="subtitle2">Seleccione un usuario para visualizar los productos disponibles</Typography>
              )}
              {/* <Form.ErrorMessage name="productosDetalle" /> */}
            </Form.Field>
          )}

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Documentos</Typography>

            <FieldArray name="adjuntos" render={arrayHelpers => (
              <>
                <Grid container spacing={3}>
                  {values.adjuntos.map((adj, index) => (
                    <Grid item xs={3} align="center" key={adj.id}>
                      <AdjuntoCard
                        adjunto={adj}
                        onDelete={() => arrayHelpers.remove(index)}
                      />
                    </Grid>
                  ))}
                  {values.adjuntos.length === 0 ? <Grid item xs={12} align="center">No hay documentos asociados</Grid> : null}
                  <Grid item xs={12} align="center">
                    <UploadButton onUpload={adjunto => arrayHelpers.push(adjunto)} />
                  </Grid>
                </Grid>
              </>
            )} />

          </Form.Field>

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Observaciones</Typography>
          </Form.Field>
          <Form.Field xs={12}>
            <Form.TextArea name="observaciones" placeholder="Ingrese un texto..." fluid />
          </Form.Field>

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty || productosDisponibles.length === 0 || _.isEmpty(values.productosDetalle)}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(productosDisponibles, values) {
  const errors = {};

  if (!values.beneficiario) {
    errors.beneficiario = 'El usuario es obligatorio'
  }
  if (!values.rutReceptor) {
    errors.rutReceptor = 'El RUT del receptor es obligatorio'
  }
  if (!values.nombreReceptor) {
    errors.nombreReceptor = 'El nombre del receptor es obligatorio'
  }
  
  if (_.isEmpty(_.pickBy(values.productosDetalle, pd => pd.cantidad > 0))) {
    errors.productosDetalle = 'Debe ingresar al menos un producto'
  } else {
    errors.productosDetalle = {};
    productosDisponibles.forEach(pd => {
      const idProducto = pd.producto.id;
      const { esMonto } = pd.producto.tipo;
      
      if (values.productosDetalle[idProducto]) {
        const detalle = values.productosDetalle[idProducto];
        const pdErrors = {};
        
        let cantidad = Number(detalle.cantidad);
        if (_.isNaN(cantidad) || cantidad < 0) {
          pdErrors.cantidad = 'La cantidad ingresada no es válida';
        } else {
          // let stock = Number(pd.producto.stock);
          let disponibles = Number(pd.disponibles);
          if (esMonto && cantidad !== disponibles) {
            pdErrors.cantidad = 'No puede entregar un monto diferente al habilitado';
          // } else if (cantidad > stock) {
          //   pdErrors.cantidad = 'Excede el stock';
          } else if (cantidad > disponibles) {
            pdErrors.cantidad = 'Excede la cantidad habilitada';
          }
        }

        if (!_.isEmpty(pdErrors)) {
          errors.productosDetalle[idProducto] = pdErrors;
        }
      }
    });

    if (_.isEmpty(errors.productosDetalle)) {
      delete errors.productosDetalle;
    }
  }
  
  return errors;
}

EntregaForm.propTypes = {
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default EntregaForm;