import React from 'react';
import { Router } from '@reach/router';

import ListadoBeneficiarios from './ListadoBeneficiarios';
import CrearBeneficiario from './CrearBeneficiario';
import EditarBeneficiario from './EditarBeneficiario';
import NotFoundPage from '../NotFoundPage';

export default function() {
  return (
    <Router>
      <ListadoBeneficiarios path="/" />
      <CrearBeneficiario path="/nuevo" />
      <EditarBeneficiario path="/:id" />
      <NotFoundPage default />
    </Router>
  )
}