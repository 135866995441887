import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MenuItem } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { Formik, Form, Fab, ApiErrorMessage } from 'components/ui';
import { useQuery } from 'api';
import { validateLength } from 'helpers';

function ProductoForm ({ producto, error, onSubmit }) {
  const { data: tiposProductosData } = useQuery('/tipos-productos')
  const tiposProductos = tiposProductosData || [];

  const initialValues = {
    idTipo: producto ? producto.idTipo : '',
    descripcion: producto ? producto.descripcion : '',
    detalle: producto ? producto.detalle : '',
    entregaDirecta: producto ? !!producto.entregaDirecta : true,
    requiereAprobacion: producto ? !!producto.requiereAprobacion : true,
  };

  const handleSubmit = (input) => {
    const values = _.chain(input)
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    values.entregaDirecta = values.entregaDirecta ? 1 : 0
    onSubmit(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateForm}
    >
      {({ dirty, handleSubmit }) => (
        <Form alignItems="left">

          <Form.Field xs={12}>
            <Form.Select required name="idTipo" label="Tipo" align="left">
              {tiposProductos.map(tp => <MenuItem key={tp.id} value={tp.id}>{tp.nombre}</MenuItem>)}
            </Form.Select>
          </Form.Field>
          <Form.Field xs={12}>
            <Form.TextField required name="descripcion" label="Descripción" validate={validateLength(0, 300)} />
          </Form.Field>
          <Form.Field xs={12}>
            <Form.TextField name="detalle" label="Detalle" validate={validateLength(0, 300)} />
          </Form.Field>
          <Form.Field xs={6}>
            <Form.Checkbox name="entregaDirecta" label="Entrega Directa" labelPlacement="bottom" />
          </Form.Field>
          <Form.Field xs={6}>
            <Form.Checkbox name="requiereAprobacion" label="Requiere Aprobación Tesorería" labelPlacement="bottom" />
          </Form.Field>

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.idTipo) {
    errors.idTipo = 'El tipo de producto es obligatorio'
  }
  if (!values.descripcion) {
    errors.descripcion = 'La descripción es obligatoria';
  }
  
  return errors;
}

ProductoForm.propTypes = {
  producto: PropTypes.object,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default ProductoForm;