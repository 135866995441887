import React, { useState } from 'react';
import { TableRow, TableCell, Grid } from '@material-ui/core';
import { Add as AddIcon, Edit, Check, Clear } from '@material-ui/icons';
import { hasPermission, useAuth } from 'auth';
import { Fab, DataTable, Loader, CompactTableCell, ActionsButton, Breadcrumbs, Title, FilterGrid, TextField, useDelayedSearch } from 'components/ui';
import { useQuery } from 'api';
import { formatDate } from 'helpers/format';
import { navigate } from '@reach/router';
import { PERMISO } from '../../constants';

function ListadoProductos() {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10, busqueda: '' });
  
  const canEdit = auth.hasPermission([PERMISO.ProductosModificar]);

  const { data: productos, loading, refetch } = useQuery('/productos');
  
  const search = useDelayedSearch(newState => refetch({ query: newState }));
    
  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  const onChangeBusqueda = (e) => {
    const newState = { ...state, busqueda: e.target.value, page: 1 };
    setState(newState);
    search(newState);
  }
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'productos', title: 'Productos', to: '/admin/productos', active: true },
      ]} />
      <Title>Productos</Title>
      <Loader loading={loading} />

      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            fluid
            name='busqueda'
            label='Buscar'
            value={state.busqueda}
            onChange={onChangeBusqueda}
          />
        </Grid>
      </FilterGrid>
      
      <DataTable
        data={productos}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <ProductoHeader />}
        renderRow={(producto) => (
          <ProductoRow
            key={producto.id}
            producto={producto}
            canEdit={canEdit}
          />
        )}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate('/admin/productos/nuevo')}
        >
          <AddIcon />
        </Fab>
      )}

    </div>
  );
}

function ProductoHeader() {
  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>Tipo</TableCell>
      <TableCell>Descripción</TableCell>
      <TableCell>Detalle</TableCell>
      <TableCell>Entrega Directa</TableCell>
      <TableCell>Requiere Aprobación</TableCell>
      <TableCell>Fecha Ingreso</TableCell>
      <TableCell>Fecha Modificación</TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

function ProductoRow({ producto, canEdit }) {
  return (
    <TableRow>
      <CompactTableCell>{producto.id}</CompactTableCell>
      <CompactTableCell>{producto.tipo.nombre}</CompactTableCell>
      <CompactTableCell>{producto.descripcion}</CompactTableCell>
      <CompactTableCell>{producto.detalle}</CompactTableCell>
      <CompactTableCell>{!!producto.entregaDirecta ? <Check color='primary'/> : <Clear color='error' />}</CompactTableCell>
      <CompactTableCell>{!!producto.requiereAprobacion ? <Check color='primary'/> : <Clear color='error' />}</CompactTableCell>
      <CompactTableCell>{formatDate(producto.fechaIngreso)}</CompactTableCell>
      <CompactTableCell>{formatDate(producto.fechaModificacion)}</CompactTableCell>
      <CompactTableCell>
        {canEdit && (
          <ActionsButton
            items={[
              { key: 'editar', text: 'Editar', icon: <Edit/>, onClick: () => navigate(`/admin/productos/${producto.id}`) }
            ]}
          />
        )}
      </CompactTableCell>
    </TableRow>
  )
}

export default hasPermission([PERMISO.ProductosVer])(ListadoProductos);
