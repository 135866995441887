import React, { useEffect, useState } from 'react';
import { omitBy, isNil } from 'lodash';
import { Tabs, Tab, Grid } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { navigate } from '@reach/router';
import { hasPermission, useAuth } from 'auth';
import { Fab, DataTable, Loader, Breadcrumbs, Title, FilterGrid, Lookup } from 'components/ui';
import { EntregaPendienteHeader, EntregaPendienteRow, EntregaRealizadaHeader, EntregaRealizadaRow } from 'components/entrega/EntregaTable'
import { useLazyQuery, useQuery } from 'api';
import { PERMISO } from '../../constants';
import { formatBeneficiarioDetallado } from '../../helpers';

function ListadoEntregas() {
  const auth = useAuth();
  const [tab, setTab] = useState(0);
  const [idBeneficiario, setIdBeneficiario] = useState(null);
  
  const canEdit = auth.hasPermission([PERMISO.EntregasIngresar]);

  const queryParamsPendientes = { pendientes: 1 };
  const queryParamsRealizadas = { pendientes: 0 };

  const entregasPendientes = useQuery('/entregas', { query: queryParamsPendientes });
  const entregasRealizadas = useQuery('/entregas', { query: queryParamsRealizadas });
  const [buscarBeneficiarios] = useLazyQuery('/beneficiarios');

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'listado', title: 'Entregas', to: '/entregas', active: true },
      ]} />
      <Title>Entregas - Guía de Salida</Title>
      <Loader loading={entregasPendientes.loading || entregasRealizadas.loading} />

      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Lookup
            name="beneficiario"
            label="Usuario"
            size="small"
            fluid
            onChange={(e, val) => setIdBeneficiario(val ? val.id : null)}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? formatBeneficiarioDetallado(option) : ''}
            fetcher={(busqueda) => buscarBeneficiarios({ query: { busqueda } }).then(resp => resp.data.nodes)}
          />
        </Grid>
      </FilterGrid>

      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, val) => setTab(val)}
      >
        <Tab value={0} label="Entregas Realizadas" />
        <Tab value={1} label="Entregas Sin Realizar" />
      </Tabs>

      <TabEntrega
        query={entregasRealizadas}
        queryParams={queryParamsRealizadas}
        idBeneficiario={idBeneficiario}
        style={{ display : (tab === 0) ? 'block' : 'none' }}
        renderHeader={() => <EntregaRealizadaHeader />}
        renderRow={(entrega) => (
          <EntregaRealizadaRow
            key={entrega.id}
            entrega={entrega}
            canEdit={canEdit}
          />
        )}
      />
      <TabEntrega
        query={entregasPendientes}
        queryParams={queryParamsPendientes}
        idBeneficiario={idBeneficiario}
        style={{ display : (tab === 1) ? 'block' : 'none' }}
        renderHeader={() => <EntregaPendienteHeader />}
        renderRow={(entrega) => (
          <EntregaPendienteRow
            key={entrega.id}
            entrega={entrega}
            canEdit={canEdit}
          />
        )}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate('/entregas/nuevo')}
        >
          <AddIcon />
        </Fab>
      )}

    </div>
  );
}

function TabEntrega({ query, queryParams, idBeneficiario, renderHeader, renderRow, style }) {
  const [state, setState] = useState({ page: 1, pageSize: 10 });
 
  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
  }

  useEffect(() => {
    const params = omitBy({ ...queryParams, ...state, idBeneficiario }, isNil);
    query.refetch({ query: params });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, idBeneficiario]);

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });

  return (
    <div style={style}>
      <DataTable
        data={query.data}
        loading={query.loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={renderHeader}
        renderRow={renderRow}
      />
    </div>
  )
}


export default hasPermission([PERMISO.EntregasVer])(ListadoEntregas);
