import React from 'react'
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import RecepcionForm from 'components/recepcion/RecepcionForm';
import { hasPermission } from 'auth';
import { useMutation } from 'api';
import { PERMISO } from '../../constants';

function IngresarRecepcion() {
  const [ingresarRecepcion, { loading, error }] = useMutation('/recepciones', { method: 'post' });

  const handleSubmit = async values => {
    const { error } = await ingresarRecepcion({ body: values });

    if (!error) {
      navigate('/recepciones');
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'list', title: 'Recepciones', to: '/recepciones' },
        { key: 'actual', title: 'Nueva Recepción', to: '/recepciones/nuevo', active: true },
      ]} />
      <Title>Nueva Recepción - Guía de Entrada</Title>
      <Loader loading={loading} />

      <RecepcionForm
        error={error}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default hasPermission([PERMISO.RecepcionesModificar])(IngresarRecepcion);
