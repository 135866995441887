import React from 'react';
import { Router } from '@reach/router';

import NotFoundPage from '../NotFoundPage';
import ListadoTipoProducto from './ListadoTipoProducto';
import CrearTipoProducto from './CrearTipoProducto';
import EditarTipoProducto from './EditarTipoProducto';

export default function() {
  return (
    <Router>
      <ListadoTipoProducto path="/" />
      <CrearTipoProducto path="/nuevo" />
      <EditarTipoProducto path="/:id" />
      
      <NotFoundPage default />
    </Router>
  )
}