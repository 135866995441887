import React, { useState } from 'react';
import _ from 'lodash';
import { Typography,Grid, TableRow, TableCell } from '@material-ui/core';
import { Add as AddIcon, Edit } from '@material-ui/icons';
import { hasPermission, useAuth } from 'auth';
import { Fab, DataTable, Loader, FilterGrid, TextField, CompactTableCell, ActionsButton, Breadcrumbs, Title, useDelayedSearch } from 'components/ui';
import { useQuery } from 'api';
import { formatRut } from 'helpers/usuario';
import { formatDate } from 'helpers/format';
import { navigate } from '@reach/router';
import { PERMISO } from '../../constants';

function ListadoProveedores() {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10, busqueda: '' });
  
  const canEdit = auth.hasPermission([PERMISO.ProveedoresModificar]);

  const { data: proveedores, loading, refetch } = useQuery('/proveedores');

  const search = useDelayedSearch(newState => refetch({ query: newState }));

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  const onChangeBusqueda = (e) => {
    const newState = { ...state, busqueda: e.target.value, page: 1 };
    setState(newState);
    search(newState);
  }
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'proveedores', title: 'Proveedores', to: '/admin/proveedores', active: true },
      ]} />
      <Title>Proveedores</Title>
      <Loader loading={loading} />
      
      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={6} lg={3}>
          <TextField
            fluid
            name='busqueda'
            label='Buscar'
            value={state.busqueda}
            onChange={onChangeBusqueda}
          />
        </Grid>
      </FilterGrid>

      <DataTable
        data={proveedores}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <ProveedorHeader />}
        renderRow={(proveedor) => (
          <ProveedorRow
            key={proveedor.id}
            proveedor={proveedor}
            canEdit={canEdit}
          />
        )}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate('/admin/proveedores/nuevo')}
        >
          <AddIcon />
        </Fab>
      )}

    </div>
  );
}

function ProveedorHeader() {
  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>RUT</TableCell>
      <TableCell>Razón Social</TableCell>
      <TableCell>Dirección</TableCell>
      <TableCell>Tel 1</TableCell>
      <TableCell>Tel 2</TableCell>
      <TableCell>Contacto</TableCell>
      <TableCell>Email</TableCell>
      <TableCell>Fecha Ingreso</TableCell>
      <TableCell>Fecha Modificación</TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

function ProveedorRow({ proveedor, canEdit }) {
  return (
    <TableRow>
      <CompactTableCell>{proveedor.id}</CompactTableCell>
      <CompactTableCell>{formatRut(proveedor.rut)}</CompactTableCell>
      <CompactTableCell>{proveedor.razonSocial}</CompactTableCell>
      <CompactTableCell>{proveedor.direccion}</CompactTableCell>
      <CompactTableCell>{proveedor.telefono1}</CompactTableCell>
      <CompactTableCell>{proveedor.telefono2}</CompactTableCell>
      <CompactTableCell>{proveedor.personaContacto}</CompactTableCell>
      <CompactTableCell>{proveedor.email}</CompactTableCell>
      <CompactTableCell>{formatDate(proveedor.fechaIngreso)}</CompactTableCell>
      <CompactTableCell>{formatDate(proveedor.fechaModificacion)}</CompactTableCell>
      <CompactTableCell>
        {canEdit && (
          <ActionsButton
            items={[
              { key: 'editar', text: 'Editar', icon: <Edit/>, onClick: () => navigate(`/admin/proveedores/${proveedor.id}`) }
            ]}
          />
        )}
      </CompactTableCell>
    </TableRow>
  )
}

export default hasPermission([PERMISO.ProveedoresVer])(ListadoProveedores);
