import React, { useState } from 'react';
import { Grid, TableRow, TableCell, Button, MenuItem } from '@material-ui/core';
import { GetApp as DownloadIcon, PictureAsPdf } from '@material-ui/icons';
import { pdf } from '@react-pdf/renderer';
import { omit } from 'lodash';
import moment from 'moment';
import { hasPermission } from 'auth';
import { DataTable, Loader, CompactTableCell, TextField, Select, Breadcrumbs, Title, FilterGrid, Lookup, DownloadButton } from 'components/ui';
import { useQuery, useLazyQuery } from 'api';
import { formatRut } from 'helpers/usuario';
import { formatDate, formatPrecio } from 'helpers/format';
import { PERMISO } from '../../constants';
import { getEstadoAyuda } from 'helpers';
import ReporteAyudaDocument from 'components/pdf/ReporteAyudaDocument';

function ReporteAyudas() {
  const [state, setState] = useState({ page: 1, pageSize: 25 });
  
  const { data: items, loading, refetch } = useQuery('/reportes/ayudas', { query: state });
  const [descargarReporte, { loading: downloading }] = useLazyQuery('/reportes/ayudas');
  const [buscarProductos] = useLazyQuery('/productos');

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    handleFilter(newState);
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
    
  const getQuery = (state) => {
    const query = {}
    if (state.fechaDesde) {
      query.fechaDesde = moment.utc(state.fechaDesde).startOf('day').toISOString();
    }
    if (state.fechaHasta) {
      query.fechaHasta = moment.utc(state.fechaHasta).endOf('day').toISOString();
    }
    if (state.rut) {
      query.rut = state.rut;
    }
    if (state.nombre) {
      query.nombre = state.nombre;
    }
    if (state.producto) {
      query.idProducto = state.producto.id;
    }
    if (state.entregadas) {
      query.entregadas = state.entregadas;
    }

    query.page = state.page;
    query.pageSize = state.pageSize;
    
    return query;
  }

  const handleFilter = (filterState) => {
    const query = getQuery(filterState);
    refetch({ query });
  }

  const handleDownloadExcel = async () => {
    const query = omit(getQuery(state), ['page', 'pageSize']);
    const content = await descargarReporte({ type: 'blob', query: { ...query, format: 'xls' } });
    const filename = `reporte-ayudas-${moment().format('YYYY-MM-DD-HH-mm')}.xlsx`;
    return { filename, content };
  }

  const handleDownloadCSV = async () => {
    const query = omit(getQuery(state), ['page', 'pageSize']);
    const content = await descargarReporte({ type: 'blob', query: { ...query, format: 'csv' } });
    const filename = `reporte-ayudas-${moment().format('YYYY-MM-DD-HH-mm')}.csv`;
    return { filename, content };
  }

  const generatePdfDocument = React.useCallback(async () => {
    const blob = await pdf((
      <ReporteAyudaDocument
        {...state}
        ayudas={items.nodes}
      />
    )).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
    // saveAs(blob, `reporte-ayudas-${moment().format('YYYY-MM-DD-HH-mm')}.pdf`);
  }, [items, state]);

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'actual', title: 'Reporte Ayudas', to: '/reportes/ayudas', active: true },
      ]} />
      <Title>Reporte Ayudas</Title>
      <Loader loading={loading || downloading} />
      
      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Lookup
            name="producto"
            label="Producto"
            size="small"
            fluid
            onChange={(e, val) => setState({ ...state, producto: val })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? option.descripcion : ''}
            fetcher={(busqueda) => buscarProductos({ query: { busqueda } }).then(resp => resp.data.nodes)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="RUT"
            fluid
            onChange={(e) => setState({ ...state, rut: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Nombre"
            fluid
            onChange={(e) => setState({ ...state, nombre: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Select
            label="Estado Entrega"
            fluid
            onChange={(e) => setState({ ...state, entregadas: e.target.value })}
          >
            <MenuItem value="1">Entregadas</MenuItem>
            <MenuItem value="0">Sin Entregar</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Desde"
            type="date"
            InputLabelProps={{shrink: true}}
            fluid
            onChange={(e) => setState({ ...state, fechaDesde: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Hasta"
            type="date"
            InputLabelProps={{shrink: true}}
            fluid
            onChange={(e) => setState({ ...state, fechaHasta: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button variant="contained" color="primary" size="small" onClick={() => handleFilter(state)}>Filtrar</Button>
        </Grid>
        <Grid item xs={12} align="right">
          <DownloadButton
            variant="contained"
            color="primary"
            size="small"
            type='text/csv;charset=utf-8'
            fetchFile={handleDownloadCSV}
            startIcon={<DownloadIcon />}
            style={{marginRight: 10}}
          >
            Descargar CSV
          </DownloadButton>
          <DownloadButton
            variant="contained"
            color="primary"
            size="small"
            type='vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
            fetchFile={handleDownloadExcel}
            startIcon={<DownloadIcon />}
            style={{marginRight: 10}}
          >
            Descargar Excel
          </DownloadButton>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<PictureAsPdf />}
            disabled={!items || items.count === 0}
            onClick={generatePdfDocument}
          >
            Generar PDF
          </Button>
        </Grid>
      </FilterGrid>

      <DataTable
        data={items}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <ReporteHeader />}
        renderRow={(item) => (
          <ReporteRow
            key={item.id}
            item={item}
          />
        )}
      />
    </div>
  );
}

function ReporteHeader() {
  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>RUT</TableCell>
      <TableCell>Nombres</TableCell>
      <TableCell>Apellidos</TableCell>
      <TableCell>Decreto Nº</TableCell>
      <TableCell>Fecha Creación</TableCell>
      <TableCell>Meses Ayuda</TableCell>
      <TableCell>Estado</TableCell>
      <TableCell>Producto</TableCell>
      <TableCell>Cantidad</TableCell>
    </TableRow>
  )
}

function ReporteRow({ item }) {
  const nrows = Math.max(1, item.productos.length);
  const prod0 = item.productos[0];

  return (
    <>
      <TableRow>
        <CompactTableCell rowSpan={nrows}>{item.id}</CompactTableCell>
        <CompactTableCell rowSpan={nrows}>{formatRut(item.rut)}</CompactTableCell>
        <CompactTableCell rowSpan={nrows}>{item.nombres}</CompactTableCell>
        <CompactTableCell rowSpan={nrows}>{item.apellidos}</CompactTableCell>
        <CompactTableCell rowSpan={nrows}>{item.decreto}</CompactTableCell>
        <CompactTableCell rowSpan={nrows}>{formatDate(item.fechaIngreso)}</CompactTableCell>
        <CompactTableCell rowSpan={nrows}>{item.cantidadMeses}</CompactTableCell>
        <CompactTableCell rowSpan={nrows}>{getEstadoAyuda(item.estado)}</CompactTableCell>        
        <CompactTableCell>{prod0?.producto}</CompactTableCell>
        <CompactTableCell align="right">{prod0?.esMonto ? `$${formatPrecio(prod0?.cantidad)}` : prod0?.cantidad}</CompactTableCell>
      </TableRow>
      {item.productos.slice(1).map(prod => (
        <TableRow key={prod.idProducto}>
          <CompactTableCell>{prod.producto}</CompactTableCell>
          <CompactTableCell align="right">
            {prod.esMonto ? `$${formatPrecio(prod.cantidad)}` : prod.cantidad}
          </CompactTableCell>
        </TableRow>
      ))}
    </>
  )
}

export default hasPermission([PERMISO.ReportesBeneficiarios])(ReporteAyudas);
