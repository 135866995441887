import React from 'react';
import PropTypes from 'prop-types';
import { Location } from '@reach/router';
import { Hidden, Drawer, makeStyles } from '@material-ui/core';
import { Build, PieChart, Assessment, Input, HowToReg, LocalShipping, Search, AccountTree, AccountBalance, CloudUpload } from '@material-ui/icons';
import { AuthContext, hasPermissionFn } from 'auth';
import Menu from './SidebarMenu';

import './Sidebar.css';
import { PERMISO } from '../../constants';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    marginTop: theme.spacing(2),
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  toolbar: {
    minHeight: theme.spacing(8),
  },
}));

function Sidebar({ open, toggleDrawer }) {
  const { user } = React.useContext(AuthContext);
  const classes = useStyles();
  
  let items = [
    { key: 'dashboard', text: 'Estadísticas', icon: <PieChart />, to: '/' },
    { key: 'mantencion', text: 'Mantención', icon: <Build/>, items: [
      { key: 'departamentos', text: 'Departamentos', to: '/admin/departamentos', perms: [PERMISO.DepartamentosVer] },
      { key: 'usuarios', text: 'Funcionarios', to: '/admin/funcionarios', perms: [PERMISO.UsuariosVer] },
      { key: 'origenes', text: 'Origenes', to: '/admin/origenes', perms: [PERMISO.OrigenesVer] },
      { key: 'productos', text: 'Productos', to: '/admin/productos', perms: [PERMISO.ProductosVer] },
      { key: 'proveedores', text: 'Proveedores', to: '/admin/proveedores', perms: [PERMISO.ProveedoresVer] },
      { key: 'roles', text: 'Roles', to: '/admin/roles', perms: [PERMISO.RolesVer] },
      { key: 'tipos-documentos', text: 'Tipos de Documentos', to: '/admin/tipos-documentos', perms: [PERMISO.TiposDocumentosVer] },
      { key: 'tipos-pagos', text: 'Tipos de Pagos', to: '/admin/tipos-pagos', perms: [PERMISO.TiposPagosVer] },
      { key: 'tipos-productos', text: 'Tipos de Productos', to: '/admin/tipos-productos', perms: [PERMISO.TiposProductosVer] },
      { key: 'beneficiarios', text: 'Usuarios', to: '/admin/usuarios', perms: [PERMISO.BeneficiariosVer] },
    ]},
    { key: 'recepcion', text: 'Recepción de productos', icon: <Input/>, to: '/recepciones', perms: [PERMISO.RecepcionesVer] },
    { key: 'procesamiento', text: 'Procesamiento de productos', icon: <AccountTree/>, to: '/procesamientos', perms: [PERMISO.ProcesamientosVer] },
    { key: 'ayudas', text: 'Ayuda Social', icon: <HowToReg/>, to: '/ayudas', perms: [PERMISO.AyudasVer] },
    { key: 'tesoreria', text: 'Tesorería', icon: <AccountBalance/>, to: '/tesoreria', perms: [PERMISO.AyudasAprobar] },
    { key: 'entregas', text: 'Entregas', icon: <LocalShipping/>, to: '/entregas', perms: [PERMISO.EntregasVer] },
    { key: 'consulta-ayudas', text: 'Consulta Ayuda Disponible', icon: <Search/>, to: '/consulta-ayudas', perms: [PERMISO.ConsultaAyudas] },
    { key: 'reportes', text: 'Reportes', icon: <Assessment/>, items: [
      { key: 'reporte-usuarios', text: 'Funcionarios', to: '/reportes/funcionarios', perms: [PERMISO.ReportesUsuarios] },
      { key: 'reporte-entrada', text: 'Guías de entrada', to: '/reportes/entrada', perms: [PERMISO.ReportesEntrada] },
      { key: 'reporte-salida', text: 'Guías de salida', to: '/reportes/salida', perms: [PERMISO.ReportesSalida] },
      { key: 'reporte-ayudas', text: 'Ayudas', to: '/reportes/ayudas', perms: [PERMISO.ReportesBeneficiarios] },
      { key: 'reporte-inventario', text: 'Inventario', to: '/reportes/inventario', perms: [PERMISO.ReportesInventario] },
      { key: 'reporte-procesamiento', text: 'Procesamiento', to: '/reportes/procesamiento', perms: [PERMISO.ReportesProcesamiento] },
      { key: 'reporte-beneficiarios', text: 'Usuarios', to: '/reportes/usuarios', perms: [PERMISO.ReportesBeneficiarios] },
    ]},
    { key: 'carga-masiva', text: 'Carga Masiva', icon: <CloudUpload/>, to: '/carga-masiva', perms: [PERMISO.CargaMasiva] },
  ];
    
  items = items.map((item) => {
    if (item.perms && !hasPermissionFn(user, item.perms)) {
      return null;
    }
    if (item.items) {
      item.items = item.items.filter(subitem => !subitem.perms || hasPermissionFn(user, subitem.perms));
      if (item.items.length === 0) {
        return null;
      }
    }
    return item;
  }).filter(item => item !== null);

  const drawer = (
    <div>
      <Location>
        {({ location }) => (
          <React.Fragment>
            <Menu items={items} location={location} />
          </React.Fragment>
        )}
      </Location>
    </div>
  );

  return (
    <React.Fragment>
      <Hidden smUp implementation="css">
        <Drawer
          // container={container}
          variant="temporary"
          anchor="left"
          open={open}
          onClose={toggleDrawer}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          <div className={classes.toolbar}></div>
          {drawer}
        </Drawer>
      </Hidden>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func,
}

export default Sidebar;