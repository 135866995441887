import React from 'react';
import moment from 'moment';
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import { formatRut, formatBeneficiario, formatDireccion, formatPrecio } from '../../helpers';
import { styles, DataRow } from './pdfComponents';

Font.register({
  family: 'Roboto-Bold',
  src: require('typeface-roboto/files/roboto-latin-500.woff'),
  fontStyle: 'normal',
  fontWeight: 'bold'
});

export default function ComprobanteAyudaPdf ({ ayuda }) {  
  if (ayuda.estado !== 1)
    return null;
  
  const esAyudaEconomica = ayuda.productosDetalle.some(pd => pd.producto.tipo.esMonto && pd.producto.requiereAprobacion);

  return (
    <Document title={`Comprobante de ayuda social #${ayuda.id}`}>
      <Page size="A4">
        <View style={styles.page}>
          <View style={[styles.row, styles.divider]}>
            <View style={{width: '30%', flexGrow: 1}}>
              <Image src='/img/logo192.png' style={{width: 60, height: 60}} />
            </View>
            <View style={{width: '70%', flexGrow: 1}}>
              <Text style={styles.titleText}>Comprobante de Ayuda Social</Text>
            </View>
          </View>
          <View style={[styles.fixedRow, styles.divider, { marginTop: 20, marginBottom: 20, paddingBottom: 20 }]}>
            <View style={styles.col6}>
              <DataRow title="Ayuda #" value={ayuda.id} />
              <DataRow title="Fecha" value={moment(ayuda.fechaIngreso).format('DD/MM/YYYY HH:mm')} />
              <DataRow title="RUT" value={formatRut(ayuda.beneficiario.rut)} />
              <DataRow title="Nombre Completo" value={formatBeneficiario(ayuda.beneficiario)} />
              <DataRow title="Dirección" value={formatDireccion(ayuda.beneficiario.direccion)} />
              <DataRow title="Decreto" value={ayuda.decreto} />
              <DataRow title="Meses Ayuda" value={ayuda.cantidadMeses} />
            </View>
            {esAyudaEconomica && (
              <View style={styles.col6}>
                <DataRow title="Tipo de Documento" value={ayuda.tipoPago ? ayuda.tipoPago.nombre : ''} />
                <DataRow title="Nº de Documento" value={ayuda.documentoPago} />
                <DataRow title="Destinatario" value={ayuda.destinatarioPago} />
              </View>
            )}
          </View>
          <View style={[styles.row, { borderBottom: '1pt solid #888'}]}>
            <View style={{width: '30%'}}>
              <Text style={styles.bold}>Producto</Text>
            </View>
            <View style={{width: '30%'}}>
              <Text style={styles.bold}>Detalle</Text>
            </View>
            <View style={{width: '15%'}}>
              <Text style={styles.bold}>Cantidad</Text>
            </View>
          </View>
          {ayuda.productosDetalle.map(pd => (
            <View style={[styles.row]} key={pd.idProducto}>
              <View style={{width: '30%'}}>
                <Text>{pd.producto.descripcion}</Text>
              </View>
              <View style={{width: '30%'}}>
                <Text>{pd.producto.detalle}</Text>
              </View>
              <View style={{width: '15%'}}>
                <Text>{pd.producto.tipo.esMonto ? `$${formatPrecio(pd.cantidad)}` : pd.cantidad}</Text>
              </View>
            </View>
          ))}
          <View style={[styles.row, { flexGrow: 1 }]}></View>
          <View style={styles.row}>
            <Text style={[styles.bold, { fontSize: 12 }]}>Emitido por</Text>
          </View>
          <View style={styles.row}>
            <View style={{width: '70%'}}>
              <Text>
                <Text style={styles.bold}>Nombre: </Text>
                {ayuda.usuarioCreador.nombres} {ayuda.usuarioCreador.apellidos}
              </Text>
            </View>
            <View style={{width: '30%', borderTop: '1pt solid black', textAlign: 'center'}}>
              <Text>Firma</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
