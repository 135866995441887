import React, { useState, useCallback } from 'react';
import { TableRow, TableCell, Grid, Button, Table, TableHead, TableBody, Typography } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { hasPermission } from 'auth';
import { CompactTableCell, Breadcrumbs, Title, FilterGrid, TextField, Loader } from 'components/ui';
import { useLazyQuery, useApi } from 'api';
import { formatPrecio, formatProducto, formatDireccion, formatDate } from 'helpers/format';
import { formatRut, getEstadoAyudaIcon } from '../../helpers';
import { PERMISO } from '../../constants';

function ConstulaAyudas() {
  const [rut, setRut] = useState('');
  const [beneficiario, setBeneficiario] = useState(null);
  const [ayudasDisponibles, setAyudasDisponibles] = useState([]);
  const [productosDisponibles, setProductosDisponibles] = useState([]);
  const [loadingAyudas, setLoadingAyudas] = useState(false);
  const [loadingProductos, setLoadingProductos] = useState(false);
  const callApi = useApi();
  const [buscarBeneficiarios, { loading, loaded }] = useLazyQuery('/beneficiarios');

  const buscarAyudas = useCallback(async (idBeneficiario) => {
    setLoadingAyudas(true);
    callApi(`/beneficiarios/${idBeneficiario}/ayudasDisponibles`)
    .then(({ data }) => {
      setLoadingAyudas(false);
      setAyudasDisponibles(data);
    });
  }, []);

  const buscarProductos = useCallback(async (idBeneficiario) => {
    setLoadingProductos(true);
    callApi(`/beneficiarios/${idBeneficiario}/productosDisponibles`)
    .then(({ data }) => {
      setLoadingProductos(false);
      setProductosDisponibles(data);
    });
  }, []);

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleBuscarUsuario();
    }
  }

  const handleBuscarUsuario = async () => {
    setProductosDisponibles([]);
    const res = await buscarBeneficiarios({ query: { rut } });
    const beneficiario = res.data.nodes[0];
    
    setBeneficiario(beneficiario);
    if (beneficiario) {
      buscarAyudas(beneficiario.id);
      buscarProductos(beneficiario.id);
    }
  };

  const sinAyudas = loaded && beneficiario && !loadingProductos && productosDisponibles.length === 0;

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'consulta', title: 'Consulta Ayudas Sociales', to: '/ayudas/consulta', active: true },
      ]} />
      <Title>Consulta Ayudas Sociales</Title>
      <Loader loading={loading || loadingProductos || loadingAyudas} />

      <FilterGrid container spacing={3} alignContent='center' alignItems='center'>
        <Grid item xs={12} md={4}>
          <TextField
            required
            variant="outlined"
            name="rut"
            label="RUT Usuario"
            size="small"
            fluid
            value={rut}
            onChange={(e) => setRut(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            onClick={handleBuscarUsuario}
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={rut.trim().length === 0}
          >
            Buscar
          </Button>
        </Grid>
      </FilterGrid>

      {beneficiario && (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="left">Usuario</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>RUT</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Apellido</TableCell>
                  <TableCell>Dirección</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{formatRut(beneficiario.rut)}</TableCell>
                  <TableCell>{beneficiario.primerNombre} {beneficiario.segundoNombre}</TableCell>
                  <TableCell>{beneficiario.primerApellido} {beneficiario.segundoApellido}</TableCell>
                  <TableCell>{formatDireccion(beneficiario.direccion)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" align="left">Ayudas Disponibles</Typography>
            {sinAyudas ? (
              <Alert variant="filled" severity="warning">
                El usuario seleccionado no posee ayudas disponibles o con stock en este momento
              </Alert>
            ) : (
              <Table>
                <TableHead>
                  <TableCell>Decreto Nº</TableCell>
                  <TableCell>Fecha Creación</TableCell>
                  <TableCell>Meses Ayuda</TableCell>
                  <TableCell>Estado</TableCell>
                </TableHead>
                <TableBody>
                  {ayudasDisponibles.map(ayuda => (
                    <TableRow key={ayuda.id}>
                      <TableCell>{ayuda.decreto}</TableCell>
                      <TableCell>{formatDate(ayuda.fechaIngreso)}</TableCell>
                      <TableCell>{ayuda.cantidadMeses}</TableCell>
                      <TableCell>{getEstadoAyudaIcon(ayuda.estado)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
          {!sinAyudas && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="left">Productos Disponibles</Typography>
              <Table>
                <TableHead>
                  <TableCell>Producto</TableCell>
                  <TableCell>Cant. Habilitada</TableCell>
                  <TableCell>Stock</TableCell>
                  <TableCell>Estado</TableCell>
                </TableHead>
                <TableBody>
                  {productosDisponibles.map(pd => {
                    const { esMonto } = pd.producto.tipo;
                    const ayuda = ayudasDisponibles[0];

                    return (
                      <TableRow key={pd.producto.id}>
                        <CompactTableCell>{formatProducto(pd.producto)}</CompactTableCell>
                        <CompactTableCell>{esMonto ? `$${formatPrecio(pd.disponibles)}` : pd.disponibles}</CompactTableCell>
                        <CompactTableCell>{esMonto ? `$${formatPrecio(pd.producto.stock)}` : pd.producto.stock}</CompactTableCell>
                        <CompactTableCell>{getEstadoAyudaIcon(ayuda && ayuda.estado)}</CompactTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Grid>
          )}
        </Grid>
      )}

      {(loaded && !beneficiario) && (
        <Alert variant="filled" severity="warning">
          No se encuentra un usuario registrado con ese RUT
        </Alert>
      )}

    </div>
  );
}

export default hasPermission([PERMISO.ConsultaAyudas])(ConstulaAyudas);
