import moment from "moment";
import { formatRut } from "./usuario";

export function formatDate(date, format = "DD/MM/YYYY") {
  if (!date) return "";
  const parsed = moment(date);
  if (!date || !parsed.isValid()) {
    return null;
  }
  return parsed.format(format);
}

export function truncate(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.substr(0, maxLength) + "...";
  }
}

export function formatBeneficiario(ben) {
  if (!ben) return "";
  return [
    ben.primerNombre,
    ben.segundoNombre,
    ben.primerApellido,
    ben.segundoApellido,
  ]
    .filter((n) => !!n)
    .join(" ");
}

export function formatBeneficiarioDetallado(ben) {
  if (!ben) return "";
  return `${formatRut(ben.rut)} - ${ben.primerNombre} ${ben.primerApellido}`;
}

export function formatDireccion(dir) {
  if (!dir) return "";
  if (dir.idTipo === 1) {
    return [dir.calle, dir.numero, dir.villa, dir.sector]
      .filter((d) => !!d)
      .join(", ");
  } else if (dir.idTipo === 2) {
    return [dir.calle, dir.sector, dir.villa, dir.otro]
      .filter((d) => !!d)
      .join(", ");
  }
  return "";
}

export function formatPrecio(precio) {
  if (!precio) {
    return "";
  }
  return precio
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function formatProducto(prod) {
  if (!prod) return "";
  return prod.descripcion + (prod.detalle ? ` (${prod.detalle})` : "");
}

export function formatRecepcion(recepcion) {
  if (!recepcion) return "";
  return `#${recepcion.id} - ${recepcion.proveedor?.razonSocial}`
}

export function formatProductoItem(pi) {
  if (!pi) return "";
  const { esMonto } = pi.producto.tipo;
  const sep = esMonto ? "$" : "x";
  const cantidad = esMonto ? formatPrecio(pi.cantidad) : pi.cantidad.toString();
  return `${formatProducto(pi.producto)} ${sep}${cantidad}`;
}

export function roundDecimals(num, decimals) {
  const k = Math.pow(10, decimals);
  return Math.round((num + Number.EPSILON) * k) / k;
}
