import React from 'react';
import { useField } from 'formik';
import { Select as SelectField } from '../Select';

export function Select(props) {
  const [field, meta] = useField(props)
  const error = meta.touched && meta.error;
  
  return (
    <SelectField
      {...field}
      {...props}
      value={field.value || ''}
      error={error}
    />
  );
}
