import React from 'react'
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import ProductoForm from 'components/producto/ProductoForm';
import { hasPermission } from 'auth';
import { useMutation } from 'api';
import { PERMISO } from '../../constants';

function CrearProducto() {
  const [crearProducto, { loading, error }] = useMutation('/productos', { method: 'post' });

  const handleSubmit = async values => {
    const { error } = await crearProducto({ body: values });

    if (!error) {
      navigate('/admin/productos');
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'productos', title: 'Productos', to: '/admin/productos' },
        { key: 'actual', title: 'Nuevo producto', to: '/admin/productos/nuevo', active: true },
      ]} />
      <Title>Nuevo Producto</Title>
      <Loader loading={loading} />

      <ProductoForm
        error={error}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default hasPermission([PERMISO.ProductosModificar])(CrearProducto);
