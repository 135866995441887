import React from 'react';
import Admin from 'components/admin';
import DepartamentoForm from 'components/listados/DepartamentoForm';
import { PERMISO } from '../../constants';

export default function() {
  return (
    <Admin
      createTitle="Nuevo Departamento"
      listTitle="Departamentos"
      editTitle="Editar Departamento"
      routePath="/admin/departamentos"
      apiPath="/departamentos"
      FormComponent={DepartamentoForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.DepartamentosVer]}
      modifyPerms={[PERMISO.DepartamentosModificar]}
      headerItems={['ID', 'Nombre']}
      rowItems={row => [row.id, row.nombre]}
    />
  )
}