import React, { useState } from 'react';
import { Grid, TableRow, TableCell, Button } from '@material-ui/core';
import { GetApp as DownloadIcon, PictureAsPdf } from '@material-ui/icons';
import _, { omit } from 'lodash';
import moment from 'moment';
import { pdf } from '@react-pdf/renderer';
import { hasPermission } from 'auth';
import { DataTable, Loader, CompactTableCell, TextField, Breadcrumbs, Title, FilterGrid, Lookup, DownloadButton } from 'components/ui';
import { useQuery, useLazyQuery } from 'api';
import { formatRut } from 'helpers/usuario';
import { formatDate, formatPrecio } from 'helpers/format';
import ReporteProcesamientoDocument from '../../components/pdf/ReporteProcesamientoDocument';
import { PERMISO } from '../../constants';

function ReporteProcesamiento() {
  const [state, setState] = useState({ page: 1, pageSize: 25 });
  
  const { data: items, loading, refetch } = useQuery('/reportes/procesamiento', { query: state });
  const [descargarReporte, { loading: downloading }] = useLazyQuery('/reportes/procesamiento');
  const [buscarProveedores] = useLazyQuery('/proveedores');
  const [buscarProductos] = useLazyQuery('/productos');

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    handleFilter(newState);
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
    
  const getQuery = (state) => {
    const query = {}
    if (state.fechaDesde) {
      query.fechaDesde = moment.utc(state.fechaDesde).startOf('day').toISOString();
    }
    if (state.fechaHasta) {
      query.fechaHasta = moment.utc(state.fechaHasta).endOf('day').toISOString();
    }
    if (state.proveedor) {
      query.idProveedor = state.proveedor.id;
    }
    if (state.productoEntrada) {
      query.idProductoEntrada = state.productoEntrada.id;
    }

    query.page = state.page;
    query.pageSize = state.pageSize;

    return query;
  }

  const handleFilter = (filterState) => {
    const query = getQuery(filterState);
    refetch({ query });
  }

  const handleDownloadExcel = async () => {
    const query = omit(getQuery(state), ['page', 'pageSize']);
    const content = await descargarReporte({ type: 'blob', query: { ...query, format: 'xls' } });
    const filename = `reporte-procesamiento-${moment().format('YYYY-MM-DD-HH-mm')}.xlsx`;
    return { filename, content };
  }

  const handleDownloadCSV = async () => {
    const query = omit(getQuery(state), ['page', 'pageSize']);
    const content = await descargarReporte({ type: 'blob', query: { ...query, format: 'csv' } });
    const filename = `reporte-procesamiento-${moment().format('YYYY-MM-DD-HH-mm')}.csv`;
    return { filename, content };
  }

  const generatePdfDocument = React.useCallback(async () => {
    const blob = await pdf((
      <ReporteProcesamientoDocument
        procesamientos={items.nodes}
        proveedor={state.proveedor}
        productoEntrada={state.productoEntrada}
      />
    )).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
    // saveAs(blob, `reporte-procesamiento-${moment().format('YYYY-MM-DD-HH-mm')}.pdf`);
  }, [items, state]);
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'actual', title: 'Reporte Procesamiento', to: '/reportes/procesamiento', active: true },
      ]} />
      <Title>Reporte Procesamiento</Title>
      <Loader loading={loading || downloading} />
      
      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Lookup
            name="proveedor"
            label="Proveedor"
            size="small"
            fluid
            onChange={(e, val) => setState({ ...state, proveedor: val })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? `${formatRut(option.rut)} - ${option.razonSocial}` : ''}
            fetcher={(busqueda) => buscarProveedores({ query: { busqueda } }).then(resp => resp.data.nodes)}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Lookup
            name="productoEntrada"
            label="Producto a Procesar"
            size="small"
            fluid
            onChange={(e, val) => setState({ ...state, productoEntrada: val })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? option.descripcion : ''}
            fetcher={(busqueda) => buscarProductos({ query: { busqueda } }).then(resp => resp.data.nodes)}
          />
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <Lookup
            name="productoSalida"
            label="Producto Salida"
            size="small"
            fluid
            onChange={(e, val) => setState({ ...state, productoSalida: val ? val.id : null })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? option.descripcion : ''}
            fetcher={() => buscarProductos().then(resp => resp.data.nodes)}
          />
        </Grid> */}
        <Grid item xs={12} md={3}>
          <TextField
            label="Desde"
            type="date"
            InputLabelProps={{shrink: true}}
            fluid
            onChange={(e) => setState({ ...state, fechaDesde: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Hasta"
            type="date"
            InputLabelProps={{shrink: true}}
            fluid
            onChange={(e) => setState({ ...state, fechaHasta: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button variant="contained" color="primary" size="small" onClick={() => handleFilter(state)}>Filtrar</Button>
        </Grid>
        <Grid item xs={12} align="right">
          <DownloadButton
            variant="contained"
            color="primary"
            size="small"
            type='text/csv;charset=utf-8'
            fetchFile={handleDownloadCSV}
            startIcon={<DownloadIcon />}
            style={{marginRight: 10}}
          >
            Descargar CSV
          </DownloadButton>
          <DownloadButton
            variant="contained"
            color="primary"
            size="small"
            type='vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
            fetchFile={handleDownloadExcel}
            startIcon={<DownloadIcon />}
            style={{marginRight: 10}}
          >
            Descargar Excel
          </DownloadButton>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<PictureAsPdf />}
            disabled={!items || items.count === 0}
            onClick={generatePdfDocument}
          >
            Generar PDF
          </Button>
        </Grid>
      </FilterGrid>

      <DataTable
        data={items}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <ReporteHeader />}
        renderRow={(item) => (
          <ReporteRow
            key={item.id}
            item={item}
          />
        )}
      />
    </div>
  );
}

function ReporteHeader() {
  return (
    <TableRow>
      <TableCell>Procesamiento Nº</TableCell>
      <TableCell>Proveedor</TableCell>
      <TableCell>RUT</TableCell>
      <TableCell>Fecha</TableCell>
      <TableCell>Producto</TableCell>
      <TableCell>Cantidad</TableCell>
      {/* <TableCell>Producto Salida</TableCell>
      <TableCell>Cantidad</TableCell> */}
      <TableCell>Funcionario</TableCell>
    </TableRow>
  )
}

function ReporteRow({ item }) {
  const nrows = Math.max(item.productosEntrada.length, item.productosSalida.length);
  const prodIn0 = item.productosEntrada[0];
  // const prodOut0 = item.productosSalida[0];

  return (
    <>
      <TableRow>
        <CompactTableCell rowSpan={nrows}>{item.id}</CompactTableCell>
        <CompactTableCell rowSpan={nrows}>{item.proveedor}</CompactTableCell>
        <CompactTableCell rowSpan={nrows}>{formatRut(item.rut)}</CompactTableCell>
        <CompactTableCell rowSpan={nrows}>{formatDate(item.fecha)}</CompactTableCell>
        <CompactTableCell>{prodIn0.producto}</CompactTableCell>
        <CompactTableCell align="right">{prodIn0.esMonto ? `$${formatPrecio(prodIn0.cantidad)}` : prodIn0.cantidad}</CompactTableCell>
        {/* <CompactTableCell>{prodOut0.producto}</CompactTableCell>
        <CompactTableCell align="right">{prodOut0.esMonto ? `$${formatPrecio(prodOut0.cantidad)}` : prodOut0.cantidad}</CompactTableCell> */}
        <CompactTableCell rowSpan={nrows}>{item.funcionario}</CompactTableCell>
      </TableRow>
      {_.range(1, nrows).map(idx => {
        const entrada = item.productosEntrada[idx];
        // const salida = item.productosSalida[idx];
        
        return (
          <TableRow key={idx}>
            <CompactTableCell>{entrada ? entrada.producto : null}</CompactTableCell>
            <CompactTableCell align="right">{entrada ? (entrada.esMonto ? `$${formatPrecio(entrada.cantidad)}` : entrada.cantidad) : null}</CompactTableCell>
            {/* <CompactTableCell>{salida ? salida.producto : null}</CompactTableCell>
            <CompactTableCell align="right">{salida ? (salida.esMonto ? `$${formatPrecio(salida.cantidad)}` : salida.cantidad) : null}</CompactTableCell> */}
          </TableRow>
        );
      })}
    </>
  )
}

export default hasPermission([PERMISO.ReportesProcesamiento])(ReporteProcesamiento);
