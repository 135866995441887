import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { Typography, Table, TableHead, TableCell, TableRow, TableBody, MenuItem, Grid } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { useLazyQuery, useQuery } from 'api';
import { useAuth } from 'auth';
import { Formik, Form, Fab, ApiErrorMessage, Loader, UploadButton } from 'components/ui';
import { formatRut, formatProducto, formatPrecio, formatBeneficiarioDetallado, validateLength } from 'helpers';
import { FieldArray } from 'formik';
import AdjuntoCard from 'components/adjunto/AdjuntoCard';

function EntregaEditForm ({ entrega, error, onSubmit }) {
  const { auth } = useAuth();
  const { data: productosDisponiblesData, loading: loadingProductos } = useQuery(`/beneficiarios/${entrega.idBeneficiario}/productosDisponibles`);
  const [buscarUsuarios] = useLazyQuery('/usuarios');

  const productosDisponibles = useMemo(() => {
    let prodMap = {};

    entrega.productosDetalle.forEach(pd => {
      if (!prodMap[pd.idProducto])
        prodMap[pd.idProducto] = { producto: pd.producto, disponibles: 0 };
      
      prodMap[pd.idProducto].disponibles += pd.cantidad;
    })

    if (productosDisponiblesData) {
      productosDisponiblesData.forEach(pd => {
        if (!prodMap[pd.producto.id])
          prodMap[pd.producto.id] = { producto: pd.producto, disponibles: 0 };
        
        prodMap[pd.producto.id].disponibles += pd.disponibles;
      })
    }

    return prodMap;
  }, [entrega, productosDisponiblesData]);

  const initialValues = {
    beneficiario: entrega.beneficiario,
    repartidor: entrega.usuarioRepartidor,
    estado: entrega.estado.toString(),
    fechaEntrega: entrega.fechaEntrega,
    observaciones: entrega.observaciones,
    rutReceptor: entrega.rutReceptor,
    nombreReceptor: entrega.nombreReceptor,
    numOrdenCompra: entrega.numOrdenCompra,
    productosDetalle: _.keyBy(entrega.productosDetalle, 'idProducto'),
    adjuntos: entrega.adjuntos,
  };

  const handleFormSubmit = (input) => {
    const values = _.chain(input)
      .omit(['beneficiario', 'repartidor', 'productosDetalle'])
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    values.idBeneficiario = input.beneficiario.id;
    if (input.repartidor) {
      values.idUsuarioRepartidor = input.repartidor.id;
    } else {
      values.idUsuarioRepartidor = auth.user.id;
    }
    values.productosDetalle = Object.keys(input.productosDetalle)
      .filter(idProducto => input.productosDetalle[idProducto].cantidad > 0)
      .map(idProducto => ({
        idProducto: Number(idProducto),
        cantidad: input.productosDetalle[idProducto].cantidad,
      }));
    
    values.fechaEntrega = moment.utc().toISOString();

    onSubmit(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm.bind(null, productosDisponibles)}
    >
      {({ values, errors, setFieldValue, dirty, handleSubmit }) => (
        <Form alignItems="center">
          <Loader loading={loadingProductos} />
          
          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Datos Generales</Typography>
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.Lookup
              disabled
              name="beneficiario"
              label="Usuario"
              fluid
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option ? formatBeneficiarioDetallado(option) : ''}
              fetcher={async () => []}
            />
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.Lookup
              name="repartidor"
              label="Funcionario"
              fluid
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option ? `${formatRut(option.rut)} - ${option.nombres} ${option.apellidos}` : ''}
              fetcher={(busqueda) => buscarUsuarios({ query: { busqueda } }).then(resp => resp.data.nodes)}
            />
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.Select required name="estado" label="Estado" fluid align="left">
              <MenuItem value="0">Pendiente de entrega</MenuItem>
              <MenuItem value="1">Entregado</MenuItem>
              <MenuItem value="2">No se pudo entregar</MenuItem>
            </Form.Select>
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField required name="rutReceptor" label="RUT Receptor" fluid />
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.TextField required name="nombreReceptor" label="Nombre Receptor" fluid validate={validateLength(0, 200)} />
          </Form.Field>
          <Form.Field xs={12} md={3}>
            <Form.TextField name="numOrdenCompra" label="# Orden Compra" fluid validate={validateLength(0, 100)} />
          </Form.Field>
          

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Productos</Typography>
          </Form.Field>

          {!_.isEmpty(productosDisponibles) && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Producto</TableCell>
                  <TableCell>Cant. Habilitada</TableCell>
                  <TableCell>Cant. a Entregar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.keys(productosDisponibles).map((idProducto) => {
                  const { producto, disponibles } = productosDisponibles[idProducto];
                  
                  return (
                    <TableRow key={`${entrega.id}-${idProducto}`}>
                      <TableCell>{formatProducto(producto)}</TableCell>
                      <TableCell>{producto.tipo.esMonto ? `$${formatPrecio(disponibles)}` : disponibles}</TableCell>
                      <TableCell>
                        <Form.TextField
                          required
                          name={`productosDetalle.${idProducto}.cantidad`}
                          label="Cantidad"
                          type="number"
                          disabled={disponibles === 0}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Documentos</Typography>

            <FieldArray name="adjuntos" render={arrayHelpers => (
              <>
                <Grid container spacing={3}>
                  {values.adjuntos.map((adj, index) => (
                    <Grid item xs={3} align="center" key={adj.id}>
                      <AdjuntoCard
                        adjunto={adj}
                        onDelete={() => arrayHelpers.remove(index)}
                      />
                    </Grid>
                  ))}
                  {values.adjuntos.length === 0 ? <Grid item xs={12} align="center">No hay documentos asociados</Grid> : null}
                  <Grid item xs={12} align="center">
                    <UploadButton onUpload={adjunto => arrayHelpers.push(adjunto)} />
                  </Grid>
                </Grid>
              </>
            )} />

          </Form.Field>

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Observaciones</Typography>
          </Form.Field>
          <Form.Field xs={12}>
            <Form.TextArea name="observaciones" placeholder="Ingrese un texto..." fluid />
          </Form.Field>

          <Form.Field xs={12}>
            <Form.ErrorMessage name='productosDetalle' />
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty || _.isEmpty(values.productosDetalle)}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(productosDisponibles, values) {
  const errors = {};

  if (!values.rutReceptor) {
    errors.rutReceptor = 'El RUT del receptor es obligatorio'
  }
  if (!values.nombreReceptor) {
    errors.nombreReceptor = 'El nombre del receptor es obligatorio'
  }
  
  if (_.isEmpty(_.pickBy(values.productosDetalle, pd => pd.cantidad > 0))) {
    errors.productosDetalle = 'Debe ingresar al menos un producto'
  } else {
    errors.productosDetalle = {};
    _.keys(productosDisponibles).forEach(idProducto => {
      const pd = productosDisponibles[idProducto];
      const { esMonto } = pd.producto.tipo;
      
      if (values.productosDetalle[idProducto]) {
        const detalle = values.productosDetalle[idProducto];
        const pdErrors = {};
        
        let cantidad = Number(detalle.cantidad);
        if (_.isNaN(cantidad) || cantidad < 0) {
          pdErrors.cantidad = 'La cantidad ingresada no es válida';
        } else {
          if (esMonto && cantidad !== pd.disponibles) {
            pdErrors.cantidad = 'No puede entregar un monto diferente al habilitado';
          } else if (cantidad > pd.disponibles) {
            pdErrors.cantidad = 'Excede la cantidad habilitada';
          }
        }

        if (!_.isEmpty(pdErrors)) {
          errors.productosDetalle[idProducto] = pdErrors;
        }
      }
    });

    if (_.isEmpty(errors.productosDetalle)) {
      delete errors.productosDetalle;
    }
  }
  
  return errors;
}

EntregaEditForm.propTypes = {
  entrega: PropTypes.object.isRequired,
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default EntregaEditForm;