import React from 'react';
import moment from 'moment';
import { chain, sum } from 'lodash';
import { Page, Text, View, Document, Image, Font } from '@react-pdf/renderer';
import { formatRut, formatBeneficiario, formatDireccion, roundDecimals } from '../../helpers';
import { styles, DataRow } from './pdfComponents';

Font.register({
  family: 'Roboto-Bold',
  src: require('typeface-roboto/files/roboto-latin-500.woff'),
  fontStyle: 'normal',
  fontWeight: 'bold'
});

export default function ComprobanteEntregaPdf ({ entrega }) {
  // const idEntrega = padStart(entrega.id.toString(), 8, '0');
  const detalle = chain(entrega.productosDetalle)
    .map(pd => ({
      idProducto: pd.idProducto,
      producto: pd.producto,
      cantidad: pd.cantidad,
      precio: '',
      esMonto: pd.producto.tipo.esMonto,
    }))
    .groupBy(pd => pd.idProducto)
    .mapValues(pds => ({
      ...pds[0],
      cantidad: sum(pds.map(pd => pd.cantidad)),
      precio: roundDecimals(sum(pds.map(pd => pd.cantidad * pd.precio)), 3)
    }))
    .values()
    .value();
  // const valorTotal = Math.round(sum(detalle.map(pd => pd.precio)));

  const ayuda = entrega.ayudas[0];

  return (
    <Document title={`Comprobante de entrega #${entrega.id}`}>
      <Page size="A4">
        <View style={styles.page}>
          <View style={[styles.row, styles.divider]}>
            <View style={{width: '30%', flexGrow: 1}}>
              <Image src='/img/logo192.png' style={{width: 60, height: 60}} />
            </View>
            <View style={{width: '70%', flexGrow: 1}}>
              <Text style={styles.titleText}>Comprobante de Entrega</Text>
            </View>
          </View>
          <View style={[styles.fixedRow, styles.divider, { marginTop: 20, marginBottom: 20, paddingBottom: 20 }]}>
            <View style={styles.col6}>
              <DataRow title="Entrega #" value={entrega.id} />
              <DataRow title="Fecha" value={moment(entrega.fechaEntrega).format('DD/MM/YYYY HH:mm')} />
              <DataRow title="RUT" value={formatRut(entrega.beneficiario.rut)} />
              <DataRow title="Nombre Completo" value={formatBeneficiario(entrega.beneficiario)} />
              <DataRow title="Dirección" value={formatDireccion(entrega.beneficiario.direccion)} />
              <DataRow title="Funcionario" value={entrega.usuarioRepartidor ? `${entrega.usuarioRepartidor.nombres} ${entrega.usuarioRepartidor.apellidos}` : ''} />
              <DataRow title="# Orden Compra" value={entrega.numOrdenCompra} />
            </View>
            {ayuda && (
              <View style={styles.col6}>
                <DataRow title="Tipo de Documento" value={ayuda.tipoPago ? ayuda.tipoPago.nombre : ''} />
                <DataRow title="Nº de Documento" value={ayuda.documentoPago} />
                <DataRow title="Destinatario" value={ayuda.destinatarioPago} />
              </View>
            )}
          </View>
          <View style={[styles.row, { borderBottom: '1pt solid #888'}]}>
            <View style={{width: '30%'}}>
              <Text style={styles.bold}>Producto</Text>
            </View>
            <View style={{width: '30%'}}>
              <Text style={styles.bold}>Detalle</Text>
            </View>
            <View style={{width: '30%', textAlign: 'right'}}>
              <Text style={styles.bold}>Cantidad</Text>
            </View>
            {/* <View style={{width: '15%', textAlign: 'right'}}>
              <Text style={styles.bold}>Valor</Text>
            </View> */}
          </View>
          {detalle.map(pd => (
            <View style={[styles.row]} key={pd.idRecepcionProducto}>
              <View style={{width: '30%'}}>
                <Text>{pd.producto.descripcion}</Text>
              </View>
              <View style={{width: '30%'}}>
                <Text>{pd.producto.detalle}</Text>
              </View>
              <View style={{width: '30%', textAlign: 'right'}}>
                <Text>{pd.esMonto ? '' : pd.cantidad}</Text>
              </View>
              {/* <View style={{width: '15%', textAlign: 'right'}}>
                <Text>${formatPrecio(pd.precio)}</Text>
              </View> */}
            </View>
          ))}
          {/* <View style={[styles.row, { marginTop: 20 }]}>
            <View style={{width: '30%'}}></View>
            <View style={{width: '30%'}}></View>
            <View style={{width: '15%'}}>
              <Text style={styles.bold}>Valor Total:</Text>
            </View>
            <View style={{width: '15%', textAlign: 'right'}}>
              <Text style={styles.bold}>${formatPrecio(valorTotal)}</Text>
            </View>
          </View> */}
          <View style={[styles.row, { flexGrow: 1 }]}></View>
          <View style={styles.row}>
            <Text style={[styles.bold, { fontSize: 12 }]}>Recibe</Text>
          </View>
          <View style={styles.row}>
            <View style={{width: '70%'}}>
              <Text>
                <Text style={styles.bold}>Nombre: </Text>
                {entrega.nombreReceptor || formatBeneficiario(entrega.beneficiario)}
              </Text>
              <Text>
                <Text style={styles.bold}>RUT: </Text>
                {formatRut(entrega.rutReceptor || entrega.beneficiario.rut)}
              </Text>
            </View>
            <View style={{width: '30%', borderTop: '1pt solid black', textAlign: 'center'}}>
              <Text>Firma</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
