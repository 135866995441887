import React, { useMemo } from 'react';
import _ from 'lodash';
import { navigate } from '@reach/router';
import { TableRow, TableCell, IconButton, Tooltip } from '@material-ui/core';
import { PictureAsPdf as PdfIcon, Comment, Edit } from '@material-ui/icons';
import { pdf } from '@react-pdf/renderer';
import { ActionsButton, CompactTableCell } from '../ui';
import { formatRut, formatBeneficiario, formatDireccion, formatDate, formatProductoItem } from '../../helpers';
import ComprobanteEntregaPdf from '../pdf/ComprobanteEntregaPdf';
import AdjuntoDownloadButton from 'components/adjunto/AdjuntoDownloadButton';

export const EstadosEntrega = { 0: 'Pendiente', 1: 'Entregado', 2: 'No entregado' }

function useProductosEntrega(entrega) {
  return useMemo(() => _.chain(entrega.productosDetalle)
    .groupBy(pd => pd.idProducto)
    .mapValues(pds => ({
      producto: pds[0].producto,
      cantidad: _.sum(pds.map(pd => pd.cantidad)),
    }))
    .values()
    .value()
  , [entrega]);
}

export function EntregaRealizadaHeader() {
  return (
    <>
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>RUT</TableCell>
        <TableCell>Usuario</TableCell>
        <TableCell>Dirección</TableCell>
        <TableCell>Funcionario</TableCell>
        <TableCell>Fecha Entregado</TableCell>
        <TableCell>Productos</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </>
  )
}

export function EntregaRealizadaRow({ entrega, canEdit }) {
  const productos = useProductosEntrega(entrega);
  const cantProds = Math.max(1, productos.length);
  const pd0 = productos[0];

  const generatePdfDocument = React.useCallback(async () => {
    const blob = await pdf(<ComprobanteEntregaPdf entrega={entrega} />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
    // saveAs(blob, `entrega-${entrega.id}.pdf`);
  }, [entrega]);
  
  return (
    <>
      <TableRow>
        <CompactTableCell rowSpan={cantProds}>{entrega.id}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatRut(entrega.beneficiario.rut)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatBeneficiario(entrega.beneficiario)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatDireccion(entrega.beneficiario.direccion)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{entrega.usuarioRepartidor ? `${entrega.usuarioRepartidor.nombres} ${entrega.usuarioRepartidor.apellidos}` : ''}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatDate(entrega.fechaEntrega)}</CompactTableCell>
        <CompactTableCell>{formatProductoItem(pd0)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>
          {entrega.observaciones && (
            <Tooltip title={entrega.observaciones}>
              <Comment color='disabled' />
            </Tooltip>
          )}
        </CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>
          <Tooltip title="Guía de salida">
            <IconButton aria-label="descargar pdf" onClick={generatePdfDocument}>
              <PdfIcon style={{color: "firebrick"}} />
            </IconButton>
          </Tooltip>
          {entrega.adjuntos.map(adj => <AdjuntoDownloadButton key={adj.id} adjunto={adj} />)}
        </CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>
          {canEdit && (
            <ActionsButton
              items={[
                { key: 'editar', text: 'Editar', icon: <Edit/>, onClick: () => navigate(`/entregas/${entrega.id}/editar`) },
              ]}
            />
          )}
        </CompactTableCell>
      </TableRow>
      {productos.slice(1).map(pd => (
        <TableRow key={pd.producto.id}>
          <CompactTableCell>{formatProductoItem(pd)}</CompactTableCell>
        </TableRow>
      ))}
    </>
  )
}

export function EntregaPendienteHeader() {
  return (
    <>
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>RUT</TableCell>
        <TableCell>Usuario</TableCell>
        <TableCell>Dirección</TableCell>
        <TableCell>Funcionario</TableCell>
        <TableCell>Estado</TableCell>
        <TableCell>Productos</TableCell>
      </TableRow>
    </>
  )
}

export function EntregaPendienteRow({ entrega, canEdit }) {
  const productos = useProductosEntrega(entrega);
  const cantProds = Math.max(1, productos.length);
  const pd0 = productos[0];
  
  return (
    <>
      <TableRow>
        <CompactTableCell rowSpan={cantProds}>{entrega.id}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatRut(entrega.beneficiario.rut)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatBeneficiario(entrega.beneficiario)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatDireccion(entrega.beneficiario.direccion)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{entrega.usuarioRepartidor ? `${entrega.usuarioRepartidor.nombres} ${entrega.usuarioRepartidor.apellidos}` : ''}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{EstadosEntrega[entrega.estado]}</CompactTableCell>
        <CompactTableCell>{formatProductoItem(pd0)}</CompactTableCell>
      </TableRow>
      {productos.slice(1).map(pd => (
        <TableRow key={pd.idProducto}>
          <CompactTableCell>{formatProductoItem(pd)}</CompactTableCell>
        </TableRow>
      ))}
    </>
  )
}
