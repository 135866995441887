import React, { useState, useCallback } from 'react';
import { debounce } from 'lodash';
import { CircularProgress, InputAdornment } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Search } from '@material-ui/icons';
import { TextField } from './TextField';

export function Lookup({ fetcher, label, fluid, inputProps, ...props }) {
  const [text, setText] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetcherFn = useCallback(
    debounce((value, active) => {
      setLoading(true);
      fetcher(value)
        .then(results => {
          setLoading(false);
    
          if (active) {
            setOptions(results || []);
          }
        });
    }, 500),
  []);

  const handleOpen = () => {
    setOpen(true);
    fetcherFn('', true);
  }
  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  }

  React.useEffect(() => {
    let active = true;

    if (text === '') {
      return undefined;
    }

    fetcherFn(text, active)

    return () => {
      active = false;
    };
  }, [text, fetcherFn]);

  return (
    <Autocomplete
      {...props}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      options={options}
      loading={loading}
      filterOptions={x => x}
      clearOnBlur={false}
      onInputChange={(event, newInputValue) => {
        setText(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...inputProps}
          label={label}
          fluid={fluid}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            startAdornment: <InputAdornment><Search /></InputAdornment>,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}