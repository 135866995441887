
export function cleanRut(rut) {
  return rut.trim().toLowerCase().replace(/[^\dk]/g, '');
}

export function validateRut(rut) {
  const cleaned = cleanRut(rut);
  if (!cleaned.match(/\d{6,}[\dk]/)) { return false; }
  
  const digits = cleaned.substring(0, cleaned.length - 1);
  const L = digits.length;
  const digVerif = cleaned[cleaned.length - 1];
  const weights = [2, 3, 4, 5, 6, 7];
  let S = 0;
  
  for(let i = 0; i < L; i++) {
    S += Number(digits[L-(i+1)]) * weights[i % weights.length];
  }

  const R = 11 - (S % 11);
  let verif;
  
  if (R === 11) {
    verif = '0';
  } else if (R === 10) {
    verif = 'k'
  } else {
    verif = R.toString();
  }

  return verif === digVerif;
}

export function formatRut(rut) {
  if (!rut){ return '' }
  const cleaned = cleanRut(rut.substr(0, rut.length - 1));
  return cleaned.replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '-' + rut.substr(-1).toUpperCase();
}
