import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Table, TableHead, TableBody } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { Formik, Form, Fab, ApiErrorMessage, Loader } from 'components/ui';
import { useLazyQuery, useQuery } from 'api';
import { validateLength } from 'helpers';
import { AyudaHeader, AyudaRow } from './AyudaTable';

function AprobarAyudaForm ({ idAyuda, error, onSubmit }) {
  const { data: ayuda, loading } = useQuery(`/ayudas/${idAyuda}`)
  const [buscarTiposPago] = useLazyQuery('/tipos-pago');

  if (ayuda && ayuda.estado !== 0)
    return null;

  const initialValues = {
    tipoPago: null,
    documentoPago: '',
    destinatarioPago: '',
  };

  const handleFormSubmit = (input) => {
    const values = _.chain(input)
      .omit(['tipoPago'])
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    values.idTipoPago = input.tipoPago.id;
    
    onSubmit(values);
  }

  return (
    <div>
      <Loader loading={loading} />

      {ayuda && (
        <Table>
          <TableHead>
            <AyudaHeader simple />
          </TableHead>
          <TableBody>
            <AyudaRow ayuda={ayuda} simple />
          </TableBody>
        </Table>
      )}

      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validate={validateForm}
      >
        {({ values, errors, dirty, handleSubmit }) => (
          <Form alignItems="center">
            <Form.Field xs={12} md={4}>
              <Form.Lookup
                required
                name="tipoPago"
                label="Tipo de Documento"
                fluid
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option ? option.nombre : ''}
                fetcher={() => buscarTiposPago().then(resp => resp.data)}
              />
            </Form.Field>
            <Form.Field xs={12} md={3}>
              <Form.TextField required name="documentoPago" label="Documento Nº" fluid validate={validateLength(0, 50)} />
            </Form.Field>
            <Form.Field xs={12} md={5}>
              <Form.TextField required name='destinatarioPago' label="Destinatario" fluid validate={validateLength(0, 100)} />
            </Form.Field>

            <Form.Field xs={12}>
              {error && <ApiErrorMessage error={error} />}
            </Form.Field>
            
            <Fab
              color="secondary"
              aria-label="add"
              type="submit"
              disabled={!dirty}
              onClick={handleSubmit}
            >
              <SaveIcon />
            </Fab>
          </Form>
        )}
      </Formik>
    </div>
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.tipoPago) {
    errors.tipoPago = 'El tipo de documento es obligatorio'
  }
  if (!values.documentoPago) {
    errors.documentoPago = 'El número de documento es obligatorio'
  }
  if (!values.destinatarioPago) {
    errors.destinatarioPago = 'El destinatario es obligatorio'
  }
  
  return errors;
}

AprobarAyudaForm.propTypes = {
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default AprobarAyudaForm;