import React, { useState } from 'react';
import _ from 'lodash';
import { Typography, Grid } from '@material-ui/core';
import { useQuery, useMutation } from 'api';
import { isAuthenticated } from 'auth';
import { Loader, Formik, Form, TextField, Snackbar, ApiErrorMessage } from 'components/ui';
import { formatRut, validatePassword } from '../helpers';

function EditarPerfilPage() {
  const [snackOpen, setSnackOpen] = useState(false);
  const { data: usuario, loading } = useQuery(`/usuarios/me`);
  const [cambiarClave, { loading: posting, error }] = useMutation('/usuarios/cambiar-clave', { method: 'patch' });
  
  if (loading) {
    return <Loader loading={loading} />
  }

  const validarClaves = (values) => {
    const errors = {};
    
    const errClave = validatePassword(values.claveNueva, values.claveActual);
    if (errClave)
      errors.claveNueva = errClave;
    
    return errors;
  }

  const handleCambioClave = async (values) => {
    const { error } = await cambiarClave({ body: values })

    if (!error) {
      setSnackOpen(true);
    }
  }

  return (
    <div>
      <Typography variant="h4" align="center">Editar perfil</Typography>
      <Loader loading={loading || posting} />

      {usuario && (
        <Grid container spacing={3} justify="space-around" style={{marginBottom: 20}}>
          <Grid item xs={12} md={2}>
            <TextField name="run" label="RUT" fluid value={formatRut(usuario.rut)} />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField name="nombres" label="Nombres" fluid value={usuario.nombres} />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField name="apellidos" label="Apellidos" fluid value={usuario.apellidos} />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField name="rol" label="Rol" fluid value={usuario.rol.nombre} />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField name="departamento" label="Departamento" fluid value={usuario.departamento.nombre} />
          </Grid>
        </Grid>
      )}
      
      <Typography variant="h5" align="center">Cambiar contraseña</Typography>
      <Formik
        initialValues={{ claveActual: '', claveNueva: '' }}
        onSubmit={handleCambioClave}
        validate={validarClaves}
      >
        <Form>
          <Form.Field xs={12} md={4}>
            <Form.PasswordField
              name="claveActual"
              label="Contraseña actual"
              required
              fluid
            />
          </Form.Field>
          <Form.Field xs={12} md={4}>
            <Form.PasswordField
              name="claveNueva"
              label="Contraseña nueva"
              required
              fluid
            />
          </Form.Field>
          <Form.Field>
            <Form.Button
              type="submit"
              variant="outlined"
              color="primary"
            >
              Cambiar
            </Form.Button>
          </Form.Field>
          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
        </Form>
      </Formik>

      {snackOpen && <Snackbar
        message="Datos guardados"
        color="success"
        open={snackOpen}
        setOpen={setSnackOpen}
      />}
    </div>
  )
}

export default isAuthenticated(EditarPerfilPage);