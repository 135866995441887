import React from 'react'
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Description, GridOn, PictureAsPdf } from '@material-ui/icons';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function AdjuntoDownloadButton({ adjunto }) {
  const classes = useStyles();

  return (
    <Tooltip title={adjunto.nombre}>
      <a
        href={`${API_ENDPOINT}/adjuntos/${adjunto.id}/download`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <IconButton aria-label="descargar">
          {getFileIcon(adjunto.nombre)}
        </IconButton>
      </a>
    </Tooltip>
  )
}

export function getFileIcon(filename) {
  const ext = filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();
  switch(ext) {
    case 'pdf':
      return <PictureAsPdf style={{color: "firebrick"}} />
    case 'xls':
    case 'xlsx':
    case 'csv':
      return <GridOn style={{color: "forestgreen"}} />
    default:
      return <Description />
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    padding: 10
  },
  link: {
    color: theme.palette.text.primary
  },
  deleteButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  }
}));


export default AdjuntoDownloadButton;
