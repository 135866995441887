import React from 'react';
import { Router } from '@reach/router';

import NotFoundPage from '../NotFoundPage';
import ListadoEntregas from './ListadoEntregas';
import IngresarEntrega from './IngresarEntrega';
import EditarEntrega from './EditarEntrega';

export default function() {
  return (
    <Router>
      <ListadoEntregas path="/" />
      <IngresarEntrega path="/nuevo" />
      <EditarEntrega path="/:id/editar" />
      <NotFoundPage default />
    </Router>
  )
}