import React, { useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { Add as AddIcon, Edit } from '@material-ui/icons';
import { hasPermission, useAuth } from 'auth';
import { Fab, DataTable, Loader, CompactTableCell, ActionsButton, Breadcrumbs, Title } from 'components/ui';
import { useQuery } from 'api';
import { formatDate } from 'helpers/format';
import { navigate } from '@reach/router';
import { PERMISO } from '../../constants';

function ListadoTipoProducto() {
  const auth = useAuth();
  const [state, setState] = useState({ page: 1, pageSize: 10 });
  
  const canEdit = auth.hasPermission([PERMISO.TiposProductosModificar]);

  const { data: tiposProductos, loading, refetch } = useQuery('/tipos-productos', { query: state });
    
  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    refetch({ query: newState });
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'tipos-productos', title: 'Tipos de productos', to: '/admin/tipos-productos', active: true },
      ]} />
      <Title>Tipos de Productos</Title>
      <Loader loading={loading} />
      
      <DataTable
        data={tiposProductos}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <TipoProductoHeader />}
        renderRow={(tipoProducto) => (
          <TipoProductoRow
            key={tipoProducto.id}
            tipoProducto={tipoProducto}
            canEdit={canEdit}
          />
        )}
      />

      {canEdit && (
        <Fab
          color="secondary"
          aria-label="add"
          onClick={() => navigate('/admin/tipos-productos/nuevo')}
        >
          <AddIcon />
        </Fab>
      )}

    </div>
  );
}

function TipoProductoHeader() {
  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>Nombre</TableCell>
      <TableCell>Medida</TableCell>
      <TableCell>Fecha Ingreso</TableCell>
      <TableCell>Fecha Modificación</TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

function TipoProductoRow({ tipoProducto, canEdit }) {
  return (
    <TableRow>
      <CompactTableCell>{tipoProducto.id}</CompactTableCell>
      <CompactTableCell>{tipoProducto.nombre}</CompactTableCell>
      <CompactTableCell>{tipoProducto.unidad ? tipoProducto.unidad.nombre : ''}</CompactTableCell>
      <CompactTableCell>{formatDate(tipoProducto.fechaIngreso)}</CompactTableCell>
      <CompactTableCell>{formatDate(tipoProducto.fechaModificacion)}</CompactTableCell>
      <CompactTableCell>
        {canEdit && (
          <ActionsButton
            items={[
              { key: 'editar', text: 'Editar', icon: <Edit/>, onClick: () => navigate(`/admin/tipos-productos/${tipoProducto.id}`) }
            ]}
          />
        )}
      </CompactTableCell>
    </TableRow>
  )
}

export default hasPermission([PERMISO.TiposProductosVer])(ListadoTipoProducto);
