
export const PERMISO = {
  // Mantención
  UsuariosVer: 'usuarios.ver',
  UsuariosModificar: 'usuarios.modificar',
  BeneficiariosVer: 'beneficiarios.ver',
  BeneficiariosModificar: 'beneficiarios.modificar',
  ProveedoresVer: 'proveedores.ver',
  ProveedoresModificar: 'proveedores.modificar',
  ProductosVer: 'productos.ver',
  ProductosModificar: 'productos.modificar',
  DepartamentosVer: 'departamentos.ver',
  DepartamentosModificar: 'departamentos.modificar',
  OrigenesVer: 'origenes.ver',
  OrigenesModificar: 'origenes.modificar',
  RolesVer: 'roles.ver',
  RolesModificar: 'roles.modificar',
  TiposDocumentosVer: 'tiposDocumentos.ver',
  TiposDocumentosModificar: 'tiposDocumentos.modificar',
  TiposPagosVer: 'tiposPagos.ver',
  TiposPagosModificar: 'tiposPagos.modificar',
  TiposProductosVer: 'tiposProductos.ver',
  TiposProductosModificar: 'tiposProductos.modificar',
  // Recepciones
  RecepcionesVer: 'recepciones.ver',
  RecepcionesModificar: 'recepciones.modificar',
  // Procesamientos
  ProcesamientosVer: 'procesamientos.ver',
  ProcesamientosModificar: 'procesamientos.modificar',
  // Ayudas Sociales
  AyudasVer: 'ayudas.ver',
  AyudasModificar: 'ayudas.modificar',
  AyudasAprobar: 'ayudas.aprobar',
  // Entregas
  EntregasVer: 'entregas.ver',
  EntregasIngresar: 'entregas.ingresar',
  EntregasModificar: 'entregas.modificar',
  // Consulta Ayudas
  ConsultaAyudas: 'consultas.ayuda',
  // Reportes
  ReportesEntrada: 'reportes.entrada',
  ReportesSalida: 'reportes.salida',
  ReportesInventario: 'reportes.inventario',
  ReportesProcesamiento: 'reportes.procesamiento',
  ReportesBeneficiarios: 'reportes.beneficiarios',
  ReporteUsuarios: 'reportes.usuarios',
  // Otros
  CargaMasiva: 'cargaMasiva',
}