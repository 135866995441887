import React from 'react';
import { Router } from '@reach/router';

import NotFoundPage from '../NotFoundPage';
import ListadoProductos from './ListadoProductos';
import CrearProducto from './CrearProducto';
import EditarProducto from './EditarProducto';

export default function() {
  return (
    <Router>
      <ListadoProductos path="/" />
      <CrearProducto path="/nuevo" />
      <EditarProducto path="/:id" />
      
      <NotFoundPage default />
    </Router>
  )
}