import React from 'react';
import { Router } from '@reach/router';

import ListadoProveedores from './ListadoProveedores';
import CrearProveedor from './CrearProveedor';
import EditarProveedor from './EditarProveedor';
import NotFoundPage from '../NotFoundPage';

export default function() {
  return (
    <Router>
      <ListadoProveedores path="/" />
      <CrearProveedor path="/nuevo" />
      <EditarProveedor path="/:id" />
      <NotFoundPage default />
    </Router>
  )
}