import React from 'react';
import Admin from 'components/admin';
import TipoPagoForm from 'components/listados/TipoPagoForm';
import { PERMISO } from '../../constants';

export default function() {
  return (
    <Admin
      createTitle="Nuevo Tipo de Pago"
      listTitle="Tipos de Pago"
      editTitle="Editar Tipo de Pago"
      routePath="/admin/tipos-pagos"
      apiPath="/tipos-pago"
      FormComponent={TipoPagoForm}
      displayItem={item => item.nombre}
      viewPerms={[PERMISO.TiposPagosVer]}
      modifyPerms={[PERMISO.TiposPagosModificar]}
      headerItems={['ID', 'Nombre']}
      rowItems={row => [row.id, row.nombre]}
    />
  )
}