import React from 'react';
import { Router } from '@reach/router';

import NotFoundPage from '../NotFoundPage';
import ReporteStock from './ReporteStock';
import ReporteEntrada from './ReporteEntrada';
import ReporteSalida from './ReporteSalida';
import ReporteProcesamiento from './ReporteProcesamiento';
import ReporteBeneficiarios from './ReporteBeneficiarios';
import ReporteUsuarios from './ReporteUsuarios';
import ReporteEntradaDetalle from './ReporteEntradaDetalle';
import ReporteAyudas from './ReporteAyudas';

export default function() {
  return (
    <Router>
      <ReporteStock path="/inventario" />
      <ReporteEntrada path="/entrada" />
      <ReporteSalida path="/salida" />
      <ReporteAyudas path="/ayudas" />
      <ReporteProcesamiento path="/procesamiento" />
      <ReporteBeneficiarios path="/usuarios" />
      <ReporteUsuarios path="/funcionarios" />
      <ReporteEntradaDetalle path="/entradaDetalle" />
      <NotFoundPage default />
    </Router>
  )
}