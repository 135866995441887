import React from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { Router } from '@reach/router';
import { AuthProvider } from './auth';
import theme from './theme'
import './App.css';

import Dashboard from './components/dashboard/Dashboard';
import NotFoundPage from './pages/NotFoundPage';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/LoginPage';
import AdminBeneficiarios from './pages/beneficiario';
import AdminProveedores from './pages/proveedor';
import AdminTipoProductos from './pages/tipoProducto';
import AdminProductos from './pages/producto';
import AdminUsuarios from './pages/usuario';
import AdminTiposDocumento from './pages/tipoDocumento';
import AdminTiposPago from './pages/tipoPago';
import AdminOrigen from './pages/origen';
import AdminDepartamento from './pages/departamento';
import AdminRoles from './pages/rol';
import Recepciones from './pages/recepcion';
import Procesamientos from './pages/procesamiento';
import Entregas from './pages/entrega';
import Ayudas from './pages/ayuda';
import Tesoreria from './pages/tesoreria';
import ConsultaAyudas from './pages/ayuda/ConsultaAyudas';
import Reportes from './pages/reportes';
import EditarPerfil from './pages/EditarPerfil';
import CambiarClave from './pages/CambiarClave';
import Estadisticas from './pages/Estadisticas';
import CargaMasiva from 'pages/CargaMasiva';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <AuthProvider>
            <ErrorPage>
              <Router>
                <LoginPage path="/login" />
                <CambiarClave path="/cambiar-clave" />
                
                <Dashboard path="/">
                  <Estadisticas path="/" />
                  <EditarPerfil path="/editar-perfil" />

                  <AdminBeneficiarios path="/admin/usuarios/*" />
                  <AdminProveedores path="/admin/proveedores/*" />
                  <AdminTipoProductos path="/admin/tipos-productos/*" />
                  <AdminProductos path="/admin/productos/*" />
                  <AdminUsuarios path="/admin/funcionarios/*" />
                  <AdminTiposDocumento path="/admin/tipos-documentos/*" />
                  <AdminTiposPago path="/admin/tipos-pagos/*" />
                  <AdminOrigen path="/admin/origenes/*" />
                  <AdminDepartamento path="/admin/departamentos/*" />
                  <AdminRoles path="/admin/roles/*" />

                  <Recepciones path="/recepciones/*" />
                  <Procesamientos path="/procesamientos/*" />
                  <Entregas path="/entregas/*" />
                  <Ayudas path="/ayudas/*" />
                  <Tesoreria path="/tesoreria/*" />
                  <ConsultaAyudas path="/consulta-ayudas" />

                  <Reportes path="/reportes/*" />
                  <CargaMasiva path="/carga-masiva" />
                </Dashboard>
                
                <NotFoundPage default />
              </Router>
            </ErrorPage>
          </AuthProvider>
        </ThemeProvider>
    </div>
  );
}

export default App;
