import React, { useState } from "react";
import { Grid, TableRow, TableCell, Table } from "@material-ui/core";
import { GetApp as DownloadIcon } from "@material-ui/icons";
import { omit } from "lodash";
import { useLocation } from "@reach/router";
import qs from "query-string";
import moment from "moment";
import { hasPermission } from "auth";
import {
  DataTable,
  Loader,
  CompactTableCell,
  Breadcrumbs,
  Title,
  FilterGrid,
  DownloadButton,
} from "components/ui";
import { useQuery, useLazyQuery } from "api";
import { formatRut } from "helpers/usuario";
import { formatDate, formatPrecio } from "helpers/format";
import { PERMISO } from "../../constants";

function ReporteEntradaDetalle() {
  const [state, setState] = useState({ page: 1, pageSize: 25 });
  const location = useLocation();
  const params = qs.parse(location.search);

  const getQuery = (state) => ({
    idRecepcion: Number(params.entrada),
    idProducto: Number(params.producto),
    page: state.page,
    pageSize: state.pageSize,
  });

  const {
    data: reporte,
    loading,
    refetch,
  } = useQuery("/reportes/entradaDetalle", { query: getQuery(state) });
  const [descargarReporte, { loading: downloading }] = useLazyQuery(
    "/reportes/entradaDetalle"
  );
  const entrada = reporte?.nodes[0];
  const items = {
    nodes: entrada?.ayudas ?? [],
    count: reporte?.count ?? 0,
  };

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    handleFilter(newState);
  };

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });

  const handleFilter = (filterState) => {
    const query = getQuery(filterState);
    refetch({ query });
  };

  const handleDownloadExcel = async () => {
    const query = omit(getQuery(state), ["page", "pageSize"]);
    const content = await descargarReporte({
      type: "blob",
      query: { ...query, format: "xls" },
    });
    const filename = `reporte-entrada-detalle-${moment().format(
      "YYYY-MM-DD-HH-mm"
    )}.xlsx`;
    return { filename, content };
  };

  const handleDownloadCSV = async () => {
    const query = omit(getQuery(state), ["page", "pageSize"]);
    const content = await descargarReporte({
      type: "blob",
      query: { ...query, format: "csv" },
    });
    const filename = `reporte-entrada-detalle-${moment().format(
      "YYYY-MM-DD-HH-mm"
    )}.csv`;
    return { filename, content };
  };

  const entradaData = [
    ["Proveedor", entrada?.proveedor],
    ["Tipo de Documento", entrada?.tipoDocumento],
    ["Nº de Doc", entrada?.documento],
    ["Producto", entrada?.producto],
    [
      "Cantidad Ingresada",
      entrada?.esMonto
        ? `$${formatPrecio(entrada?.cantidad)}`
        : entrada?.cantidad,
    ],
  ];

  return (
    <div>
      <Breadcrumbs
        items={[
          { key: "home", title: "Home", to: "/" },
          {
            key: "list",
            title: "Reporte Guía de Entrada",
            to: "/reportes/entrada",
          },
          {
            key: "actual",
            title: "Reporte Detalle Guía",
            to: "/reportes/entradaDetalle",
            active: true,
          },
        ]}
      />
      <Title>Reporte Detalle Guía de Entrada</Title>
      <Loader loading={loading || downloading} />

      <FilterGrid container spacing={3}>
        <Grid item xs={12} align="right">
          <DownloadButton
            variant="contained"
            color="primary"
            size="small"
            type="text/csv;charset=utf-8"
            fetchFile={handleDownloadCSV}
            startIcon={<DownloadIcon />}
            style={{ marginRight: 10 }}
          >
            Descargar CSV
          </DownloadButton>
          <DownloadButton
            variant="contained"
            color="primary"
            size="small"
            type="vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
            fetchFile={handleDownloadExcel}
            startIcon={<DownloadIcon />}
            style={{ marginRight: 10 }}
          >
            Descargar Excel
          </DownloadButton>
        </Grid>
      </FilterGrid>

      <Grid container>
        <Grid item xs={12} lg={6}>
          <Table size="small">
            {entradaData.map(([label, value]) => (
              <TableRow key={label}>
                <TableCell>
                  <strong>{label}</strong>
                </TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>

      <DataTable
        data={items}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <ReporteHeader />}
        renderRow={(item, i) => <ReporteRow key={i} item={item} />}
      />
    </div>
  );
}

function ReporteHeader() {
  return (
    <TableRow>
      <TableCell>RUT</TableCell>
      <TableCell>Nombre</TableCell>
      <TableCell>Producto</TableCell>
      <TableCell>Cantidad</TableCell>
      <TableCell>Fecha Ayuda</TableCell>
      <TableCell>Fecha Entrega</TableCell>
    </TableRow>
  );
}

function ReporteRow({ item }) {
  const nrows = Math.max(1, item.entregas.length);
  const ent0 = item.entregas[0];

  return (
    <>
      <TableRow>
        <CompactTableCell rowSpan={nrows}>
          {formatRut(item.rut)}
        </CompactTableCell>
        <CompactTableCell rowSpan={nrows}>{item.nombre}</CompactTableCell>
        <CompactTableCell rowSpan={nrows}>{item.producto}</CompactTableCell>
        <CompactTableCell rowSpan={nrows} align="right">
          {item.esMonto ? `$${formatPrecio(item.cantidad)}` : item.cantidad}
        </CompactTableCell>
        <CompactTableCell rowSpan={nrows}>
          {formatDate(item.fechaAyuda)}
        </CompactTableCell>
        <CompactTableCell>{formatDate(ent0?.fechaEntrega)}</CompactTableCell>
      </TableRow>
      {item.entregas.slice(1).map((ent, i) => (
        <TableRow key={i}>
          <CompactTableCell>{formatDate(ent.fechaEntrega)}</CompactTableCell>
        </TableRow>
      ))}
    </>
  );
}

export default hasPermission([PERMISO.ReportesEntrada])(ReporteEntradaDetalle);
