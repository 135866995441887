import React from 'react'
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import TipoProductoForm from 'components/producto/TipoProductoForm';
import { hasPermission } from 'auth';
import { useMutation } from 'api';
import { PERMISO } from '../../constants';

function CrearTipoProducto() {
  const [crearTipoProducto, { loading, error }] = useMutation('/tipos-productos', { method: 'post' });

  const handleSubmit = async values => {
    const { error } = await crearTipoProducto({ body: values });

    if (!error) {
      navigate('/admin/tipos-productos');
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'tipos-productos', title: 'Tipos de productos', to: '/admin/tipos-productos' },
        { key: 'actual', title: 'Nuevo tipo de producto', to: '/admin/tipos-productos/nuevo', active: true },
      ]} />
      <Title>Nuevo Tipo de Producto</Title>
      <Loader loading={loading} />

      <TipoProductoForm
        error={error}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default hasPermission([PERMISO.TiposProductosModificar])(CrearTipoProducto);
