import React from 'react'
import { navigate } from '@reach/router';
import { Loader, Breadcrumbs, Title } from 'components/ui';
import ProcesamientoForm from '../../components/procesamiento/ProcesamientoForm';
import { hasPermission } from 'auth';
import { useMutation } from 'api';
import { PERMISO } from '../../constants';

function IngresarProcesamiento() {
  const [ingresarProcesamiento, { loading, error }] = useMutation('/procesamientos', { method: 'post' });

  const handleSubmit = async values => {
    const { error } = await ingresarProcesamiento({ body: values });

    if (!error) {
      navigate('/procesamientos');
    }
  }

  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'list', title: 'Procesamientos', to: '/procesamientos' },
        { key: 'actual', title: 'Nuevo Procesamiento', to: '/procesamientos/nuevo', active: true },
      ]} />
      <Title>Envío a Proceso</Title>
      <Loader loading={loading} />

      <ProcesamientoForm
        error={error}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default hasPermission([PERMISO.ProcesamientosModificar])(IngresarProcesamiento);
