import React from 'react';
import { Grid } from '@material-ui/core';
import { Loader, Title } from 'components/ui';
import { isAuthenticated } from 'auth';
import { useQuery } from 'api';

import AyudaChart from '../components/charts/AyudaChart';
import RecepcionChart from '../components/charts/RecepcionChart';
import EntregaChart from '../components/charts/EntregaChart';

function Estadisticas() {
  const { data: estadisticaAyudas, loading: loadingAyudas } = useQuery('/estadisticas/ayudas');
  const { data: estadisticaRecepciones, loading: loadingRecepciones } = useQuery('/estadisticas/recepciones');
  const { data: estadisticaEntregas, loading: loadingEntregas } = useQuery('/estadisticas/entregas');

  return (
    <div>
      <Title>Estadísticas</Title>
      <Loader loading={loadingAyudas || loadingRecepciones || loadingEntregas} />

      <Grid container spacing={3} style={{marginTop: 20}}>
        <Grid item xs={12} lg={6}>
          {estadisticaAyudas && estadisticaAyudas.length > 0 &&
            <AyudaChart data={estadisticaAyudas} />
          }
        </Grid>
        <Grid item xs={12} lg={6}>
          {estadisticaRecepciones && estadisticaRecepciones.length > 0 &&
            <RecepcionChart data={estadisticaRecepciones} />
          }
        </Grid>
        <Grid item xs={12} lg={6}>
          {estadisticaEntregas && estadisticaEntregas.length > 0 &&
            <EntregaChart data={estadisticaEntregas} />
          }
        </Grid>
      </Grid>
    </div>
  );
}
export default isAuthenticated(Estadisticas);
