import React from 'react';
import FileSaver from 'file-saver';
import { Button } from '@material-ui/core';

export function DownloadButton({ fetchFile, type='text/plain', open = false, ...props }) {
  const handleClick = async () => {
    try {
      const { filename, content } = await fetchFile();
      const blob = new Blob([content], {type});
      if (open) {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      } else {
        FileSaver.saveAs(blob, filename);
      }
    } catch(err) {
      console.log(err)
    }
  }

  return (
    <React.Fragment>
      <Button {...props} onClick={handleClick} />
    </React.Fragment>
  )
}