import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useField } from 'formik';
import { TextField } from '../TextField';

export function Datepicker(props) {
  const [field, meta, helpers] = useField(props);
  const hasError = meta.touched && !!meta.error;

  const handleChange = (e) => {
    const value = localToIso(e.target.value, props.format);
    helpers.setValue(value);
  }

  return (
    <TextField
      {...field}
      {...props}
      type="date"
      value={isoToLocal(field.value, props.format)}
      error={hasError}
      helperText={hasError && meta.error}
      onChange={handleChange}
      InputLabelProps={{ shrink: true }}
    />
  )
}

Datepicker.propTypes = {
  name: PropTypes.string.isRequired,
  format: PropTypes.string,
}

Datepicker.defaultProps = {
  format: 'YYYY-MM-DD',
}

function isoToLocal(str, format) {
  const date = moment.utc(str);
  return date.isValid() ? date.format(format) : str;
}

function localToIso(str, format) {
  const date = moment.utc(str, format);
  return date.isValid() ? date.toISOString() : str;
}
