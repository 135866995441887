import React, { useState } from 'react';
import { Grid, MenuItem, Button } from '@material-ui/core';
import { Loader, Title, Select, DownloadButton, TextArea } from 'components/ui';
import { isAuthenticated } from 'auth';
import { useApi, useMutation } from 'api';

import { CloudUpload, Help } from '@material-ui/icons';

function Estadisticas() {
  const [tipo, setTipo] = useState('beneficiarios');
  const [fileName, setFileName] = useState(null);
  const fileInput = React.createRef();
  
  const [subirCarga, { data, loading, loaded, error }] = useMutation('/carga-masiva', { method: 'post' })
  const callApi = useApi();

  const handleFileSelect = () => {
    setFileName(fileInput.current ? fileInput.current.files[0].name : null);
  }

  const handleSubmit = () => {
    const reader = new FileReader();
    reader.onload = () => {
      subirCarga({ body: { tipo, file: reader.result } });
      setFileName(null);
    };
    reader.readAsText(fileInput.current.files[0], 'utf-8');
  }

  const handleGetExample = async () => {
    const content = await callApi('/carga-masiva', { type: 'blob', query: { tipo } });
    const filename = `ejemplo-${tipo}.csv`;
    return { filename, content };
  }

  const errors = data ? data.errors : [];
  
  let resultado;
  if (loaded) {
    if (errors.length > 0) {
      resultado = errors.map(({ index, message }) => `Linea ${index}: ${message}`).join('\n')
    } else if (error) {
      resultado = JSON.stringify(error);
    } else {
      resultado = 'Carga realizada exitosamente'
    }
  }

  return (
    <div>
      <Title>Carga Masiva</Title>
      <Loader loading={loading} />

      <Grid container spacing={3} style={{marginTop: 20}}>
        <Grid item xs={12} md={3} style={{textAlign: 'left'}}>
          <Select
            fluid
            label="Tipo de carga"
            value={tipo}
            onChange={e => setTipo(e.target.value)}
          >
            <MenuItem value="beneficiarios">Usuarios</MenuItem>
            <MenuItem value="ayudas">Ayudas</MenuItem>
            <MenuItem value="entregas">Entregas</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={4}>
          <input
            accept=".csv"
            style={{display: 'none'}}
            id="contained-button-file"
            type="file"
            ref={fileInput}
            onChange={handleFileSelect}
          />
          <label htmlFor="contained-button-file">
            <Button color="primary" component="span">
              Seleccionar Archivo
            </Button>
          </label>
          <p>{fileName}</p>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudUpload/>}
            onClick={handleSubmit}
            disabled={!tipo || !fileName}
          >
            Cargar
          </Button>
          <DownloadButton
            variant="contained"
            color="secondary"
            startIcon={<Help/>}
            type='text/csv'
            fetchFile={handleGetExample}
            style={{marginLeft: 10}}
          >
            Descargar Ejemplo
          </DownloadButton>
        </Grid>
        <Grid item xs={12}>
          <TextArea fluid placeholder="Resultados" value={resultado} />
        </Grid>
      </Grid>
    </div>
  );
}
export default isAuthenticated(Estadisticas);
