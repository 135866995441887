import React, { useState } from 'react';
import { Grid, TableRow, TableCell, Button } from '@material-ui/core';
import { GetApp as DownloadIcon, PictureAsPdf } from '@material-ui/icons';
import { omit } from 'lodash';
import moment from 'moment';
import { pdf } from '@react-pdf/renderer';
import { hasPermission } from 'auth';
import { DataTable, Loader, CompactTableCell, TextField, Breadcrumbs, Title, FilterGrid, Lookup, DownloadButton } from 'components/ui';
import { useQuery, useLazyQuery } from 'api';
import { formatRut } from 'helpers/usuario';
import { formatDate, formatPrecio } from 'helpers/format';
import ReporteStockDocument from 'components/pdf/ReporteStockDocument';
import { PERMISO } from '../../constants';

function ReporteStock() {
  const [state, setState] = useState({ page: 1, pageSize: 25 });
  
  const { data: items, loading, refetch } = useQuery('/reportes/stock', { query: state });
  const [descargarReporte, { loading: downloading }] = useLazyQuery('/reportes/stock');
  const [buscarProveedores] = useLazyQuery('/proveedores');
  const [buscarProductos] = useLazyQuery('/productos');
  const [buscarOrigenes] = useLazyQuery('/origenes');

  const onChange = (changes) => {
    const newState = { ...state, ...changes };
    setState(newState);
    handleFilter(newState);
  }

  const onPageChange = (page) => onChange({ page });
  const onPageSizeChange = (pageSize) => onChange({ pageSize, page: 1 });
    
  const getQuery = (state) => {
    const query = {};
    if (state.fechaDesde) {
      query.fechaDesde = moment.utc(state.fechaDesde).toISOString();
    }
    if (state.fechaHasta) {
      query.fechaHasta = moment.utc(state.fechaHasta).toISOString();
    }
    if (state.proveedor) {
      query.idProveedor = state.proveedor.id;
    }
    if (state.origen) {
      query.idOrigen = state.origen.id;
    }
    if (state.producto) {
      query.idProducto = state.producto.id;
    }

    query.page = state.page;
    query.pageSize = state.pageSize;

    return query;
  }

  const handleFilter = (filterState) => {
    const query = getQuery(filterState);
    refetch({ query });
  }

  const handleDownloadExcel = async () => {
    const query = omit(getQuery(state), ['page', 'pageSize']);
    const content = await descargarReporte({ type: 'blob', query: { ...query, format: 'xls' } });
    const filename = `reporte-stock-${moment().format('YYYY-MM-DD-HH-mm')}.xlsx`;
    return { filename, content };
  }

  const handleDownloadCSV = async () => {
    const query = omit(getQuery(state), ['page', 'pageSize']);
    const content = await descargarReporte({ type: 'blob', query: { ...query, format: 'csv' } });
    const filename = `reporte-stock-${moment().format('YYYY-MM-DD-HH-mm')}.csv`;
    return { filename, content };
  }

  const generatePdfDocument = React.useCallback(async () => {
    const blob = await pdf((
      <ReporteStockDocument
        productos={items.nodes}
        proveedor={state.proveedor}
        origen={state.origen}
        producto={state.producto}
      />
    )).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
    // saveAs(blob, `reporte-stock-${moment().format('YYYY-MM-DD-HH-mm')}.pdf`);
  }, [items, state]);
  
  return (
    <div>
      <Breadcrumbs items={[
        { key: 'home', title: 'Home', to: '/' },
        { key: 'actual', title: 'Reporte Inventario', to: '/reportes/inventario', active: true },
      ]} />
      <Title>Reporte Inventario</Title>
      <Loader loading={loading || downloading} />
      
      <FilterGrid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Lookup
            name="proveedor"
            label="Proveedor"
            size="small"
            fluid
            onChange={(e, val) => setState({ ...state, proveedor: val })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? `${formatRut(option.rut)} - ${option.razonSocial}` : ''}
            fetcher={(busqueda) => buscarProveedores({ query: { busqueda } }).then(resp => resp.data.nodes)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Lookup
            name="origen"
            label="Origen"
            size="small"
            fluid
            onChange={(e, val) => setState({ ...state, origen: val })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? option.nombre : ''}
            fetcher={() => buscarOrigenes().then(resp => resp.data)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Lookup
            name="producto"
            label="Producto"
            size="small"
            fluid
            onChange={(e, val) => setState({ ...state, producto: val })}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option ? option.descripcion : ''}
            fetcher={(busqueda) => buscarProductos({ query: { busqueda } }).then(resp => resp.data.nodes)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Desde"
            type="date"
            InputLabelProps={{shrink: true}}
            fluid
            onChange={(e) => setState({ ...state, fechaDesde: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            label="Hasta"
            type="date"
            InputLabelProps={{shrink: true}}
            fluid
            onChange={(e) => setState({ ...state, fechaHasta: e.target.value })}
          />
        </Grid>
        <Grid item xs={12} md={1}>
          <Button variant="contained" color="primary" size="small" onClick={() => handleFilter(state)}>Filtrar</Button>
        </Grid>
        <Grid item xs={12} align="right">
          <DownloadButton
            variant="contained"
            color="primary"
            size="small"
            type='text/csv;charset=utf-8'
            fetchFile={handleDownloadCSV}
            startIcon={<DownloadIcon />}
            style={{marginRight: 10}}
          >
            Descargar CSV
          </DownloadButton>
          <DownloadButton
            variant="contained"
            color="primary"
            size="small"
            type='vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
            fetchFile={handleDownloadExcel}
            startIcon={<DownloadIcon />}
            style={{marginRight: 10}}
          >
            Descargar Excel
          </DownloadButton>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<PictureAsPdf />}
            disabled={!items || items.count === 0}
            onClick={generatePdfDocument}
          >
            Generar PDF
          </Button>
        </Grid>
      </FilterGrid>

      <DataTable
        data={items}
        loading={loading}
        page={state.page}
        pageSize={state.pageSize}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        renderHeader={() => <ReporteHeader />}
        renderRow={(item) => (
          <ReporteRow
            key={item.id}
            item={item}
          />
        )}
      />
    </div>
  );
}

function ReporteHeader() {
  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>Proveedor</TableCell>
      <TableCell>RUT</TableCell>
      <TableCell>Guía Entrada Nº</TableCell>
      <TableCell>Fecha Entrada</TableCell>
      <TableCell>Origen</TableCell>
      <TableCell>Doc Nº</TableCell>
      <TableCell>Producto</TableCell>
      <TableCell>Cantidad</TableCell>      
      <TableCell></TableCell>
    </TableRow>
  )
}

function ReporteRow({ item }) {

  return (
    <TableRow>
      <CompactTableCell>{item.id}</CompactTableCell>
      <CompactTableCell>{item.proveedor}</CompactTableCell>
      <CompactTableCell>{formatRut(item.rut)}</CompactTableCell>
      <CompactTableCell>{item.idRecepcion}</CompactTableCell>
      <CompactTableCell>{formatDate(item.fechaRecepcion)}</CompactTableCell>
      <CompactTableCell>{item.origen}</CompactTableCell>
      <CompactTableCell>{item.documento}</CompactTableCell>
      <CompactTableCell>{item.producto}</CompactTableCell>
      <CompactTableCell>{item.esMonto ? `$${formatPrecio(item.stock)}` : item.stock}</CompactTableCell>
    </TableRow>
  )
}

export default hasPermission([PERMISO.ReportesInventario])(ReporteStock);
