import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FieldArray } from 'formik';
import { Typography, IconButton, Button, InputAdornment } from '@material-ui/core';
import { Save as SaveIcon, Delete, Add } from '@material-ui/icons';
import { useLazyQuery } from 'api';
import { Formik, Form, Fab, ApiErrorMessage } from 'components/ui';
import { formatRut, formatProducto } from 'helpers';

function ProcesamientoForm ({ error, onSubmit }) {
  const [buscarProveedores] = useLazyQuery('/proveedores');
  const [buscarProductos] = useLazyQuery('/productos');

  const initialValues = {
    proveedor: null,
    observaciones: '',
    productosEntrada: [],
    productosSalida: [],
  };

  const handleFormSubmit = (input) => {
    const values = _.chain(input)
      .omit(['proveedor', 'productosEntrada', 'productosSalida'])
      .mapValues(val => (!_.isNil(val) && val !== '') ? val : null)
      .value();

    values.idProveedor = input.proveedor.id;
    values.productosDetalle = [
      ...input.productosEntrada.map(pd => ({
        idProducto: pd.producto.id,
        cantidad: pd.cantidad,
        salida: false,
      })),
      ...input.productosSalida.map(pd => ({
        idProducto: pd.producto.id,
        cantidad: pd.cantidad,
        salida: true,
      })),
    ];

    onSubmit(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validate={validateForm}
    >
      {({ values, setFieldValue, setFieldTouched, errors, dirty, handleSubmit }) => (
        <Form alignItems="center">
          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Datos Generales</Typography>
          </Form.Field>
          <Form.Field xs={12}>
            <Form.Lookup
              required
              name="proveedor"
              label="Proveedor"
              fluid
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option ? `${formatRut(option.rut)} - ${option.razonSocial}` : ''}
              fetcher={(busqueda) => buscarProveedores({ query: { busqueda } }).then(resp => resp.data.nodes)}
            />
          </Form.Field>
          <Form.Field xs={12}>
            <Form.TextArea name="observaciones" placeholder="Observaciones..." fluid />
          </Form.Field>

          <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Productos a Procesar</Typography>
          </Form.Field>
          <FieldArray name="productosEntrada" render={arrayHelpers => (
            <>
              {values.productosEntrada.map((pd, index) => {
                const esMonto = !!pd.producto && pd.producto.tipo.esMonto;

                return (
                  <React.Fragment key={index}>
                    <Form.Field xs={12} md={7}>
                      <Form.Lookup
                        required
                        name={`productosEntrada.${index}.producto`}
                        label="Producto"
                        fluid
                        onChange={(e, option) => {
                          if (option) {
                            setFieldValue(`productosEntrada.${index}.stock`, option.stock.toString());
                            if (option.tipo.esMonto) {
                              setFieldValue(`productosEntrada.${index}.precio`, '1');
                              setFieldTouched(`productosEntrada.${index}.precio`);
                            }
                          }
                        }}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option ? formatProducto(option) : ''}
                        fetcher={() => buscarProductos({ query: { entregaDirecta: 0, stock: 1, pageSize: 0 } }).then(resp => resp.data.nodes)}
                      />
                    </Form.Field>
                    <Form.Field xs={12} md={2}>
                      <Form.TextField
                        disabled
                        name={`productosEntrada.${index}.stock`}
                        label="Stock"
                        fluid
                      />
                    </Form.Field>
                    <Form.Field xs={12} md={2}>
                      <Form.TextField
                        required
                        name={`productosEntrada.${index}.cantidad`}
                        label={esMonto ? 'Monto' : 'Cantidad'}
                        type="number"
                        InputProps={{
                          startAdornment: esMonto ? <InputAdornment position="start">$</InputAdornment> : null,
                        }}
                        fluid
                      />
                    </Form.Field>
                    <IconButton aria-label="delete" onClick={() => arrayHelpers.remove(index)}>
                      <Delete />
                    </IconButton>
                  </React.Fragment>
                );
              })}
              <Form.Field xs={12} align="left">
                <Form.ErrorMessage name="productosEntrada" />
                <Button variant="contained" size="small" startIcon={<Add/>} onClick={() => arrayHelpers.push({ producto: null, cantidad: '1' })}>
                  Agregar Producto
                </Button>
              </Form.Field>
            </>
          )} />

          {/* <Form.Field xs={12} align="left" style={{paddingBottom: 0}}>
            <Typography variant="subtitle1">Productos de Salida</Typography>
          </Form.Field>
          <FieldArray name="productosSalida" render={arrayHelpers => (
            <>
              {values.productosSalida.map((pd, index) => {
                const esMonto = !!pd.producto && pd.producto.tipo.esMonto;

                return (
                  <React.Fragment key={index}>
                    <Form.Field xs={12} md={9}>
                      <Form.Lookup
                        required
                        name={`productosSalida.${index}.producto`}
                        label="Producto"
                        fluid
                        onChange={(e, option) => {
                          if (option && option.tipo.esMonto) {
                            setFieldValue(`productosSalida.${index}.precio`, '1');
                            setFieldTouched(`productosSalida.${index}.precio`);
                          }
                        }}
                        getOptionSelected={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option ? formatProducto(option) : ''}
                        fetcher={() => buscarProductos({ query: { entregaDirecta: 1, pageSize: 0 }}).then(resp => resp.data.nodes)}
                      />
                    </Form.Field>
                    <Form.Field xs={12} md={2}>
                      <Form.TextField
                        required
                        name={`productosSalida.${index}.cantidad`}
                        label={esMonto ? 'Monto' : 'Cantidad'}
                        type="number"
                        InputProps={{
                          startAdornment: esMonto ? <InputAdornment position="start">$</InputAdornment> : null,
                        }}
                        fluid
                      />
                    </Form.Field>
                    <IconButton aria-label="delete" onClick={() => arrayHelpers.remove(index)}>
                      <Delete />
                    </IconButton>
                  </React.Fragment>
                );
              })}
              <Form.Field xs={12} align="left">
                <Form.ErrorMessage name="productosSalida" />
                <Button variant="contained" size="small" startIcon={<Add/>} onClick={() => arrayHelpers.push({ producto: null, cantidad: '1' })}>
                  Agregar Producto
                </Button>
              </Form.Field>
            </>
          )} /> */}

          <Form.Field xs={12}>
            {error && <ApiErrorMessage error={error} />}
          </Form.Field>
          
          <Fab
            color="secondary"
            aria-label="add"
            type="submit"
            disabled={!dirty}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </Fab>
        </Form>
      )}
    </Formik>
  )
}

function validateForm(values) {
  const errors = {};

  if (!values.proveedor) {
    errors.proveedor = 'El proveedor es obligatorio'
  }
  ['productosEntrada'].forEach(list => {
    if (values[list].length === 0) {
      errors[list] = 'Debe ingresar al menos un producto'
    } else {
      errors[list] = values[list].map(pd => {
        const pdErrors = {};
        if (!pd.producto) {
          pdErrors.producto = 'El producto es obligatorio';
        }
        let cantidad = Number(pd.cantidad);
        if (_.isNaN(cantidad) || cantidad < 0) {
          pdErrors.cantidad = 'La cantidad ingresada no es válida';
        } else {
          let stock = Number(pd.stock);
          if (cantidad > stock) {
            pdErrors.cantidad = 'Excede el stock';
          }
        }
        
        return pdErrors;
      });
  
      if (_.isEmpty(errors[list].filter(x => !_.isEmpty(x)))) {
        delete errors[list];
  
        const unicos = _.uniq(values[list].map(pd => pd.producto.id));
        if (unicos.length !== values[list].length) {
          errors[list] = 'Hay productos repetidos'
        }
      }
    }
  })
  
  return errors;
}

ProcesamientoForm.propTypes = {
  error: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default ProcesamientoForm;