import React from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import { PictureAsPdf as PdfIcon, ThumbDown, ThumbUp } from '@material-ui/icons';
import { pdf } from '@react-pdf/renderer';
import { CompactTableCell, ActionsButton } from '../ui';
import ComprobanteAyudaPdf from '../pdf/ComprobanteAyudaPdf';
import { formatRut, formatBeneficiario, formatDate, getEstadoAyudaIcon, formatProductoItem } from '../../helpers';
import { navigate } from '@reach/router';


export function AyudaHeader({ simple }) {
  return (
    <>
      <TableRow>
        <TableCell>ID</TableCell>
        <TableCell>RUT</TableCell>
        <TableCell>Usuario</TableCell>
        <TableCell>Decreto Nº</TableCell>
        <TableCell>Fecha Creación</TableCell>
        <TableCell>Meses Ayuda</TableCell>
        <TableCell>Estado</TableCell>
        <TableCell>Productos</TableCell>
        {!simple && (
          <React.Fragment>
            <TableCell>Tipo Documento</TableCell>
            <TableCell>Nº Documento</TableCell>
            <TableCell>Destinatario</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </React.Fragment>
        )}
      </TableRow>
    </>
  )
}

export function AyudaRow({ ayuda, onDesactivar, simple }) {
  const cantProds = Math.max(1, ayuda.productosDetalle.length);
  const pd0 = ayuda.productosDetalle[0];
  
  const generatePdfDocument = React.useCallback(async () => {
    const blob = await pdf(<ComprobanteAyudaPdf ayuda={ayuda} />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
    // saveAs(blob, `ayuda-${ayuda.id}.pdf`);
  }, [ayuda]);

  return (
    <>
      <TableRow>
        <CompactTableCell rowSpan={cantProds}>{ayuda.id}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatRut(ayuda.beneficiario.rut)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatBeneficiario(ayuda.beneficiario)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{ayuda.decreto}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{formatDate(ayuda.fechaIngreso)}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{ayuda.cantidadMeses}</CompactTableCell>
        <CompactTableCell rowSpan={cantProds}>{getEstadoAyudaIcon(ayuda.estado)}</CompactTableCell>
        <CompactTableCell>{formatProductoItem(pd0)}</CompactTableCell>
        {!simple && (
          <React.Fragment>
            <CompactTableCell rowSpan={cantProds}>{ayuda.tipoPago ? ayuda.tipoPago.nombre : ''}</CompactTableCell>
            <CompactTableCell rowSpan={cantProds}>{ayuda.documentoPago}</CompactTableCell>
            <CompactTableCell rowSpan={cantProds}>{ayuda.destinatarioPago}</CompactTableCell>
            <CompactTableCell rowSpan={cantProds}>
              {ayuda.estado === 1 && (
                <IconButton aria-label="descargar pdf" onClick={generatePdfDocument}>
                  <PdfIcon style={{color: "firebrick"}} />
                </IconButton>
              )}
            </CompactTableCell>
            <CompactTableCell rowSpan={cantProds}>
              {ayuda.estado === 0 && (
                <ActionsButton
                  items={[
                    { key: 'aprobar', text: 'Aprobar', icon: <ThumbUp/>, onClick: () => navigate(`/tesoreria/${ayuda.id}/aprobar`) },
                    { key: 'rechazar', text: 'Rechazar', icon: <ThumbDown/>, confirmText: 'Confirme si desea rechazar la ayuda', onClick: () => onDesactivar(ayuda.id) },
                  ]}
                />
              )}
            </CompactTableCell>
          </React.Fragment>
        )}
      </TableRow>
      {ayuda.productosDetalle.slice(1).map(pd => (
        <TableRow key={pd.idProducto}>
          <CompactTableCell>{formatProductoItem(pd)}</CompactTableCell>
        </TableRow>
      ))}
    </>
  )
}